div.display-currency-dialog-paper {
	max-width: 90%;
}

div.display-currency-container {
	width: 64px;

	div.display-currency {
		&.hovered {
			cursor: pointer;
		}

		// span.currency {

		// }

		.busy {
			margin-top: 16px;
			margin-left: 16px;
		}
	}
}

ul.currency-list {
	// font-family: Arial, Helvetica, sans-serif;
	color: #4e4e4e;
	font-size: 14px;
	// line-height: 18px;

	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;

	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

	border: 0;
	margin: 0;
	padding: 0;
	vertical-align: baseline;

	list-style: none;

	-moz-columns: 4 240px;
	-webkit-columns: 4 240px;
	columns: 4 240px;
	column-break-inside: avoid;
	overflow: hidden;

	&.main {
		padding-bottom: 8px;
		margin-bottom: 8px;
		border-bottom: 1px solid #ccc;
	}

	li {
		height: 2.5em;

		&.active > a {
			color: #fff;
			background-color: #0071b2; // background-color: #156bc1;

			border-radius: 4px;

			em {
				color: #fff;
				vertical-align: middle;
			}

			.check-icon {
				float: right;
			}
		}

		& > a {
			text-decoration: none;

			color: #0071b2; // color: #156bc1;
			box-sizing: border-box;
			display: inline-block;
			width: 100%;
			height: 100%;
			padding: 0.5em 10px;

			em {
				display: inline-block;
				width: 3em;
				font-style: normal;
				font-weight: bold;
				color: #4e4e4e;
			}
		}
	}
}
