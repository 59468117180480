@import 'colors';

.credit-card-component,
.iframe-container,
.settle-payment-wrap {
	height: 100%;
}

.settle-payment-title {
	.title-text {
		font-size: 24px;
		font-weight: 700;
	}

	.title-logo {
		margin-top: 8.5px;
		height: 28px;

		img {
			// width: 180px;
			width: 120px;
		}
	}

	.close-button {
		color: $black-text;
		padding: 0;
		margin-bottom: 12px;
	}
}

.settle-payment-wrap {
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 100%;

	.order-total {
		display: flex;
		justify-content: center; /* Centers items horizontally */
		gap: 125.5px;
		padding: 20px;

		line-height: 20px;
		font-size: 18px;
		font-weight: 500;
		color: #161616;

		.order-total-title {
		}

		.order-total-value {
		}
	}

	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 100%;

		button {
			width: 128px;
			&:last-child {
				margin-left: 12px;
			}
		}
	}

	.space-between {
		justify-content: space-between;
	}

	.space-top {
		margin-top: 24px;
	}

	.copy-link-text {
		font-size: 13px;
		font-weight: 700;
		line-height: 15.6px;
		color: $text-orange;
	}
}

.settle-payment {
	max-width: 370px;

	.settle-payment-charge-currency {
		margin-bottom: 16px;
	}

	.name-on-invoice {
		margin-top: 22px;
		margin-bottom: 14px;
	}

	#choose-charge-currency,
	#outlined-helper-text {
		font-weight: 400;
		font-size: 15px;
		color: $gray-1;
		margin-left: 4px;
	}

	// .retroactive-actions {
	//     display: flex;
	//     margin-right: -12px;

	//     .status-container {
	//         align-self: center;

	//         span.success {
	//             color: $green-bright;
	//         }

	//         span.failed {
	//             color: $danger;
	//         }

	//     }

	//     .busy-container {
	//         align-self: center;
	//         margin-right: 16px;
	//     }

	//     // .cancel-container {
	//     //     margin-left: auto;
	//     // }

	//     .update-container {
	//         margin-left: auto;

	//         button {
	//             text-transform: none;
	//         }
	//     }
	// }
}

.settle-payment-result {
	display: flex;
	flex-direction: column;
	align-items: center;

	.title {
		font-size: 16px;
		font-weight: 700;
		margin-top: 26px;
	}

	.reservation-modal-content {
		margin-top: 16px;
		text-align: center;
	}

	.actions {
		margin-top: 26px;
	}
}

.expired-message {
	font-size: 13px;
	font-weight: 400;
	color: $text-orange;
	margin-bottom: 8px;
}

.settle-payment-copy-link,
.settle-payment-type {
	.MuiFormControlLabel-label {
		width: 100%;
		font-weight: 700;
	}

	.show-error {
		color: $danger;
		font-weight: 700;
	}

	.generate-button {
		width: 92px;
		height: 36px;
		font-size: 14px;
		font-weight: 700;
		color: $main-blue;
		background-color: $light-blue;
		border-radius: 5px;
		box-shadow: none;

		&.Mui-disabled {
			color: $gray-1;
			background-color: $gray-5;
			cursor: not-allowed !important;
		}
	}

	.full-width {
		width: 100%;
	}

	.input-link {
		flex: 1;
		margin-right: 16px;
		font-size: 14px;
		font-weight: 700;
	}

	.copy-link {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.copy-link-width {
		width: 125px;
	}

	.send-link-label {
		display: flex;
		flex-direction: column;
		margin-bottom: 12px;

		.send-link-label-title {
			margin-top: 8px;
		}

		.send-link-label-subtitle {
			font-size: 13px;
			font-weight: 400;
			color: $gray-2;
		}
	}
}

.copy-link-title {
	font-size: 15px;
	font-weight: 400;
	line-height: 21px;
	color: $gray-1;
	margin-bottom: 6px;
}

.copy-link-wrap {
	//margin-top: 8px;
	background-color: $gray-5;
	border-radius: 8px;
	padding: 12px;
}

.copy-link-time {
	font-size: 13px;
	font-weight: 400;
	color: $gray-2;
	margin-top: 10px;
}
