.bookings-filters-component {
	width: 100%;
	height: 134px;
	margin: 0 auto;
	// min-width: 1092px;
	background: $gray-background-light;
	padding: 14px 66px 50px;

	&.tags {
		padding-bottom: 18px;
	}

	&__filters,
	&__tags {
		max-width: 1600px;
	}

	&__filters {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 0 auto;
	}

	&__filter {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		&.toggle button {
			height: 40px;
			min-width: 180px;

			svg {
				position: absolute;
				left: 14px;
			}
		}

		&.end {
			margin-left: auto;
		}

		&.space-right-sm {
			margin-right: 16px;
		}

		&.space-right-md {
			margin-right: 25px;
		}

		label {
			font-size: 15px;
			font-weight: 400;
		}

		& .MuiTextField-root {
			label {
				font-size: 14px;
				color: $gray-color-light;
			}
		}

		&.no-label {
			margin-top: 27px;
		}

		&.when {
			width: 160px;

			.desktop-date-picker {
				label {
					color: rgb(172, 172, 172);
					font-size: 14px;
				}

				input::selection {
					background: transparent;
				}
			}
		}

		&.export {
			margin-left: auto;

			.busy-indicator {
				width: 24px;
				height: 24px;
				display: flex;
				align-items: center;
				padding-left: 4px;
			}
		}

		&.search {
			width: 420px;

			& .MuiInputBase-root {
				padding-right: 6px;
			}
		}

		& .MuiInputBase-root {
			height: 40px;
			background-color: white;

			legend {
				max-width: 0.01px; // don't show legend
			}
		}

		& .MuiInputLabel-root {
			transform: translate(12px, 10px);

			&.MuiInputLabel-shrink {
				display: none;
			}
		}

		& button.MuiButtonBase-root {
			font-size: 14px;
			background-color: white;
			text-transform: none;
		}

		& button.MuiButton-outlined {
			color: var(--desktop-bookings-button);
			border-color: var(--desktop-bookings-border-color);
		}
	}

	&__tags {
		margin: 9px auto;

		.MuiChip-root {
			height: 28px;
		}

		.tag {
			display: flex;

			& div:first-child {
				color: $gray-border-dark;
				margin-right: 4px;
			}
		}

		& .MuiChip-root {
			color: $tag-color;
			background-color: $tag-background;
			border-radius: 8px;
			border: 1px solid transparent;

			&.Mui-focusVisible {
				border: 1px solid $tag-color;
			}
		}
	}

	@media (max-width: 1420px) {
		&__filter {
			.MuiButtonBase-root {
				white-space: nowrap;

				.MuiButton-startIcon,
				.MuiButton-endIcon {
					display: none;
				}
			}
		}
	}
}
