@import 'colors';

.companies-autocomplete {
	width: 200px;
	height: 44px;
	border: 1px solid $gray-3;
	border-radius: 4px;

	.MuiInputBase-root {
		height: 44px;
		padding-left: 16px;
		padding-right: 20px;
	}

	.MuiInput-root {
		color: $black-text;
		background-color: $gray-5;
		border-bottom: 1px solid $gray-3;
		border-radius: 4px;

		&::before,
		&::after,
		&:hover::before {
			border-bottom: none !important;
		}
	}

	.MuiAutocomplete-endAdornment {
		right: 10px;

		svg {
			color: $black-text;
		}
	}
}
