@import 'colors';

.bookings-advanced-filters-drawer {
	width: 480px;
	height: 100%;
	padding: 24px 20px;
	display: flex;
	flex-direction: column;

	&__title-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		color: $travel-booster-black;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		margin-top: 6px;

		display: flex;

		svg {
			margin-right: 4px;

			path {
				stroke: $black-text;
			}
		}
	}

	&__subtitle {
		font-size: 15px;
		line-height: 25px;
		margin-top: 6px;
		padding-left: 60px;
	}

	&__box {
		background-color: #f8f8f8;
		padding: 16px;
		margin: 12px 0;
		border-radius: 10px;
	}

	&__filter {
		display: flex;
		flex-direction: column;
		margin: 20px 0;

		.tooltip-icon:hover {
			color: $black-text;
			cursor: pointer;
		}

		&.no-margin-top {
			margin-top: 0;
		}

		&.no-margin-bottom {
			margin-bottom: 0;
		}

		&.no-column {
			flex-direction: row;
		}

		label {
			font-size: 15px;
			font-weight: 400;
			display: flex;
			align-items: center;

			svg {
				height: 18px;
				margin-right: 6px;
			}
		}

		& .MuiInputBase-root {
			height: 40px;

			legend {
				max-width: 0.01px; // don't show legend
			}
		}
	}

	&__extras {
		padding: 0 60px;

		.MuiToggleButton-root svg {
			position: absolute;
			left: 14px;
		}
		.MuiToggleButtonGroup-grouped.Mui-selected + .MuiToggleButtonGroup-grouped.Mui-selected {
			border-top: 1px solid transparent;
		}
	}

	&__actions {
		display: flex;
		align-self: flex-start;
		padding: 0 0 60px 20px;
	}
}

.extras-tooltip {
	&__title {
		font-size: 16px;
		margin-bottom: 10px;
		display: flex;
		justify-content: flex-start;
	}

	&__item {
		margin-bottom: 8px;

		> div:last-child {
			font-weight: 300;
		}
	}
}
