@import 'variables';
@import 'representativeDealComponent';
@import 'dealComponent';
@import 'hotelAddressComponent';
@import './mixins/hotelMixins';

.m_supplier {
	background: -webkit-linear-gradient(#fff, #ffeaca) !important; /* For Safari 5.1 to 6.0 */
	border-radius: 4px;

	span.hotel-name {
		color: #336699 !important;
	}
}

.hotel {
	display: block;
	width: 100%;
	box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.078);
	border-radius: 16px;

	&.hotel_match {
		border: 1px solid $secondary-blue;
		box-sizing: content-box;
		border-radius: 17px;
		box-shadow: 0 0 16px rgba(39, 81, 128, 0.2);
		background-color: #f9fcfe;
	}

	&.shallow {
		background: #eeeeee;
	}

	.hotel-inner-container {
		display: flex;
		width: 100%;
		height: 240px;

		.hotel-image {
			background-image: url('../img/hotel_page/hotel_place_h2x.png');
			background-color: #333;
			//background-size: cover;
			//background-repeat: no-repeat;
			//background-position: center;
			border-radius: 16px 0 0 16px;
			width: 280px;
			height: 100%;
			vertical-align: top;
			//position: relative;
			text-align: center;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
				border-radius: 16px 0 0 16px;
				object-fit: cover;
			}

			.pb {
				position: absolute;
				top: 0;
				left: 2px;
			}
		}

		.hotel-content {
			display: flex;
			justify-content: space-between;
			padding: 24px;
			width: 544px;

			.hotel-review {
				display: flex;
				align-items: center;
				gap: 6px;
				color: $gray-1;
				font-size: 15px;
				line-height: 21px;
			}

			.hotel-info {
				position: relative;
				height: 100%;

				.hotel-details {
					width: 100%;

					.title {
						max-height: 50px;
						overflow: hidden;
						margin-top: 4px;

						a,
						button {
							text-align: left;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							text-overflow: ellipsis;
							text-decoration: none;
						}

						a > .hotel-name {
							cursor: pointer;
							transition: 0.1s all ease;

							&:hover {
								color: var(--desktop-hotel-name-hover);
							}
						}

						.hotel-name {
							display: inline;
							font-weight: bold;
							font-size: 21px;
							color: $black-text;
							line-height: 25px;

							text-transform: capitalize;
							margin-right: 4px !important;

							span.fa-stack {
								margin-left: 6px;
								font-size: 6px;
								vertical-align: top;

								&:hover > i {
									color: red;
								}
							}
						}
					}

					@include hotel-address;

					@import 'starsComponent';

					.review-score {
						padding: 2px 6px;
						background: var(--desktop-score-small-background);
						border-radius: 6px;
						font-size: 15px;
						color: var(--desktop-score-small-color);
					}
				}
			}

			.hotel-reasons {
				margin-top: 10px;
				max-height: 60px;
				overflow-x: auto;
				overflow-y: hidden;
				display: block;
				color: $gray-secondary;

				@include reasons(true);

				ul {
					padding-left: 0;

					li {
						display: flex;

						&.inline {
							margin-left: 16px;
						}
					}
				}
			}

			.amenities-container {
				display: flex;
				margin-top: auto;
				gap: 8px;

				.amenity {
					width: 16px;
					height: 16px;

					.icon {
						width: inherit;
						height: inherit;
					}
				}
			}

			.no-deal-shallow-wrapper {
				height: auto;
				padding: 0;
				display: flex;
				justify-content: space-between;
				width: 496px;
				color: $new-brown-text;

				.shallow {
					width: 100%;
					display: flex;
					align-items: center;
					font-size: 15px;
				}

				@include select-button;
			}
		}

		&.extended {
			height: 270px;

			.amenities-container {
				margin-bottom: 10px;
			}
		}
	}
}
