@import 'variables';

.referral-page {
	max-width: 1092px;
	min-width: 800px;
	height: 100%;
	margin: 0 auto;
	padding: 10px;
	padding-top: 36px;
	position: relative;
	padding-bottom: 64px;
	position: relative;

	& > div {
		display: inline-block;
		width: 50%;
		vertical-align: top;
	}

	.text-container {
		width: 40%;

		h1 {
			img {
				margin-right: 2px;
			}

			span {
				vertical-align: middle;
				font-weight: bold;

				&.arbi {
					color: #0b4e96;
				}

				&.trip {
					color: #35a8df;
				}
			}
		}

		.terms {
			padding-top: 5px;
			padding-bottom: 20px;
			font-size: 24px;
			h3,
			h4 {
				margin-top: 15px;
			}
			span {
				display: block;
			}
		}

		.invitation {
			h4 {
			}

			.frm {
				//border: 1px solid #ccc;
				//border-radius: 4px;
				width: 370px;
				height: 195px;
				padding: 3px;
				position: relative;

				label {
					width: 30%;
					font-weight: 400;
				}

				input {
					width: 70%;
					//border: none;
					//border-bottom: 1px solid #ccc;
					display: inline-block;
					&:focus {
						outline: none;
					}

					&[type='text'] {
						margin-bottom: 5px;
						//text-transform: capitalize;
					}
				}

				small {
					width: 70%;
					float: right;
				}

				button {
					margin-top: 10px;
					float: right;
					width: 70%;
				}

				.form-hover {
					position: absolute;
					background: rgba(255, 255, 255, 0.9);
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;

					.busy,
					.success,
					.failed {
						text-align: center;

						i.fa {
							margin-right: 4px;
							vertical-align: middle;
						}

						span {
							font-size: 20px;
							color: #555555;
							vertical-align: middle;
						}
					}

					.busy {
						margin-top: 59px;
					}

					.success,
					.failed {
						margin-top: 60px;
					}

					.success {
						i.fa {
							color: #00ae80;
						}
					}
				}
			}
		}
	}

	.image-container {
		width: 60%;
		height: 480px; //495px;

		.bg {
			width: 100%;
			height: 100%;
			background: #918c86;
			background-image: url(../img/referral.jpeg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			border-radius: 0 4px 4px 0;

			.gradient {
				width: 100%;
				height: 100%;
				background: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
			}
		}
	}
}
