@import 'colors';

@import 'redeemPoints';

// $selected-booking-color: hsl(200.17deg 61.66% 62.16% / 24%);
$hovered-booking-color: rgb(248 248 248);

.booking-drawer {
	top: calc(#{$top-bar-height} + 1px) !important;

	.actions {
		margin: 20px;
		display: flex;
		justify-content: space-between;

		button {
			&.MuiButton-textPrimary {
				color: var(--desktop-bookings-button);
				border-color: var(--desktop-bookings-button);
			}
			z-index: 12;

			.circular-progress {
				position: absolute;
				right: 8px;
			}
		}

		&.column {
			flex-direction: column;
			align-items: flex-start;

			.MuiButton-root:not(:first-child) {
				margin-top: 12px;
			}
		}

		.MuiButton-root:not(:last-child) {
			margin-right: 8px;
		}
	}

	.booking-drawer-inner {
		width: 460px;
		padding-bottom: 60px;
		position: relative;
		overflow-y: auto;
		max-height: 100vh;

		.booking-title {
			padding: 20px 20px;
			position: sticky;
			top: 0;
			background-color: white;
			z-index: 1;

			&.shadow {
				box-shadow: 0 4px 1px 0 #00000014;
			}

			.trip-to {
				font-size: 21px;
				line-height: 25px;
				font-weight: 800;
				// height: 50px;
			}

			.city {
				margin: 8px 0 10px;
				font-size: 14px;
				// height: 60px;

				> div:last-child {
					font-weight: 300;
				}
			}

			.booking-status {
				text-transform: capitalize;
			}
		}

		.booking-field {
			background-color: $gray-background-light;
			margin: 0 20px 20px;
			padding: 12px 16px 12px 16px;
			border-radius: 10px;

			&.active {
				background-color: $blue-bright-message;
			}

			&.capitalize {
				text-transform: capitalize;
			}

			.field-row {
				display: flex;
				justify-content: space-between;
				line-height: 25px;

				.field-icon {
					cursor: pointer;
				}

				.field-title {
					font-size: 16px;
					font-weight: 800;
				}

				.field-value {
					font-size: 15px;
					font-weight: 400;
					width: 100%;
					white-space: pre-line;
					position: relative;

					.space {
						margin-top: 8px;
						display: flex;

						svg {
							margin-right: 4px;
						}
					}

					.booking-points {
						position: relative;

						.booking-points-value {
							display: inline-block;
						}

						.booking-points-discount {
							display: inline-block;
							color: #008f4b; // Green
							margin-left: 24px;
						}

						// .booking-points-icon {
						//   width: 36px;
						//   height: 36px;
						//   background-color: #D1F0FF; // Light Blue
						//   border-radius: 48px;
						//   position: absolute;
						//   right: 0;
						//   top: -12px;
						//   display: flex;
						//   align-items: center;
						//   justify-content: center;
						// }
					}
				}

				&.field-action {
					margin-top: 8px;
					align-content: center;
					justify-content: end;

					.notes-action-item {
						align-self: center;

						&.busy-indicator {
							margin-right: 8px;
						}
					}
				}

				.agent-notes {
					background-color: white;
					margin-top: 5px;

					& > div {
						border-radius: 8px;
					}
				}
			}

			.show-more-btn {
				color: var(--desktop-bookings-show-more);
				background: transparent;
			}
		}

		// }
	}

	.placeholder {
		height: 10px;
	}

	.booking-reservation-summary,
	.payment-details {
		display: flex;
		justify-content: flex-start;
		margin-top: 6px;

		&.breakdown {
			justify-content: space-between;

			> div {
				width: auto;

				&:first-child {
					min-width: 10px;
				}
			}
		}

		&.bold {
			font-weight: bold;
		}

		&.margin-top {
			margin-top: 28px;
		}

		&.margin-bottom {
			margin-bottom: 18px;
		}

		> div {
			width: 100px;

			&:first-child {
				margin-right: 22px;
				min-width: 150px;
			}
		}

		.policy {
			.in_policy,
			.out_of_policy {
				display: flex;
				align-items: center;
				white-space: nowrap;
			}

			.in_policy {
				color: $text-green;
			}

			.out_of_policy {
				color: $text-orange;
			}

			svg {
				margin-right: 4px;
			}
		}

		.pay-at-the-hotel {
			width: 100%;
		}

		.booking-points-icon {
			width: 36px;
			height: 36px;
			background-color: #d1f0ff; // Light Blue
			border-radius: 48px;
			position: absolute;
			right: 0;
			bottom: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.tooltip-icon {
			background: url('../img/icons/tooltip.svg') right center no-repeat;
			background-size: 13px 13px;
			padding-right: 18px;
		}

		.tooltip-icon:hover {
			color: $black-text;
			cursor: pointer;
		}

		.booking-points-update {
			margin-left: 24px;
			button {
				padding: 0 !important;
			}
		}
	}
}

.new-bookings-page {
	width: 100%;
	height: 100%;

	.bookings-loading {
		display: block;
		width: 100%;
		margin-bottom: 20px;

		font-size: 36px;
		margin-top: 7%;
		text-align: center;
		padding: 25px;
	}

	.tabs-wrapper {
		width: 100%;
		margin: 0 auto;
		padding: 16px 66px;

		.MuiTabs-root,
		.tabs-loader {
			max-width: 1600px;
			margin: 0 auto;
			min-height: 32px;

			.MuiTabs-indicator {
				display: none;
			}

			.MuiTabs-flexContainer {
				display: flex;
				gap: 16px;
			}
		}

		.MuiTab-root {
			background-color: $gray-5;
			border-radius: 4px;
			width: 140px;
			min-height: 32px;

			&.Mui-selected {
				background-color: $gray-1;
				color: $gray-5;
				font-weight: bold;
			}

			&:not(.Mui-selected):hover {
				border: 1px solid $gray-4;
			}
		}
	}

	.bookings-table-component {
		margin: 0 auto;
		padding: 0 66px 100px;

		.bookings-table-container {
			box-shadow: none;
			overflow-x: unset;
			max-width: 1600px;
			margin: 0 auto;

			table.bookings-table {
				border-collapse: separate;
				border-spacing: 0 4px;

				tr.booking-row {
					cursor: pointer;
					border-radius: 4px;

					&.power-user {
						border-radius: 4px 4px 0 0;
					}

					&.Mui-selected,
					&.Mui-selected:hover {
						background-color: $blue-bright-message;
					}

					&:hover {
						background-color: $hovered-booking-color;

						td:first-child {
							border-start-start-radius: 4px;
							border-end-start-radius: 4px;
						}

						td:last-child {
							border-start-end-radius: 4px;
							border-end-end-radius: 4px;
						}
					}

					&:focus-visible {
						outline: $color-outline-tab 2px auto;
					}
				}

				th {
					font-size: 13px;
					line-height: 19px;
					color: #919595;

					.MuiTableSortLabel-root:focus-visible {
						outline: $color-outline-tab 2px auto;
					}
				}

				td {
					font-size: 15px;
					line-height: 21px;
					color: $black-text;
				}

				th,
				td {
					border: 0;
					max-width: 10vw;
					overflow: hidden;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;

					&:first-child {
						padding: 0 16px;
					}

					&:last-child {
						padding-right: 16px;
					}

					&.cell__hotel {
						max-width: 15vw;
					}

					&.cell__cancellation {
						max-width: 15vw;
					}
				}

				tbody {
					tr {
						box-shadow: 0 0 8px 0 #0000001a;
						-webkit-box-shadow: 0 0 8px 0 #0000001a;
						-moz-box-shadow: 0 0 8px 0 #0000001a;
						border-radius: 6px;
					}
				}

				tfoot {
					tr {
						.bookings-table-pagination {
							margin-top: 16px;
						}
					}
				}
			}

			&.loader {
				table.bookings-table {
					th,
					td {
						text-overflow: inherit;
						white-space: inherit;
					}
				}
			}
		}

		.booking-client-logo-container {
			width: 32px;
			height: 32px;
			border: 1px solid #0000001a;
			border-radius: 4px;
			padding: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: white;

			.booking-client-logo {
				max-width: 28px;
				max-height: 28px;
			}
		}
	}

	.no-bookings {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 100px;

		div {
			margin-top: 24px;
			text-align: center;
		}
	}
}
