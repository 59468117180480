@import 'colors';

$travel-booster-black: $black-text;
$travel-booster-gray: #919595;
$travel-booster-main-blue: #275180;
$travel-booster-light-gray: rgba(216, 216, 216, 0.102797);
$travel-booster-white: #ffffff;

.travel-booster-title {
	display: flex;
	border-bottom: 1px solid $travel-booster-gray;
	padding-bottom: 16px;

	.title-text {
		font-size: 24px;
		font-weight: 700;
	}

	.title-logo {
		margin-top: 8.5px;
		height: 28px;

		img {
			// width: 180px;
			width: 120px;
		}
	}

	.close-button {
		// height: 28px;
		margin-right: -16px;
		margin-left: auto;
	}
}

.travel-booster {
	// position: relative;

	hr {
		border-color: $travel-booster-gray;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.x-button {
		position: absolute;
		top: 13.5px;
		right: 0;

		color: #888;
	}

	.reservation-summary {
		// .reservation-summary-title {
		//     background-color: white;
		// }

		.column-item {
			width: 50%;
			display: inline-block;

			&.full-width {
				height: 80px;
				width: 100%;

				span.reservation-summary-item-value {
					position: relative;
				}

				.edit-docket-number {
					background-color: $travel-booster-light-gray;
				}

				.edit-button {
					width: 50px;
					position: absolute;
					top: -5px;
					right: 34px;
				}
			}

			&.docket-number {
				margin-top: 36px;
				margin-bottom: 36px;
			}

			.reservation-summary-item {
				flex-direction: column;

				&:not(:first-child) {
					margin-top: 20px;
				}

				span {
					&.reservation-summary-item-title {
						font-weight: 700;
						width: 100%;
					}

					&.reservation-summary-item-value {
						width: 100%;
					}
				}
			}
		}

		.reservation-summary-item {
			display: flex;
			font-size: 14px;
			color: $travel-booster-black;

			span {
				display: inline-block;

				&.reservation-summary-item-title {
					width: 33%;

					&.main-title {
						width: 100%;
						font-weight: 700;
					}
				}

				&.reservation-summary-item-value {
					width: 67%;
				}
			}

			// &.vertical-item {
			//     flex-direction: column;
			// }
		}
	}

	.travel-booster-id {
		position: relative;

		.travel-booster-id-input {
			label {
				width: 200px;
			}

			input {
				&::placeholder {
					opacity: 0.5;
				}
			}
		}

		.edit-button {
			position: absolute;
			bottom: 0;
		}
	}

	.footer {
		display: flex;

		.status {
			display: flex;
			// padding-top: 10px;
			position: relative;
			width: 35%;

			// .busy {
			//     height: 41px;
			//     justify-items: center;
			// }

			.status-text,
			.busy {
				position: absolute;
				bottom: 0;
			}
		}

		.action-buttons {
			margin-left: auto;
			display: flex;
			justify-content: flex-end;
			// width: 33%;
			height: 41px;

			.busy {
				padding-left: 4px;
			}

			.action-button {
				width: 153px;
				height: 41px;
				font-size: 14px;
				text-transform: none;
			}

			.sync-button {
				background-color: $travel-booster-main-blue;
				color: $travel-booster-white;

				margin-left: 16px;
			}

			.close-button {
				color: $travel-booster-gray;
			}
		}

		.status {
			// display: inline-block;
			// width: 67%;
			// text-align: right;
			// justify-items: center;
			height: 41px;

			// .busy {

			// }

			.status-text {
				&.succeeded {
					color: $green;
				}

				&.failed {
					color: $red;
				}
			}
		}
	}
}
