@import 'variables';
@import 'travelersCompleteComponent';
@import 'airportsCompleteComponent';

.forward-requests-page {
	//max-width: 1092px;
	//min-width: 1092px;
	//height: 100%;
	//margin: 0 auto;
	//padding-top: 36px;
	//position: relative;
	//color: #4A4A4A;
	font-family: $secondary-font;
	font-weight: normal;
	margin-left: auto;
	max-width: 1080px;
	min-width: 760px;

	hr.separator {
		margin: 10px 25px 10px 25px;
	}

	.name-bar {
		.action {
		}

		.name {
			margin-top: 2px;
			max-width: 90%;

			.view-name,
			.edit-name {
				min-width: 210px;
				max-width: 100%;
				min-height: 31px;
				display: block;
				font-size: 20px !important;
			}

			.view-name {
				cursor: text;
				white-space: nowrap;
			}

			&:hover {
				.view-name {
					border-bottom: 1px solid #e5e5e5;
				}
			}

			.edit-name {
				margin-top: -36px;
				padding-bottom: 4px;
			}

			.action.edit {
			}
		}

		.action.cancel {
		}
	}

	.forward-requests.paper {
		//padding: 0 !important;

		.meta {
			margin-bottom: 108px;
			&.busy {
				opacity: 0.5;
				cursor: progress;
				.field {
					pointer-events: none;
				}
			}
			.title {
				font-size: 16px;
				font-weight: bold;
				color: #333;
			}

			.field {
				width: 40%;
			}

			.additional-info.section {
				.field {
					width: 93% !important;
				}
			}
		}

		.requests {
			&.busy {
				opacity: 0.5;
				cursor: progress;
			}

			.title-line,
			.flight-container {
				border-bottom: 1px solid #e5e5e5;
			}

			.title-line {
				//margin-left: 9px;
				height: 48px;
				& > span {
					display: inline-block !important;
					width: 93% !important;
				}

				.check {
					display: inline-block !important;
					width: 24px !important;
				}
			}

			.components {
				.flight-container {
					display: block;
					max-height: 75px;

					&:hover,
					&.checked {
						background: $arbi-selection-background-color;
					}

					.add-component {
						width: 93% !important;
						display: inline-block !important;
						position: relative;
						//padding-left: 24px;
						padding-bottom: 12px;
						//max-height: 64px;
						&:last-of-type {
							margin-bottom: 20px;
						}

						&.checked {
							background: $arbi-selection-background-color;
						}

						.component-icon,
						.field {
							display: inline-block;
						}

						.component-icon {
							margin-right: 12px;
							padding-bottom: 10px;

							i.fa {
								font-size: 18px;
								color: #ccc;
							}
						}

						.field {
							width: 24%;
							vertical-align: bottom;

							//&:not(:last-child) {
							margin-right: 20px;
							//}

							&.dates {
								max-width: 180px;
							}
							.field-input {
								display: inline-block;
								vertical-align: bottom;

								&,
								& div {
									cursor: text !important;
								}

								label {
									white-space: nowrap;
								}

								&.date {
									width: 40% !important;
									max-width: 80px;
									//margin-right: 20px;

									& + .field-input.select {
										input {
											width: 80px !important;
										}
									}
								}

								&.select {
									padding-left: 20px;
									width: 100% !important;
									max-width: 80px;
								}
							}
						}

						.check {
							display: inline-block;
							width: 24px;
							vertical-align: text-bottom;
						}
					}

					.check {
						display: inline-block;
					}
				}
			}
		}

		.requests {
			margin-bottom: 40px;
		}

		.actions-container {
			position: relative;
			border-top: 1px solid #e5e5e5;
			padding-top: 15px;
			font-size: 12px;

			&.busy {
				cursor: progress;
				a.action {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.bottom-right-corner {
				position: absolute;
				right: 0;
				margin-right: 0 !important;
				top: 12px;

				&.busy-indicator {
					&.fa-spinner {
						font-size: 20px;
					}
				}
			}

			a.action {
				display: inline-block;
				margin-right: 24px;
				color: #999;

				&.disabled {
					cursor: not-allowed;
				}

				&:not(.disabled):hover {
					color: #333;
				}

				&.trash {
					position: absolute;
					top: 20px;
					right: 0;
				}
			}

			span.saving {
				position: absolute;
				right: 56px;
				color: #999;
			}
		}
	}
}
