@import 'variables';

.balance {
	width: 50%;
	text-align: left;

	.balance-title {
		display: block;
		font-size: 16px;
		margin-bottom: 6px;
	}

	.balance-value {
		font-weight: 600;
		line-height: 28px;
	}
}

.pending-balance {
	color: #a6a8ab;

	i.fa.fa-question-circle {
		color: #2a99cf;
		cursor: pointer;
	}
}
