@import 'variables';
@import './mixins/reservationMixins';

.reservation-dialog-main .MuiPaper-root {
	width: auto;
}

.reservation-dialog-container {
	padding: 26px 40px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.image_header {
		width: 128px;
		height: 128px;
		background-size: 128px 128px;
		justify-self: center;
		margin-top: 16px;

		&.pending-icon {
			background-image: url('../img/reservation_page/confirming-reservation.svg');
		}

		&.error-icon {
			background-image: url('../img/reservation_page/failed_reservation.svg');
		}

		&.pricing-icon {
			background-image: url('../img/reservation_page/attention_reservation.svg');
		}

		&.confirm-icon {
			background-image: url('../img/reservation_page/processing_reservation.svg');
		}
	}

	h3.title {
		@include container-title-typography;
		font-size: 21px;
		margin-top: 32px;
		text-align: center;

		&.red {
			color: $text-red;
		}

		&.green {
			color: $text-green;
		}
	}

	.message {
		text-align: center;
		margin-top: 16px;
		margin-bottom: 20px;
		color: $gray-1;
	}

	.additional-message {
		min-height: 80px;
		align-content: end;

		.additional-message-text {
			text-align: center;
			background-color: #ffe6e6;
			padding: 10px;
			border-radius: 8px;
			color: $gray-1;
		}
	}

	.additional-placeholder {
		content: none;
		min-height: 80px;
	}

	.price-changed-container {
		font-size: 15px;
		text-align: center;
		color: $gray-2;
		line-height: 21px;
		min-height: 80px;
		align-items: center;

		.price {
			font-weight: 700;
			font-size: 24px;
			line-height: 29px;
			color: var(--general-colors-black);
		}
	}

	.action-buttons {
		justify-items: center;
	}

	.button-primary {
		width: 180px;
		height: 40px;
		background: $main-blue;
		border-radius: 6px;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $white;
		margin-top: 16px;
	}

	.button-secondary {
		width: 180px;
		height: 40px;
		border-radius: 6px;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		color: $main-blue;
		margin-top: 16px;
	}

	.right-arrow {
		width: 24px;
		height: 24px;
		background-size: 24px 24px;
		background-image: url('../img/reservation_page/arrow_right.svg');
		margin: auto 20px 18px;
	}

	.id-text {
		margin-top: 10px;
		color: $gray-2;
	}

	.footer {
		color: $gray-1;
		text-align: center;
		margin-top: 20px;
		border-top: 1px solid $gray-4;
		width: 100%;

		.footer-header {
			margin-top: 12px;
			margin-bottom: 12px;
		}

		.footer-numbers {
			justify-content: space-between;
			min-width: 500px;
		}
	}
}
