@import 'colors';
@import 'variables';
@import 'filtersComponent';
@import 'searchComponent';
@import 'searchFull';
@import 'resultsComponent';
@import './mixins/filtersMixins';

$modal-paper-height: 225px;
$modal-height: 223px;
div.results-progress {
	width: 100%;
	position: fixed;
	top: $top-bar-height;
	z-index: 555;
}

.results-page {
	width: 1102px; //$width-main;
	height: 100%;
	margin: 0 auto;

	.scroll {
		background: none;
		width: 40px;
		height: 40px;
		position: fixed;
		border-radius: 5px;
		border: none;

		bottom: 40px;
		left: 16px;

		cursor: pointer;
		transition-duration: 0.2s;
		transition-timing-function: linear;
		transition-delay: 0s;

		&:hover {
			opacity: 1;
		}

		.arrow-up {
			position: absolute;
			top: 50%;
			left: 50%;

			font-size: 32px;
			color: #777;
		}
	}

	> div {
		height: 100%;
		display: inline-block;
		vertical-align: top;
		margin-top: 36px; //24px;//36px;
	}

	.search-results-container {
		margin-left: 28px; //1%; // PATCH
		width: 824px; //820px;//844px;//$width-main - 266px - 24px;// PATCH
		margin-bottom: 16px;

		.results-actions {
			display: flex;
			gap: 16px;
			height: 50px;
			width: 100%;
			margin-top: 24px;

			.MuiFormControl-root {
				min-width: 248px;
				height: 46px;
				margin-left: auto;

				.MuiInputBase-root {
					height: 49px;
					color: $gray-1;
					font-size: 15px;
					font-weight: 700;
				}

				.MuiOutlinedInput-input {
					padding: 14px 40px 14px 20px;
				}

				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $gray-3;
				}

				.MuiSelect-select {
					&:focus-visible {
						outline: $color-outline-tab 2px auto;
					}
				}
			}
		}

		.travel-policy-component,
		.leisure-experience-component {
			display: flex;
			align-items: center;
			gap: 4px;
			height: 50px;
			width: 100%;
			min-width: 282px;
			padding: 12px;
			background-color: #dbffee;
			border-radius: 4px;
			color: $new-green-text;
			font-size: 1.05rem;
			line-height: 19px;

			&.loader {
				padding: 0;
			}

			.icon {
				min-width: 14px;
				min-height: 14px;

				&.in-policy {
					width: 14px;
					height: 14px;
					background: url('../img/results_page/check_green.svg') center center no-repeat;
				}
			}
		}

		.private-travel-component {
			display: flex;
			align-items: center;
			height: 50px;
			width: 100%;
			min-width: 282px;
			background-color: #dbffee;
			border-radius: 4px;
			padding: 12px 28px 12px;
			color: $new-green-text;
			font-size: 1.05rem;
		}

		.internals-component {
			height: 24px;
			background-color: $blue-background;
			@include Font-Regular($secondary-font, $base-font-size-13, $main-blue);

			position: relative;

			span {
				margin-right: 24px;

				&.time {
					position: absolute;
					right: 0;
				}
			}

			padding-left: 24px;
			vertical-align: middle;
			line-height: 24px;
			margin-top: 10px;
			margin-bottom: -1px; //-14px;
		}
	}

	.trust-you {
		width: 100%;
		margin: 8px 0 16px 0;

		a {
			float: right;
			img {
				height: 25px;
				padding: 0 15px;
			}
		}
	}
}

#map-box-overlay,
.results-page {
	@include filters;
}

.results-page {
	.filters-container .filters .filter-container:first-child > .filter {
		border-top: 0;
	}
}

#map-box-overlay {
	.filters-container {
		padding: 2px;
		width: 360px;
		height: 100%;
		overflow: auto;
	}
}
