$action-icon-size: 23px;

.travel-policy-page {
	gap: 44px;
	width: 100%;
	height: 100%;
	padding: 48px 72px 26px 66px;
	position: relative;

	.left-section {
		position: fixed;
		top: 120px;
		left: 30px;
		max-width: 289px;

		.header {
			font-size: 24px;
			font-weight: 700;
		}

		.description {
			margin-top: 8px;
			font-size: 13px;
			color: $gray-2;
		}

		.search {
			margin-top: 20px;
			margin-bottom: 32px;

			.MuiInputBase-root {
				height: 42px;
				width: 289px;

				.search-icon {
					min-width: 24px;
					margin-left: -4px;
					margin-right: 8px;
				}

				.close-icon {
					margin-right: -16px;
				}

				.MuiInputBase-input::placeholder {
					color: $gray-2;
					opacity: 1;
					font-size: 15px;
				}

				.MuiInputBase-input {
					font-size: 15px;
				}
			}
		}
	}

	.right-section {
		margin-left: 289px;
		width: calc(100% - 289px);

		.travel-policy-table-container {
			box-shadow: none;
			overflow-x: unset;
			margin: 0 auto;

			.travel-policy-table {
				border-collapse: separate;
				border-spacing: 0;

				.country-row {
					height: 64px;
					box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
					border-radius: 8px;
					cursor: pointer;

					&:hover {
						background-color: $gray-5;

						td:first-child {
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
						}

						td:last-child {
							border-top-right-radius: 8px;
							border-bottom-right-radius: 8px;
						}
					}

					td {
						border: none;
					}

					.flag-cell {
						padding-left: 24px;
						width: 70px;
						min-width: 70px;
					}

					.country-name {
						font-size: 15px;
						font-weight: 700;
					}
				}

				.entry-row {
					height: 64px;
					background-color: rgba(215, 218, 218, 0.1);

					.MuiTableCell-root {
						border: none;
						padding: 0;

						&:first-child {
							.entry-row-wrapper {
								padding-left: 8px;
								border-top-left-radius: 8px;
								border-bottom-left-radius: 8px;
							}
						}

						&:last-child {
							.entry-row-wrapper {
								border-top-right-radius: 8px;
								border-bottom-right-radius: 8px;
							}
						}

						&:not(:first-child) {
							.entry-row-wrapper {
								border-left: none;
							}
						}

						&:not(:last-child) {
							.entry-row-wrapper {
								border-right: none;
							}
						}

						.entry-row-wrapper {
							height: 64px;
							border: 1px solid #d7dada;
						}
					}

					.flag-cell {
						width: 70px;
						min-width: 70px;
					}

					.place-name {
						min-width: 350px;
						font-size: 15px;

						.entry-row-wrapper {
							padding-left: 16px;
						}
					}

					.price-cell {
						width: 100%;
						font-size: 15px;
						font-weight: 700;

						.entry-row-wrapper {
							padding-left: 16px;
						}

						.edit-price-input {
							.currency-symbol {
								.MuiTypography-root {
									margin-right: -4px;
									font-size: 15px;
									color: $black-text;
								}
							}

							.MuiInputBase-root {
								height: 38px;
								width: 84px;
								font-size: 15px;

								&.Mui-focused {
									background-color: $light-blue;

									.MuiOutlinedInput-notchedOutline {
										border-color: $secondary-blue;
									}
								}
							}
						}
					}
				}

				.worldwide-row {
					height: 64px;
					box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
					border-radius: 8px;

					&:hover {
						background-color: $gray-5;

						td:first-child {
							border-top-left-radius: 8px;
							border-bottom-left-radius: 8px;
						}

						td:last-child {
							border-top-right-radius: 8px;
							border-bottom-right-radius: 8px;
						}
					}

					td {
						border: none;
					}

					.flag-cell {
						padding-left: 24px;
						width: 70px;
						min-width: 70px;
					}

					.country-name {
						font-size: 15px;
						font-weight: 700;
						min-width: 350px;
					}

					.price-cell {
						width: 100%;
						padding: 0;
						font-size: 15px;
						font-weight: 700;

						.entry-row-wrapper {
							padding-left: 16px;
						}

						.edit-price-input {
							.currency-symbol {
								.MuiTypography-root {
									margin-right: -4px;
									font-size: 15px;
									color: $black-text;
								}
							}

							.MuiInputBase-root {
								height: 38px;
								width: 84px;
								font-size: 15px;

								&.Mui-focused {
									background-color: $light-blue;

									.MuiOutlinedInput-notchedOutline {
										border-color: $secondary-blue;
									}
								}
							}
						}
					}
				}

				.actions-cell {
					min-width: 200px;
					padding: 0;

					.entry-row-wrapper {
						padding-right: 16px;

						.action-icon {
							padding: 8px;
							border: 0;
							background-size: $action-icon-size;
							width: $action-icon-size;
							height: $action-icon-size;
							background-color: $gray-1;
							mask-repeat: no-repeat;
							mask-size: $action-icon-size;
							cursor: pointer;

							&:hover {
								background-color: $secondary-blue;
							}

							&.remove-button {
								mask-image: url('../img/icons/trash_bin.svg');
								margin-right: 24px;
							}

							&.edit-button {
								mask-image: url('../img/icons/pencil.svg');
								margin-right: 8px;
							}
						}

						.action-button {
							width: 66px;
							height: 35px;
							line-height: 17px;

							&.cancel {
								margin-right: 16px;
							}
						}
					}
				}

				.collapsible-cell {
					padding: 0;
					border: none;
				}
			}
		}
	}

	.currency-symbol {
		.MuiTypography-root {
			margin-right: -4px;
			font-size: 15px;
			color: $black-text;
		}
	}
}
