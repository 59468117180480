$color-white: white;
$color-outline-tab: #e69700;
$blue-link: #4790b4;
$text-gray-bright: #6e7272;

button {
	all: unset;
	text-align: center;
	cursor: pointer;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;

	&:focus-visible {
		outline: $color-outline-tab 2px auto;
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&.tab-theme-color:focus-visible {
		border-color: $blue-link;
		box-shadow: inset 0 0 0 2px $blue-link;
		outline: none;
	}
}

button[aria-disabled='true']:not(.default-style) {
	cursor: not-allowed !important;
	color: $text-gray-bright !important;
	background-color: $color-white !important;
	box-shadow: none !important;
	border: 1px solid $text-gray-bright !important;

	a {
		color: $text-gray-bright !important;
	}

	svg path {
		fill: $text-gray-bright !important;
	}
}

button[aria-disabled='true'].no-border {
	border: 0 !important;
}

.MuiCheckbox-root {
	&.Mui-focusVisible {
		border-radius: 2px;
		outline: $color-outline-tab 2px auto;
	}
}
