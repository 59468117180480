@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?v6iz67');
	src:
		url('../fonts/icomoon.eot?v6iz67#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?v6iz67') format('truetype'),
		url('../fonts/icomoon.woff?v6iz67') format('woff'),
		url('../fonts/icomoon.svg?v6iz67#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.cancellation-term {
	.icon-at-cancellation-o,
	.icon-at-cancellation,
	.fa-comment-o {
		margin: 5px;
	}
}

.icon-at-cancellation-o:before {
	content: '\e900';
}
.icon-at-cancellation:before {
	content: '\e901';
}
.icon-at-breakfast-o:before {
	content: '\e902';
}
.icon-at-breakfast:before {
	content: '\e903';
}

.MuiOutlinedInput-root {
	&:hover .MuiOutlinedInput-notchedOutline {
		border-color: var(--desktop-bookings-button);
	}
	&.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: var(--desktop-bookings-button);
	}
}

.MuiLinearProgress-root {
	background: var(--desktop-linear-progress-track);
}

.MuiLinearProgress-bar {
	background: var(--desktop-linear-progress-bar);
}
