.search-box-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 74px;
	padding: 6px 8px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0px 26px 18px 0px rgba(17, 72, 113, 0.16);

	.search-box-wrap {
		position: relative;
		display: flex;
		width: 100%;
		height: 100%;
	}

	.autocomplete {
		position: relative;
		display: flex;
		flex: 1;
		margin-right: 6px;
		margin-right: 8px;
		background-color: $gray-5;
		border-radius: 8px;

		.css-outlined-input {
			background-color: $gray-5;
			border-radius: 8px;
		}

		.paper-root > div {
			max-height: 400px;
		}
	}

	.autocomplete-wrap {
		width: 100%;

		.recent-searces-container {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.recent-searces-info {
				width: 90%;

				.ellipsis {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.adress {
					font-size: 14px;
					font-weight: bold;
					margin-bottom: 4px;
				}

				.icons {
					@include Font-Regular($secondary-font, $base-font-size-13, $gray-border-dark);

					.icon {
						margin-left: -5px;
						margin-top: -2px;
					}
				}
			}

			.remove-button {
				display: none;
				border: 0;
				background-size: 24px 24px;
				width: 24px;
				height: 24px;
				background-color: #dcd9d9;
				mask-image: url('../img/recent_searches/x-close.svg');
				mask-repeat: no-repeat;
				mask-size: 24px 24px;
				cursor: pointer;

				&:hover {
					background-color: #555555;
				}
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border: none;
		}

		.MuiListItemIcon-root {
			min-width: 30px;
		}

		.MuiMenuItem-root {
			&:hover {
				.remove-button {
					display: block;
				}
			}
		}
	}

	.dates {
		position: relative;
		display: flex;
		margin-right: 8px;
		background-color: #fff;
		border-radius: 8px;
		cursor: pointer;

		.label {
			position: absolute;
			top: 10px;
			left: 34px;
			font-weight: 400;
			font-size: 15px;
			color: $gray-2;
			z-index: 1;
			pointer-events: none;

			&.check-out {
				left: 196px;
			}
		}
	}

	.data {
		position: relative;
		display: flex;
		background-color: var(--desktop-search-background);
		border-bottom: 2px solid var(--desktop-search-background);
		border-radius: 8px;
		margin-right: 8px;
		padding-right: 10px;
		cursor: pointer;

		&:hover:not(.focused) {
			background-color: var(--desktop-search-hover);
			border-bottom: 2px solid var(--desktop-search-hover);
		}

		&.focused {
			border-bottom: 2px solid var(--desktop-search-border);
		}
	}

	.search {
		min-width: 60px;
		min-height: 60px;
		max-width: 60px;
		max-height: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--general-primary-button-background);
		border-radius: 8px;
		cursor: pointer;

		&:not(.search-disabled):hover {
			background: var(--general-primary-button-hover);
		}
	}

	.search-disabled {
		background: var(--desktop-search-background);
		cursor: not-allowed !important;
	}

	.search-icon {
		width: 24px;
		height: 24px;
		margin: 18px 10px 0 14px;
	}

	.calendar-icon {
		pointer-events: none;
		position: absolute;
		top: 17px;
		left: 10px;
		z-index: 1;

		&.check-out {
			left: 172px;
		}
	}

	&.small {
		height: 60px;
		padding: 0;
		background-color: transparent;
		box-shadow: none;

		.autocomplete {
			.paper-root {
				width: initial;
				min-width: 100%;
				max-width: 515px;
			}
		}

		.value-container {
			height: 100%;
		}

		.data {
			margin-right: 10px;
		}

		.DateInput {
			width: 124px;
		}

		.dates {
			.label {
				&.check-out {
					left: 166px;
				}
			}

			.calendar-icon {
				&.check-out {
					left: 143px;
				}
			}
		}

		.search-data-content-wrap {
			font-size: 14px;
			line-height: 18px;
		}
	}
}
