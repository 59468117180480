.arbithing {
	.arbi {
		color: #0e4b94;
	}
	.thing {
		color: #40ade2;
	}
	.bold {
		font-weight: 600; //900;
	}
}
