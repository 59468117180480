$column-width: 300px;
$left-column-width: 120px;
$right-column-width: 170px;

$retroactive-column-width: 330px;
$retroactive-left-column-width: 120px;
$retroactive-right-column-width: 200px;

.redeem-points {
	display: flex;
	flex-direction: column;

	.content-loader {
	}

	.points-usage {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.points-balance {
			display: flex;
			flex-direction: column;

			.balance-title {
				font-family: Lato;
				font-size: 15px;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: 0px;
				text-align: left;

				height: 21px;
			}

			.balance-amount {
				display: flex;
				flex-direction: row;

				height: 34px;

				.balance-points {
					font-family: Lato;
					font-size: 28px;
					font-weight: 600;
					line-height: 34px;
					letter-spacing: 0px;
					text-align: left;
				}

				.balance-suffix {
					margin-left: 4px;
					margin-bottom: 1px;

					font-family: Lato;
					font-size: 15px;
					font-weight: 400;
					line-height: 21px;
					letter-spacing: 0px;
					text-align: left;
					align-self: flex-end;
				}

				.balance-value {
					margin-left: 8px;

					font-family: Lato;
					font-size: 15px;
					font-weight: 600;
					line-height: 21px;
					letter-spacing: 0px;
					text-align: left;
					align-self: flex-end;
				}
			}
		}

		.usage-amount {
			width: $column-width;
			position: relative;

			&.retroactive {
				width: $retroactive-column-width;
			}

			.amount-title {
				height: 21px;

				font-family: Lato;
				font-size: 15px;
				font-weight: 400;
				line-height: 21px;
				letter-spacing: 0px;
				text-align: left;
			}

			.amount-input {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				// align-items: center;
				// justify-content: center;

				.amount-value {
					width: $left-column-width;
					// height: 42px

					&.retroactive {
						width: $left-column-width;
					}
				}

				.amount-button {
					margin-left: 8px;
					width: $right-column-width;
					// height: 42px;

					&.retroactive {
						width: $retroactive-right-column-width;
					}
				}
			}

			.amount-error {
				position: absolute;
				bottom: -24px;
				font-size: 13px;
				color: red;
			}
		}
	}

	hr {
		border-top: 1px solid #d7dada;
		margin-top: 32px;
		margin-right: 0;
		margin-bottom: 32px;
		margin-left: 0;
	}

	.payment-summary {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.points-earned {
			font-weight: 700;
			// line-height: 18px;
			line-height: 21px;
			letter-spacing: 0px;
			text-align: left;

			.earned-prefix {
			}

			.earned-amount {
				display: flex;
				flex-direction: row;
				align-items: center;

				.earned-points {
					font-weight: 700;
				}

				.earned-suffix {
					margin-left: 4px;
				}

				.earned-value {
					margin-left: 4px;
				}

				.earned-disclaimer {
					margin-left: 4px;
				}
			}
		}

		.summary-prices {
			display: flex;
			flex-direction: column;

			font-family: Lato;
			font-size: 15px;
			font-weight: 500;
			line-height: 18px;
			letter-spacing: 0px;
			text-align: left;

			width: $column-width;

			&.retroactive {
				width: $retroactive-column-width;
			}

			.summary-price {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 20px;

				.price-prefix {
					align-self: flex-end;
				}

				.price-value {
					text-align: right;
					align-self: flex-end;
				}
			}

			.summary-discount {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 5px;

				.discount-prefix {
					align-self: flex-end;
				}

				.discount-value {
					text-align: right;
					align-self: flex-end;
				}
			}

			.summary-total {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 20px;

				.total-prefix {
					align-self: flex-end;
				}

				.total-value {
					font-size: 28px;
					font-weight: 600;
					line-height: 34px;
					text-align: right;
					align-self: flex-end;
				}
			}
		}
	}
}
