$light-blue: #d1f0ff;
$gray-1: var(--general-colors-gray-dark);
$black: var(--general-colors-black);

.loyalty-box {
	width: 543px;
	height: 200px;
	border-radius: 8px;
	background: linear-gradient(99.53deg, rgba(242, 249, 253, 0.6) 4.71%, rgba(205, 227, 241, 0.6) 96.18%);
	padding: 24px;

	font-family: Lato;

	&.reservation {
		width: 100%;

		.loyalty-info {
			width: 543px;
		}
	}

	.loyalty-info {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.points-data {
			height: 73px;
			display: flex;
			align-items: center;

			.points-icon-container {
				width: 73px;
				height: 73px;
				background: $light-blue;
				border-radius: 73px;
				margin-right: 24px;

				display: flex;
				align-items: center;
				justify-content: center;

				.points-icon {
					width: 46.72px;
					height: 46.72px;
					background-image: url('../img/search_page/loyalty/present-plain.svg');
					background-size: cover;
				}
			}

			.points-amount {
				display: flex;
				flex-direction: column;
				justify-content: space-between;

				width: 190px;
				height: 73px;

				font-weight: 700;
				font-size: 15px;
				line-height: 18px;

				&.points-balance {
					color: $black;

					.amount-points {
						font-weight: 600;
					}

					.amount-points-value {
						font-weight: 300;
					}
				}

				&.points-upcoming {
					color: $gray-1;

					.amount-points,
					.amount-points-value {
						font-weight: 300;
					}
				}

				.amount-title {
					font-size: 15px;
					line-height: 18px;
					position: relative;

					.points-upcoming-tooltip {
						position: absolute;
						top: -8px;
					}
				}

				.amount {
					.amount-points {
						display: inline-block;
						font-size: 40px;
						line-height: 48px;
					}

					.amount-label {
						display: inline-block;
						margin-left: 4px;
					}

					.amount-points-value {
						display: block;
					}
				}
			}
		}

		.points-description {
			font-size: 13px;
			font-weight: 400;
			line-height: 19px;
			letter-spacing: 0;
			text-align: left;
			color: $gray-1;
			white-space: pre-line;
		}
	}

	.points-usage {
		display: flex;
		flex-direction: column;
	}
}
