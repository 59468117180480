@import 'variables';
@import 'travelBooster';
@import 'retroactivePointsUsage';
@import 'settlePayment';
@import 'bookingsFilterComponent';
@import 'newBookingsFiltersComponent';
@import 'bookingsAdvancedFiltersDrawer';
@import 'bookingsAdvancedFiltersPopover';
@import 'newBookingsPage';

$filters-height: 84px;
$filters-padding: 20px;

$entry-height: 92px;
$property-padding: 5px;

$booking-dates-width: 146px;

$booking-status-width: 114px;
$payment-font-size: 24px;

.cell__cancellation.deadline_exited {
	color: $text-red !important;
}

.booking-status {
	&.rejected,
	&.cancelled,
	&.error,
	&.canceled {
		color: $text-red !important;
	}

	&.requested,
	&.pending,
	&.pending-support {
		color: $text-orange !important;
	}

	&.confirmed,
	&.approved {
		color: $text-green !important;
	}

	&.unpaid {
		color: $text-blue !important;
	}

	&.expired {
		color: $gray !important;
	}

	&.gap {
		margin-left: 10px;
	}

	&.indent {
		margin-left: 12px;
	}
}

.tooltip:hover {
	display: block !important;
}

.tooltip.in {
	opacity: 1;
}

.tooltip > .tooltip-inner {
	padding: 0;
	max-height: 200px;
	max-width: 400px;
	position: relative;
	background: #ffffff;
	border: 1px solid #ccc;
}

.tooltip.upwards > .tooltip-inner:after,
.tooltip > .tooltip-inner:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.tooltip.upwards > .tooltip-inner:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #ffffff;
	border-width: 5px;
	margin-left: -5px;
}

.tooltip.upwards > .tooltip-inner:before {
	border-color: rgba(204, 204, 204, 0);
	border-top-color: #ccc;
	border-width: 6px;
	margin-left: -6px;
}

.breakfast-tooltip-container {
	padding: 4px;

	span {
		color: #4a4a4a;
	}
}

.info-tooltip-container {
	max-height: 195px;
	overflow: auto;
	display: block;
	width: 100%;
	color: #4a4a4a;
	text-align: left;

	.data {
		padding: 4px 9px;
	}

	.inline {
		display: inline-block;
	}

	.title {
		margin-right: 4px;

		span {
			font-weight: bold;
		}
	}

	.reservation-id {
		display: block;
		// .id {

		// }
	}

	.cancellation-policy {
		display: block;

		.title {
			display: block;
		}

		.policy {
			display: block;
			white-space: pre-line;
		}
	}

	.supplier-terms {
		display: block;

		.title {
			display: block;
		}

		.terms {
			display: block;
			text-align: justify;

			span {
				word-wrap: break-word;
			}
		}
	}
}

.travel-policy-tooltip-container {
	text-align: left;
	padding: 5px;

	span {
		display: block;
		color: #4a4a4a;
	}
}

.more-travelers.tooltip {
	.tooltip-arrow {
		display: none;
	}

	.tooltip-inner {
		border: none;
	}

	.booking-travelers {
		position: relative;
		background: #fff;
		border: 1px solid #ccc;
		border-radius: 4px;
		border-top: 0;

		&:after,
		&:before {
			bottom: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}

		&:after {
			border-color: rgba(255, 255, 255, 0);
			border-bottom-color: #fff;
			border-width: 5px;
			margin-left: -5px;
		}

		&:before {
			border-color: rgba(204, 204, 204, 0);
			border-bottom-color: #ccc;
			border-width: 6px;
			margin-left: -6px;
		}
	}
}

.cancel-reservation-modal-content,
.approve-reservation-modal-content,
.reject-reservation-modal-content {
	margin-bottom: 24px;
	text-align: left;

	.cancellation-policy {
		white-space: pre-wrap;
	}

	.reason-container {
		label {
			margin-right: 4px;
			display: block;
		}

		span {
			display: block;
		}

		.text-box-container {
			display: block;
			width: 100%;
			height: 140px;
			border: 1px solid #d0d1d2;
			border-radius: 4px;
			margin-top: 5px;

			.text-box {
				width: 100%;
				resize: none;
				overflow: auto;
				padding: 10px;
				height: 100%;
				border: none;
				border-radius: 4px;
			}
		}
	}
}

.canceling-modal-status,
.approving-modal-status,
.rejecting-modal-status {
	i {
		margin-right: 4px;
	}
}

.cancel-modal-actions,
.approve-modal-actions,
.reject-modal-actions {
	button:first-of-type {
		margin-right: 24px;
	}
}
