///
/// Used for both DestinationsBox & DestinationComponent
///
@import 'variables';

.destinations-box {
	min-height: 227px;

	&.expanded {
		min-height: 532px;
	}

	.title {
		position: relative;
		display: flex;
		justify-content: space-between;

		.title-main {
			@include Font-Regular($secondary-font, $base-font-size-21, $black-text);

			font-weight: bold;
			margin-bottom: 28px;
		}

		.title-action {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 6px;
			margin-top: -4px;
			width: 260px;
			height: 40px;
			border-radius: 8px;
			@include Font-Regular($secondary-font, $base-font-size-14, $secondary-blue, 700);

			&:hover {
				color: $main-blue;
				cursor: pointer;
			}
		}
	}

	.no-destinations {
		text-align: center;
		width: 100%;
		border: 1px solid #e1e1e1;
		border-radius: 16px;
		height: 258px;

		span {
			font-family: 'Lato' !important;
			font-style: normal;
			font-weight: 400;
			color: #20242b24;
			font-size: 28px;
			margin: 110px auto;
			display: table;
			height: 100%;
		}
	}

	.input-wrapper:not(:first-child) {
		margin-top: 20px;
	}

	.input-wrapper {
		max-height: 70px;

		label {
			margin-left: 5px;
			color: $gray-1;
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
		}
	}

	.destinations-components-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.destination-component-container {
			position: relative;
			width: 261px;
			// width: 47%;
			height: 200px;
			margin-bottom: 14px;
			border-radius: 16px;

			.destination-component {
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				border-radius: 16px;

				.destination-content-container {
					height: 100%;
					padding: 16px 20px 16px 16px;
					cursor: pointer;
					text-align: left;

					.destination-content {
						height: 100%;
						width: 100%;

						.destination-title {
							@include Font-Regular(
								$destination-component-title-font-family,
								$destination-component-title-font-size,
								$destination-component-title-font-color,
								600
							);
							line-height: 25px;
						}
						text-shadow: 0 0 13px rgba(0, 0, 0, 0.4);

						.add {
							@include Font-Regular(
								$destination-component-secondary-font-family,
								$destination-component-secondary-font-size,
								$gray-5
							);
						}

						.address {
							@include Font-Regular(
								$destination-component-secondary-font-family,
								$destination-component-secondary-font-size,
								$destination-component-secondary-font-color
							);
							margin-top: 8px;
						}
					}
				}

				.destination-removal-container {
					position: absolute;
					width: 22px;
					height: 22px;
					top: 0;
					right: 4px;
					border: 0;
					background: none;

					button.remove-button:focus,
					button.remove-button:active,
					button.cancel-button:focus,
					button.cancel-button:active,
					button.addition-button:focus,
					button.addition-button:active {
						outline: none;
					}

					button.cancel-button.hovering {
						background-color: #d0021b !important;
						cursor: pointer;
					}

					button.remove-button,
					button.cancel-button {
						position: absolute;
						right: 5px;
						border: 0;

						background-size: 24px 24px;
						width: 24px;
						height: 24px;
						display: block;
						background-color: $black-text;

						mask-repeat: no-repeat;
						mask-size: 24px 24px;
					}

					button.remove-button,
					button.cancel-button {
						top: 9px;
						mask-image: url('../img/recent_searches/x-close.svg');
					}

					button.addition-button.valid {
						color: #009ad2 !important;

						&:hover,
						&:active,
						&:focus {
							text-decoration: underline;
						}
					}

					button.addition-button {
						position: absolute;
						right: 5px;
						font-size: 12px;
						color: #dcd9d9 !important;

						border: 0;
						top: 46px;
						background: none;
					}
				}
			}

			.removal-popover {
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				background: #fff;
				border: 1px solid #ccc;
				padding: 10px 20px;
				text-align: center;
				border-radius: 16px;

				.removal-approval-text {
					margin-bottom: 8px;
				}

				button {
					background: none;
					border: none;
					outline: none;

					&:hover,
					&:focus-visible {
						text-decoration: underline;
					}
				}

				button.approve-removal-button {
					margin-right: 10px;
					color: #417505;
				}

				button.cancel-removal-button {
					color: #d0021b;
				}
			}
		}
	}

	.search-root {
		.MuiInputBase-input {
			background-color: $gray-5;
			border: none;
		}

		.value-container {
			padding: 0 20px;

			.loader {
				top: 20px;
			}
		}

		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border: 1px solid $gray-3;
		}

		&.clean {
			.MuiPaper-root {
				top: 50px;
			}
		}
	}
}
