@import 'variables';

$height-search-bar-container: 256px;
$height-search-bar: 160px;
$label-color: #3ea8dc;
$input-border-color: #6680a5;
//$search-button-secondary-color: #CD9A3A;
//$search-button-main-color: #EBAE35;

.search-component-container {
	height: 100%;
	width: 100%;
	border: 1px solid $border-gray;
	border-radius: 4px 8px 4px 4px;
	background: $white;

	.search-component {
		border-radius: 4px;
		border-top: 4px solid $label-color;
		height: 100%;
		//padding-top: 6%;
		//padding-top: $search-component-top-margin;
		margin: 0 auto;
		width: 100%;
		max-width: 1092px;

		form {
			.search-component-table {
				//margin: 0 auto;
				//margin-top: $search-component-top-margin;
				width: 100%;
				//height: $search-bar-background-height - $search-bar-top-margin - $search-bar-bottom-margin;
				//border-top: 4px solid $label-color;

				//background-color: rgba(255, 255, 255, 0.8);

				//.table-inner-container {
				//
				//  margin: 0 auto; // TODO: toggle
				//  width: 85%;
				//  height: 100%;

				.search-component-row {
					width: 100%;
					margin: 2%;
					//position: relative;

					.input-container {
						display: inline-block;
						position: relative;
						//border-bottom: 1px solid $input-border-color;
						//padding-bottom: 10px;
						//margin-left: 2%;
						margin-left: 1%;
						margin-right: 1%;

						label {
							text-transform: uppercase;
							@include Font-Regular(
								$search-bar-label-font-family,
								$search-bar-label-font-size,
								$search-bar-label-font-color
							);
							display: block;
							height: 40%;
						}

						.component-input {
							//, input:placeholder-shown {
							//margin-left: 10px;
							//margin-bottom: 10px;
							display: block;
							background: transparent;
							border: 0;
							height: 20px;
							width: 100%;
							@include Font-Regular(
								$search-bar-input-font-family,
								$search-bar-input-font-size,
								$search-bar-input-font-color
							);

							option {
								span {
									margin: 0 6px 0 6px;
								}
							}
						}

						input {
							//margin-bottom: 10px;
							//border: 0;
						}

						input:focus {
							outline: none;
						}

						.check-date-container {
							background: url('../img/search_page/search_bar/calender.png') no-repeat right;

							.component-input {
								//margin-left: 10px;
								//margin-bottom: 10px;
								display: block;
								background: transparent;
								//border: 0;
								height: 20px;
								width: 100%;
								@include Font-Regular(
									$search-bar-input-font-family,
									$search-bar-input-font-size,
									$search-bar-input-font-color
								);

								//appearance: none;
								//-moz-appearance: none;
								//-webkit-appearance: none;
							}

							.component-input::-webkit-inner-spin-button,
							.component-input::-webkit-calendar-picker-indicator {
								cursor: pointer;
								appearance: none;
								-moz-appearance: none;
								-webkit-appearance: none;
								//display: none;
								color: transparent;
								background: transparent;
								width: 20px;
							}

							.component-input::-webkit-clear-button {
								appearance: none;
								-moz-appearance: none;
								-webkit-appearance: none;
								//display: none;
								color: transparent;
								background: transparent;
							}
						}

						input:focus {
							outline: none;
						}

						.select-container {
							background: url('../img/search_page/search_bar/arrow_drop.png') no-repeat right;

							select {
								background: none;
								//border: 0 !important;
								appearance: none;
								-moz-appearance: none;
								-webkit-appearance: none;
							}

							select:focus,
							input:focus {
								outline: none;
								//-moz-box-shadow: 0 0 5px #fff;
								//-webkit-box-shadow: 0 0 5px #fff;
								//box-shadow: 0px 0px 5px #fff;
							}
						}

						.double-select-container {
							select {
								width: 33%;
								display: inline-block;
							}

							.per {
								display: inline-block;
								margin-left: 5%;
								margin-right: 5%;
								text-transform: uppercase !important;
							}
						}
					}
				}

				.first-row {
					//.input-container {
					//  margin: 2%;
					//}

					.destination-input {
						width: 50%;
						input {
							width: 100%;
						}
					}

					.check-date {
						width: 20%;
					}
				}

				.second-row {
					position: relative;

					//.input-container {
					//  margin: 2%;
					//}

					.search-component-guests-per-rooms {
						width: 30%;
					}

					.traveler-name {
						width: 25%;
					}

					.rooms {
						width: 20%;
					}

					.travelers-per-room {
						width: 28%;
					}

					.empty {
						width: 20%;
					}

					.nights {
						//display: inline-block;
						//margin-left: 25%;
						width: 20%;

						input {
						}
					}

					.search-for-company {
						width: 28%;
					}

					.edit-set {
						display: inline-block;
						position: relative;
						bottom: 0;
						//text-transform: uppercase;
						content: '';
						//margin: 2%;
						margin-left: 25%;

						input[type='button'],
						input[type='button']:focus,
						input[type='button']:active,
						button,
						button:focus,
						button:active {
							/* Remove all decorations to look like normal text */
							//background: none;
							//border: none;
							//display: inline;
							//font: inherit;
							//margin: 0;
							//padding: 0;
							//outline: none;
							//outline-offset: 0;
							///* Additional styles to look like a link */
							//color: rgb(53, 168, 223);
							//cursor: pointer;
							//text-decoration: underline;
							//
							//text-transform: uppercase;
						}
						/* Remove extra space inside buttons in Firefox */
						//input[type="button"]::-moz-focus-inner,
						//button::-moz-focus-inner {
						//  border: none;
						//  padding: 0;
						//}
					}

					.search-now {
						position: absolute; //relative;//absolute;
						bottom: 0;
						cursor: pointer;
						width: 30%;
						margin-left: 2%;
						display: inline-block;

						.search-button {
							width: 100%;
							height: 100%;
							display: table;

							div {
								display: table-cell;
								margin: 0 auto;
								vertical-align: middle;
								height: 100%;
							}

							.search-button-text {
								background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
								border-radius: 4px 0 0 4px;
								/* background-color: #00D994; */
								text-transform: uppercase;
								color: #ffffff;
								text-align: center;
								&:hover {
									background-color: #419641;
									background-position: 0 -15px;
								}
							}

							.search-button-icon {
								//width: 15%;
								width: 36px;
								//height: 36px;
								//background-color: $search-bar-button-secondary-color;
								//color: $search-bar-button-main-color;
								//background-image: linear-gradient(to bottom,#54A954 0,#3B883B 100%);
								background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
								width: 36px;
								//background-color: #419641;
								//background-position: 0 -15px;
								//background-color: #00AE80;
								color: #e6ffdf;
								border-radius: 0 4px 4px 0;
								i {
									padding: 6px;
									vertical-align: middle;
									margin: 0 auto;
								}
							}
						}
					}
				}
				//}
			}
		}
	}

	&.disabled {
		.search-component {
			border-top: 4px solid #c5c7c7;
			form {
				label {
					display: none !important;
				}
				input {
					//color: #939393 !important;
				}
			}
		}
	}
}
