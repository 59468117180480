@import 'variables';
@import 'searchBar';
@import 'loyaltyBox';
@import 'recentSearchesBox';
@import 'destinationsBox';
@import 'emptyStateSearchesDestination';
@import 'upgradePlanBox';
@import 'tipsBox';
@import 'companiesAutoComplete';

.search-page {
	min-width: 1055px;
	width: 100%;
	height: 100%;

	.higher {
		width: 100%;
		height: 380px;
		background-image: url('../img/search_page/search_bar/business-bg.jpeg');
		background-color: $main-blue;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		transition: background-image 0.6s ease-in-out;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;

		.gradient {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0.7;
			background: linear-gradient(61deg, #093577 13.58%, rgba(9, 53, 119, 0) 38.17%);
			mix-blend-mode: multiply;
		}

		&.private {
			background-image: url('../img/search_page/search_bar/leisure-bg.jpg');
		}

		&.letstay {
			background-image: url('../img/search_page/search_bar/letstay-bg.png');
			background-color: transparent;

			.gradient {
				background: none;
			}

			.search-title,
			.search-sub-title {
				color: var(--general-colors-gray-dark);
				text-shadow: none;
			}
		}

		&.htz {
			background-image: url('../img/search_page/search_bar/htz-bg.jpeg');
			background-color: transparent;

			.gradient {
				background: none;
			}

			.search-title,
			.search-sub-title {
				color: var(--general-colors-black);
				text-shadow: none;
			}
		}
	}

	.lower {
		width: 100%;
		min-height: 50%;
		overflow: auto;
		padding-top: 30px;
		padding-bottom: 24px;

		.lower-content {
			margin: 0 auto;
			max-width: 1124px;
			min-width: 800px;
			height: 100%;

			.loyalty {
				padding-top: 59px;
			}

			.leisure_destinations {
				.header {
					@include Font-Regular($secondary-font, $base-font-size-18, $black-text);

					font-weight: bold;
					margin-bottom: 23px;
				}
			}

			.leisure_destinations_small,
			.leisure_destinations_large {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				width: 100%;
				gap: 23px;

				.item-wrap,
				.item {
					border: 2px solid transparent;
					border-radius: 18px;
					flex: 1;
					position: relative;
					padding: 34px 30px;
					height: 292px;
					color: #fff;
					text-shadow: 0 0 13px rgba(0, 0, 0, 0.4);
					cursor: pointer;

					&:hover .image:after {
						border: 1px solid $gray-border;
						opacity: 1;
					}

					.image {
						border-radius: 16px;
						background-size: cover;
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						background-color: #333;
						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							opacity: 0;
							border-radius: 16px;
							background: rgba(0, 0, 0, 0.4);
							transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
						}
					}

					.title {
						position: absolute;
						top: 34px;
						left: 30px;
						font-size: 28px;
						line-height: 34px;
					}

					.sub_title {
						position: absolute;
						top: 68px;
						left: 30px;
						font-size: 14px;
						font-weight: 500;
						line-height: 22px;
					}

					&:focus-visible {
						outline: none;
						border: 2px solid $color-outline-tab;
					}
				}

				.item-wrap {
					margin-bottom: 20px;
					width: 355px;
					min-width: 346px;
				}

				.item {
					flex: 1;
					margin: 0 40px;
				}
			}

			.leisure_destinations_small {
				margin-bottom: 30px;
				.item {
					width: 346px;

					&:first-child,
					&:last-child {
						margin: 0;
					}
				}
			}

			.leisure_destinations_large {
				.item {
					width: 533px;

					&:first-child {
						margin: 0 16px 0 0;
					}

					&:last-child {
						margin: 0 0 0 16px;
					}
				}
			}

			.actions-container {
				display: flex;
				justify-content: space-between;
				width: 100%;
				height: 50%;

				> div {
					width: 48%;
				}
			}

			.tips-container {
				margin-top: 24px;
				display: block;
				width: 100%;
				height: 100%;
				float: left;
				padding: 20px;
			}
		}
	}
}
