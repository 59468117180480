@charset "UTF-8";

@import 'variables';
@import 'helpers';
@import 'hotelSearch';
@import 'helpComponent';
@import 'amenitiesComponent';
@import 'hotelImagesCollage';
@import 'installmentsBanner';
@import './mixins/hotelMixins';
@import './mixins/sharedMixin';

.hotel-page {
	width: 1092px; //$width-main;
	height: 100%;
	margin: 10px auto 0;

	.back-to-results {
		@include back-to-button;
	}

	.hotel-container {
		display: flex;
		gap: 23px;
		margin: 0 auto;

		&.top-container {
			margin-top: 10px;
		}

		.hotel-main {
			width: 816px;
			display: inline-block;
			vertical-align: top;

			@import 'starsComponent';

			.stars {
				margin-top: 16px;
			}

			.hotel-name {
				@include Font-Regular(
					$hotel-page-hotel-name-font-family,
					$hotel-page-hotel-name-font-size,
					$hotel-page-hotel-name-font-color,
					$hotel-page-hotel-name-font-weight
				);
				line-height: 29px;
				text-transform: capitalize;
			}

			.hotel-address {
				margin-top: 8px;
				@include Font-Regular($hotel-address-font-family, $hotel-address-font-size, $hotel-address-font-color);
				font-size: 14px;

				img {
					margin-right: 2px;
					margin-bottom: 2px;
				}

				text-transform: capitalize;

				span.distance-description {
					margin-left: 4px;
					text-transform: lowercase;
				}
			}

			.info-status-container {
				text-align: center;
				//padding: 10px;
				//border: 1px solid #eee;
				//border-radius: 4px;
				//margin-bottom: 20px;
				margin-bottom: 10px;
				margin-top: -10px;

				i.fa.fa-spinner {
					font-size: 20px;
					width: 20px;
					height: 20px;
					color: #4a4a4a;
				}
			}

			.mapping-button {
				margin-top: 10px;
			}

			.mapping-statistics {
				border: 1px solid lightgrey;
				border-radius: 4px;
				padding: 4px 4px 4px 15px;
				margin-top: 6px;
			}

			.mapping-actions {
				margin: 6px 0;

				.mapping-actions-button {
					margin: 0 4px;
				}

				.mapping-actions-button:first-child {
					margin-left: 0;
				}
			}

			////////////////////////////////////////////////////////////////////////////////
			// COVID-19

			.essential-workers-disclaimer {
				border: 1px solid #417505;
				border-radius: 4px;
				height: 52px;
				@include Font-Regular($secondary-font, 14px, #417505);

				padding-left: 24px;
				vertical-align: middle;
				line-height: 26px;
				margin-top: -12px; //10px;
				margin-bottom: 2px; //14px
			}

			.filters-and-deals {
				background-color: #fafafa;
				border: 1px solid #d7dada;
				border-radius: 8px;
				padding: 24px 22px;
				width: 816px;

				.title {
					line-height: 28.8px;
					margin-bottom: 16px;
					@include Font-Regular($secondary-font, $base-font-size-24, $black-text, 700);
				}
			}

			.deals-container {
				margin-top: 24px;

				.deals-title {
					margin-left: 8px;
					line-height: 25px;
					@include Font-Regular($secondary-font, $base-font-size-18, $gray-1, 700);
				}

				input[type='button'] {
					text-transform: uppercase;
					background-image: linear-gradient(to bottom, #3da9dd 0, #144d92 100%);
					border-color: #144d92;
					&:hover {
						background-color: #144d92;
					}
					width: 128px; //100%;
				}

				button[name='show-more-deals'] {
					//margin-top: 15px;
					//width: 100%;
					//font-weight: 300;
					//background: white;
					//border: 1px solid lightgrey;
					//color: #999;
					margin-top: 15px;
					width: 100%;
					font-weight: 300;
					background: #3498db;
					border: 1px solid #337ab7;
					color: white;
					background-image: linear-gradient(to bottom, #3498db 0, #337ab7 100%) !important;
					&:hover {
						background-image: linear-gradient(to bottom, #337ab7 0, #337ab7 100%) !important;
					}
				}
			}

			.info-deals-cached-status-container {
				text-align: center;
				margin-bottom: 24px;

				i.fa.fa-spinner {
					font-size: 20px;
					width: 20px;
					height: 20px;
					color: #4a4a4a;
				}
			}

			.deals-filter {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				gap: 16px;
				margin-bottom: 24px;

				.checkbox-container {
					display: flex;
					height: 40px;
					padding: 0 10px;
					border-radius: 8px;
					cursor: pointer;
					background-color: #ffffff;
					border: 1px solid #bcc0c0;

					&.checked {
						background-color: var(--desktop-deals-filter-checked-background);
						border: 1px solid var(--desktop-deals-filter-checked-border);
					}

					.MuiCheckbox-root {
						margin: 8px;
						padding: 1px;
						border-radius: 2px;
					}

					.MuiFormControlLabel-root {
						margin: 0 8px 0 -8px;

						.MuiFormControlLabel-label {
							@include Font-Regular($secondary-font, $base-font-size-15, $gray-1);
						}
					}

					& > div > div {
						display: flex;
						align-items: center;

						div,
						label,
						svg {
							margin: 0 !important;
							margin-right: 4px !important;
						}
					}

					&.disabled {
						&,
						input,
						label {
							cursor: not-allowed !important;
						}
						label {
							font-weight: normal;
							color: #cccccc;
						}
					}

					label {
						@include Font-Regular($check-list-filter-item-font-family, 15px, $gray-1, 400);
						white-space: nowrap;
					}
				}
			}

			.no-valid-deals-wrapper {
				display: flex;
				flex-direction: column;
				align-items: center;
				height: 400px;
				margin: 24px 0;
				border-radius: 8px;
				line-height: 25px;
				@include Font-Regular($secondary-font, $base-font-size-18, $black-text);

				.empty-state-image {
					margin-top: 67px;
					margin-bottom: 53px;
					height: 156px;
					width: 201px;
				}
			}

			.no-deals-after-filter {
				border: 1px solid lightgrey;
				border-radius: 4px;
				padding: 10px;
				text-align: center;
				height: 84px; //64px;
				font-weight: 500;
				font-size: 21px; //16px;
				margin-top: 20px;
				margin-bottom: 34px;
			}

			.tabs-wrapper {
				margin-top: 24px;

				.Mui-selected {
					color: var(--desktop-tabs);
				}

				.MuiTabs-indicator {
					background-color: var(--desktop-tabs);
				}
			}

			.hotel-map-box-container {
				width: 100%;
				height: 360px !important;
				margin-top: 24px;
				border-radius: 8px;

				.map-hover-layer {
					cursor: pointer;
					position: relative;
				}
				.map-hover-layer:hover .map-overlay {
					opacity: 0.7;
				}
				.map-hover-layer:hover .map-overlay-text {
					opacity: 1;
				}

				.filter-container {
					height: 100%;

					.map-overlay {
						opacity: 0;
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						text-align: center;
						background-color: #222222;
						transition: 0.5s ease;
					}
					.map-overlay-text {
						opacity: 0;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						-ms-transform: translate(-50%, -50%);
						text-align: center;
						color: white;
						font-size: 24px;
						transition: 0.5s ease;
					}

					img {
						opacity: 1;
						display: block;
						min-width: 100%;
						max-width: 100%;
						min-height: 100%;
						max-height: 100%;
						transition: 0.5s ease;
					}

					.filter,
					.map-filter,
					#map-box {
						height: 100%;
					}
				}
			}

			.about-container {
				background: $white;
				margin-bottom: $hotel-page-about-bottom-margin;
				margin-top: 24px;
				.about {
					margin-top: 24px;
					border-radius: 4px;
					.title {
						line-height: 25px;
						margin-bottom: 20px;
						text-transform: capitalize;

						@include Font-Regular(
							$hotel-page-about-title-font-family,
							$hotel-page-about-title-font-size,
							$hotel-page-about-title-font-color,
							700
						);
					}
					.description {
						white-space: pre-line;
						line-height: $hotel-page-about-text-line-height;
						@include Font-Regular(
							$hotel-page-about-text-font-family,
							$hotel-page-about-text-font-size,
							$hotel-page-about-text-font-color
						);

						p {
							margin-top: 8px;
							margin-bottom: 8px;
						}
					}
				}
			}

			.amenities-container {
				margin-top: 24px;
			}

			.supplier-terms-container,
			.extra-information-container {
				margin-bottom: 0;

				.supplier-terms-wrapper,
				.hotel-policies-wrapper,
				.payment-details-wrapper,
				.key-collection-info-wrapper {
					margin-top: 24px;

					&.payment-details-wrapper,
					&.key-collection-info-wrapper {
						margin-top: 32px !important;
					}

					// TODO: extract and use as "About"'s title
					.title {
						@include Font-Regular(
							$hotel-page-about-title-font-family,
							$hotel-page-about-title-font-size,
							$hotel-page-about-title-font-color,
							700
						);
						margin-bottom: 20px;
					}
					p {
						margin-top: 8px;
						margin-bottom: 8px;

						@include Font-Regular(
							$hotel-page-about-text-font-family,
							$hotel-page-about-text-font-size,
							$hotel-page-about-text-font-color
						);
					}
				}
			}

			.reviews-container {
				margin-top: 24px;
				margin-bottom: 24px;
				position: relative;

				.loading-container {
					$duration: 0.8s;
					position: absolute;
					left: 0;
					right: 0;
					width: 100%;
					-webkit-transition: max-height $duration;
					-moz-transition: max-height $duration;
					-ms-transition: max-height $duration;
					-o-transition: max-height $duration;
					transition: max-height $duration;
					overflow: hidden;
				}

				iframe {
					border-radius: 4px;
					background: #fff;
					position: relative;
				}
			}
			section {
				padding-bottom: 0 !important;
				border-bottom: 0 !important;
			}

			.installments-banner {
				padding: 8px 16px;
				margin: 12px 0;
				height: 48px;

				&__text {
					margin-inline-start: 15px;
				}
			}
		}

		.hotel-side {
			width: 266px;

			// TripInfo Component start
			.trip-details-container {
				@include side-info-block();

				.destination {
					font-size: 16px;
					font-weight: 500;
				}

				.dates-container {
					.check-in,
					.check-out {
						width: 117px;
						margin-top: 8px;
						padding: 12px;
						border: 1px solid $gray-4;

						span:nth-child(1) {
							font-size: 15px;
							line-height: 21px;
							color: $gray-2;
						}

						span:nth-child(2) {
							font-size: 15px;
							line-height: 23px;
							color: $black-text;
						}
					}

					.check-in {
						border-top-left-radius: 5px;
					}

					.check-out {
						border-left: none;
						border-top-right-radius: 5px;
					}
				}

				.guests-container {
					padding: 13px;
					border: 1px solid $gray-4;
					border-top: none;
					border-radius: 0 0 5px 5px;
					font-size: 15px;
					color: $black-text;

					span:nth-child(1) {
						line-height: 21px;
						color: $gray-2;
					}

					span:nth-child(2) {
						line-height: 23px;
						color: $black-text;
					}
				}
			}
			// TripInfo Component end

			.review-reasons-container {
				@include side-info-block();
				margin-top: 24px;

				// HotelReview Component start
				.review {
					gap: 13px;
					font-size: 15px;
					color: $gray-1;
					line-height: 21px;
					transition: all 0.2s ease;

					&:not(.no-review):hover {
						cursor: pointer;

						.description {
							color: #2d65a1;
						}
					}

					.score-box {
						padding: 5px 10px;
						background: var(--desktop-score-background);
						border-radius: 8px;

						.score {
							font-size: 33px;
							font-weight: 600;
							line-height: 40px;
							color: var(--desktop-score-color);
						}
					}

					.description {
						font-weight: 700;
						line-height: 18px;
					}

					&.no-review {
						text-align: center;

						span.no-review-line {
							text-transform: uppercase;
							display: block;
						}
					}
				}
				// HotelReview Component end

				// HotelReasons Component start
				.reasons {
					ul {
						padding-left: 25px;
						margin-bottom: 5px;
						list-style: none;

						li {
							color: $gray-1;
							line-height: 21px;
							font-size: 15px;
							margin-top: 16px;
						}

						li.negotiated_rate {
							color: green;
						}

						li.flag {
							&::before {
								content: '';
								width: 20px;
								height: 20px;
								margin: 0px 5px -5px -25px;
								background-image: url('../img/check.svg');
								background-repeat: no-repeat;
								background-size: contain;
								display: inline-block;
							}

							span {
								font-family: $secondary-font;
								margin-left: -16px;
								margin-right: 40px;
							}
						}
					}
				}
				// HotelReasons Component end
			}

			.catalog {
				margin-top: 28px;
				border: 1px solid $gray-4;
				border-radius: 8px;

				.rooms-catalog-container {
					&.fixed {
						position: fixed;
						top: 90px;
						height: 600px;
						width: 600px;
						overflow: auto;
						background-color: #fff;
					}

					.title {
						padding: 12px 14px;
						border-bottom: 1px solid $gray-4;
						@include Font-Regular($secondary-font, $base-font-size-18, $gray-1, 700);
						line-height: 25px;
					}

					ul {
						@include Font-Regular($secondary-font, $base-font-size-15, var(--desktop-room-catalog));
						margin-bottom: 0;
						list-style: none;
						padding-inline-start: 0; // FIX

						li:not(:last-child) {
							border-bottom: 1px solid $gray-4;
						}

						li {
							position: relative;

							&:not(:has(button)) {
								padding: 14px 12px;
							}

							button {
								padding: 14px 12px;
								width: 100%;
								text-align: left;
							}

							.room {
								display: inline-block;
								width: 85%;
								line-height: 21px;

								&:hover {
									cursor: pointer;
									text-decoration: underline;
								}

								&::after {
									content: '';
									display: block;
									position: absolute;
									right: 12px;
									top: 60%;
									transform: translateY(-50%) rotate(180deg);
									width: 24px;
									height: 24px;
									background-color: var(--desktop-room-catalog);
									mask-image: url('../img/hotel_page/arrow.svg');
									mask-repeat: no-repeat;
								}
							}
						}
					}
				}
			}

			.reasons-container,
			.hotel-details-container,
			.rooms-catalog-container {
				position: relative;

				@include Font-Regular($secondary-font, 16px, #223143);
			}

			.hotel-search-container {
				display: none !important;
				width: 100%;
			}

			.help-container {
				display: none;
				border: 1px solid $lighter-gray;
			}

			// TODO: continue right side bar here
		}
	}

	.rooms-catalog-dialog-wrapper {
		.dialog-content {
			gap: 32px;
			color: $gray-1;

			.description-wrapper {
				p {
					margin-bottom: 5px;
					font-size: 15px;
					line-height: 21px;
				}

				strong {
					margin-bottom: 19px;
					font-size: 16px;
					font-weight: 700;
					line-height: 25px;
				}
			}

			.room-size {
				strong {
					font-size: 16px;
					font-weight: 700;
					line-height: 25px;
				}
			}

			.amenities-container {
				margin-top: 19px;

				.amenities-title {
					margin-bottom: 19px;
					font-size: 16px;
					font-weight: 700;
					line-height: 25px;
					color: $gray-1;
				}
			}
		}
	}
}

.rooms-catalog-dialog-title {
	text-transform: capitalize;
}

.rooms-catalog-dialog-content {
	gap: 32px;
	padding-bottom: 16px;
	color: $gray-1;

	.description-wrapper {
		p {
			margin-bottom: 5px;
			font-size: 15px;
			line-height: 21px;
		}

		strong {
			margin-bottom: 19px;
			font-size: 16px;
			font-weight: 700;
			line-height: 25px;
		}
	}

	.room-size {
		strong {
			font-size: 16px;
			font-weight: 700;
			line-height: 25px;
		}
	}

	.disclaimer {
		font-size: 15px;
		color: #ae8951;

		a {
			color: #ae8951;
			text-decoration: underline;
		}

		&.disclaimer-flex {
			display: flex;
			align-items: center;
			gap: 10px;
			margin-top: 32px;
		}
	}

	.tooltip-icon {
		background: url('../img/hotel_page/disclaimer_icon.svg') left center no-repeat;
		background-size: 16px 16px;
		padding-left: 18px;
	}

	.amenities-container {
		margin-top: 19px;

		.amenities-title {
			margin-bottom: 19px;
			font-size: 16px;
			font-weight: 700;
			line-height: 25px;
			color: $gray-1;
		}
	}
}
