@import 'variables';

.hotel-search {
	display: block;
	text-transform: uppercase;

	.title {
		display: block;
	}

	.check-date-container {
		display: block;

		label {
			display: block;
		}

		.check-date-input {
			display: block;
		}
	}

	.rooms {
		display: block;

		label {
			display: block;
		}

		.rooms-input {
			display: block;
		}
	}

	.guests {
		display: block;

		label {
			display: block;
		}

		.guests-input {
			display: block;
		}
	}

	.search-container {
		input {
			text-transform: uppercase;
		}
	}
}
