.combtas-bar {
	height: 34px;
	background-color: #2384c8;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	color: $white;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.combtas-session {
		font-size: 14px;
		font-weight: 400;
		line-height: 16.8px;

		.tas-id {
			font-weight: 700;
		}

		.end-time {
			margin-inline-start: 18px;
			font-size: 12px;
			font-weight: 700;
			line-height: 14.4px;
		}
	}

	.end-session-button {
		font-size: 12px;
		font-weight: 700;
		line-height: 14.4px;
		position: absolute;
		right: 162px;
		padding: 2px;

		&:hover {
			text-decoration: underline;
		}
	}
}
