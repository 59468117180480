@import './mixins/reservationMixins';

.traveler-details-container {
	margin-bottom: 32px;

	h4.client-name {
		margin: 12px 0 12px 12px;
	}

	h4.travelers-title {
		margin: 15px 0 5px;
	}

	p.notes {
		margin: 15px;

		i {
			margin-right: 4px;
		}
	}

	.send-email-container {
		.checkbox-container {
			span {
				padding: 0;
				margin: 0 9px;
			}

			.MuiFormControlLabel-label {
				font-size: 15px;
				font-weight: 700;
				color: $gray-1;
			}

			min-width: 424px;
			display: inline-block;
			cursor: pointer;

			label {
				margin-bottom: 0;
				@include Font-Regular(
					$check-list-filter-item-font-family,
					$check-list-filter-item-font-size,
					$check-list-filter-item-font-color
				);
				&.checkbox-label-disabled {
					span {
						color: rgba(0, 0, 0, 0.38) !important;
					}
				}
			}
		}
	}

	// RoomContacts (component)
	.room-contacts {
		margin-bottom: 32px;

		&:last-child {
			margin-bottom: 0;
		}

		.private-travel-guest-title {
			margin-left: 13px;
			margin-bottom: 24px;
			@include Font-Regular($secondary-font, 23px, $black-text);
			font-weight: 700;
		}

		.room-name {
			margin-left: 13px;
			margin-bottom: 12px;
			@include container-title-typography;
			text-transform: capitalize;
		}

		.contacts {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding: 32px;
			@include light-gray-bg-rounded;

			// ContactCard (component)
			.contact-card {
				background-color: $gray-5;
				border: 1px solid $gray-4;
				border-radius: 8px;
				position: relative;

				&.locked {
					.contact-details {
						display: initial;
						align-self: initial;
					}

					.content-container {
						padding-bottom: 24px;
					}
				}

				.MuiFormControlLabel-label {
					// font-size: 12px;
					font-size: 32px;
				}

				.content-container {
					width: 100%;
					height: 100%;
					overflow: hidden;
					padding: 16px 24px;

					.card-title {
						margin: 0;
						font-size: 16px;
						font-weight: 600;
						line-height: 25px;
						color: $black-text;
					}

					.user-container {
						display: flex;
						align-items: center;
						// height: 94px;
						gap: 16px;
						margin-top: 7px;
					}
				}

				.avatar-container {
					position: relative;
					margin: 20px 0 auto;
					height: 50px;

					.guest-badge {
						position: absolute;
						top: 41px;
						left: 28px;
						padding: 3px;
						font-size: 11px;
						line-height: 11px;
						color: #fff;
						text-align: center;
						vertical-align: middle;
						background-color: #808080;
						border-radius: 4px;
					}
				}

				.contact-details {
					display: flex;
					flex-wrap: wrap;
					align-self: baseline;
					gap: 22px;

					span.invalid-traveler {
						display: block;
						color: red;
						font-size: $base-font-size-14;
						line-height: 14px;
						position: absolute;
						bottom: -32px;

						&:first-of-type {
							margin-top: 7px;
						}
					}

					.card-name-input,
					.card-input,
					.card-div,
					.travelers-autocomplete {
						display: flex;
						flex-direction: column;
						position: relative;

						@include input-label;

						&.traveler-name-div {
							font-size: $base-font-size-20;
							padding-top: 4px;
							color: #4a4a4a;
							text-transform: capitalize;
						}

						&.traveler-email-div {
							padding-top: 6px;
							color: #a4a4a4;
						}

						&.traveler-phone-input {
							.caret-down {
								position: absolute;
								top: 18px;
								left: 58px;
								width: 0;
								height: 0;
								border-left: 5px solid transparent;
								border-right: 5px solid transparent;
								border-top: 6px solid $black-text;
								pointer-events: none;
								cursor: pointer;
							}
						}

						&.traveler-email-input {
							.field-input {
								.Mui-disabled {
									background: $gray-4;
								}
							}
						}

						.field-input {
							height: 42px;
							width: 200px;
							border: none;

							&.phone {
								width: 240px;

								.MuiTelInput-IconButton {
									padding-right: 28px;
									height: 40px;
									width: 60px;
									border-radius: 0;
								}

								.MuiTouchRipple-root {
									display: none;
								}
							}

							.MuiInputBase-input {
								border: none;
								height: 8px;
								@include Font-Regular($secondary-font, 15px, $black-text);
							}

							.MuiOutlinedInput-notchedOutline {
								border: 1px solid $gray-4;
								border-radius: 4px;
								top: -6px;
							}

							input,
							input::placeholder,
							input::-webkit-input-placeholder,
							input::-moz-placeholder,
							input:-ms-input-placeholder,
							input:-moz-placeholder {
								font-size: 15px;
								font-weight: 700;
								line-height: 18px;
								color: $gray-2;
								text-transform: capitalize;
							}

							input.has-value:invalid {
								border-color: #fb5454;
							}

							.MuiOutlinedInput-root {
								&:focus fieldset {
									border-color: $main-blue;
								}

								&.Mui-focused fieldset {
									border-color: var(--general-input);
								}
							}

							&.error {
								.MuiOutlinedInput-root {
									fieldset {
										border-color: red;
									}

									&:hover fieldset {
										border-color: red;
									}

									&.Mui-focused fieldset {
										border-color: red;
									}
								}
							}
						}
					}

					.card-name-input {
						.field-input {
							.MuiInputBase-input {
								text-transform: capitalize;
							}
						}
					}
				}

				.action-container {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 8px;
					width: 100%;
					margin-top: 24px;
					position: relative;

					.button-container {
						display: flex;
						margin-left: auto;
						gap: 8px;

						.action-button {
							border-radius: 4px;

							&.add-button {
								background: var(--general-primary-button-background);
								color: var(--general-primary-button-color);
							}
						}
					}

					i.busy-indicator {
						font-size: 13px;
					}

					.MuiFormControlLabel-root {
						margin: 0 -8px 0 0;

						.MuiFormControlLabel-label {
							@include Font-Regular($secondary-font, 15px, $gray-1, 700);
						}
					}
				}

				.edit-traveler-button {
					position: absolute;
					top: 7px;
					right: 7px;
				}

				button.remove-traveler {
					border: none;
					background: none;
					display: block;
					outline: none;
					position: absolute;
					top: 7px;
					right: 7px;

					.action-icon {
						background-color: #00abdc;
						background-size: 10px 10px;
						background-repeat: no-repeat;
						display: inline-block;
						width: 10px;
						height: 10px;
						mask-repeat: no-repeat;

						&.remove-icon {
							-webkit-mask-image: url('../img/reservation_page/x_close.svg');
							mask-image: url('../img/reservation_page/x_close.svg');
						}

						&.save-icon {
							-webkit-mask-image: url('../img/reservation_page/save.svg');
							mask-image: url('../img/reservation_page/save.svg');
						}
					}

					&.save-traveler {
						margin-top: 30px;
					}
				}

				.card-alert {
					padding: 0 8px 8px;

					span {
						display: block;
						font-size: $base-font-size-12;
						line-height: 14px;
					}

					&.failed-to-add-traveler,
					&.invalid-email,
					&.email-exists {
						span {
							color: red;
							width: 103%;
						}
					}
				}
			}
		}
	}
}
