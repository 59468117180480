@import 'variables';
@import 'colors';

.filters {
	width: 100%;
	height: 100%;

	.form-control {
		height: 42px;

		&:focus {
			box-shadow: none;
			border-color: var(--general-input);
		}

		&.read-only-background {
			background-color: #fff;
		}
	}

	#map-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;

		#map-placeholder {
			width: 100%;
			height: 100%;
			background-color: gray;
			position: relative;
		}

		#map-box-toggle-button {
			position: absolute;
			display: flex;
			align-items: center;
			gap: 10px;
			padding: 9px 18px;
			border: 1px solid var(--desktop-map-button);
			border-radius: 8px;
			z-index: 90;
			background-color: rgba(246, 246, 246, 0.7);
			color: var(--desktop-map-button);
			font-weight: 700;
			line-height: 17px;
			cursor: pointer;
		}
	}

	.static-map-container {
		border-radius: 4px 5px 4px 4px;
		border: 1px solid #ebebeb;
		width: 100%;
		height: 132px;

		img {
			display: block;
			margin: 0 auto;

			min-width: 100%;
			max-width: 100%;
			min-height: 100%;
			max-height: 100%;

			border-radius: 3px;
		}
	}

	.filter-divider {
		width: 100%;
		height: 2px;
		background-color: var(--general-colors-gray-light);
		margin: 7px 0;
	}

	.filter-container {
		margin-bottom: 12px; //10px;

		&.map-container {
			border: 0;

			background-color: gray;

			width: 100%;
			height: 100%;

			#map-box {
				border: 1px solid #ebebeb;
				border-top: 4px solid #3ea8dc;
				//margin-bottom: 24px;
				width: 100%;
				height: 100%;
			}
		}

		.filter {
			border-radius: 4px;
			width: 100%;
			padding: 11px;

			&:first-child {
				margin-top: 18px;
			}

			&:last-child {
				padding-bottom: 18px;
			}

			.currency-input-wrapper {
				position: relative;

				.currency-icon {
					position: absolute;
					top: 20px;
					right: 10px;
					transform: translateY(-50%);
					@include Font-Regular($secondary-font, $base-font-size-13, $gray-2);
				}
			}

			.hotel-name-description,
			.price-per-night-description {
				display: block;
				margin-bottom: 20px;
				@include Font-Regular(
					$hotel-name-filter-description-font-family,
					$hotel-name-filter-description-font-size,
					$hotel-name-filter-description-font-color
				);
				line-height: $hotel-name-filter-description-line-height;
			}

			.check-list {
				display: block;
				width: 100%;

				.checkbox-container {
					display: block;
					width: 100%;
					height: 32px;
					margin-left: 12px;
					cursor: pointer;

					label {
						@include Font-Regular(
							$check-list-filter-item-font-family,
							$check-list-filter-item-font-size,
							$check-list-filter-item-font-color
						);
						margin-bottom: 0;
						padding: 2px 0;
						width: 100%;
					}

					input[type='checkbox'] {
						width: 80% !important;
					}
				}
			}

			#map-box-filter {
				width: 100%;
				height: 100%; //150px;
			}

			label.filter-title {
				display: block;
				padding-left: 5px;
				margin-bottom: 6px;

				@include Font-Regular(
					$filter-title-font-family,
					$filter-title-font-size,
					$filter-title-font-color,
					$filter-title-font-weight
				);
			}

			.autocomplete-wrap {
				position: absolute;
				top: 15px;
				left: 10px;
				width: 420px;
				z-index: 50;
				height: 80px;

				.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
				.MuiOutlinedInput-root .Mui-focused .MuiOutlinedInput-notchedOutline,
				.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
				.MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline,
				.Mui-focused .MuiOutlinedInput-notchedOutline {
					margin: 10px;
					border: 2px solid $border-gray;
					border-radius: 8px;
				}
			}

			input.hotelNameInput {
				background: url('../img/results_page/search.png') no-repeat scroll 96% 50%;
				background-size: 16px 16px;
				width: 90%;
				margin: 0 auto;
				display: block;
				border: 1px solid $border-gray;
			}

			.price-range {
				width: 100%;

				div {
					text-align: center;
					display: inline-block;
					margin-right: 14%;
				}

				div:last-child {
					margin-right: 0;
				}
			}
		}

		.map-filter {
			padding: 0;
			//height: 228px;
			height: 112px;

			filter: blur(0);
			-webkit-filter: blur(0);
			-webkit-transform: translateZ(0);
			transform: translateZ(0);
			-webkit-backface-visibility: hidden;
			-webkit-transform: translateZ(0) scale(1, 1);

			// Removing Googles sign-in, logo & disclaimer
			//iframe, a > div, .gmnoprint  {
			//  display: none;
			//}
			/*
      #map-box-toggle-button {
        background-color: #fff;
        border: 2px solid #fff;
        border-radius: 3px;
        -webkit-box-shadow: 0 2px 6px rgba(0,0,0,.3);
        -moz-box-shadow: 0 2px 6px rgba(0,0,0,.3);
        box-shadow: 0 2px 6px rgba(0,0,0,.3);
        cursor: pointer;
        //margin-bottom: 22px;
        margin: 10px;
        text-align: center;

        #map-box-toggle-text {
          color: rgb(25,25,25);
          font-family: Roboto, Arial, sans-serif;
          //font-size: 16px;
          font-size: 11px;
          //line-height: 38px;
          font-weight: 500;
          line-height: 27px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }*/

			#map-box-legend {
				background-color: #fff;
				border: 2px solid #fff;
				border-radius: 3px;
				-webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
				-moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
				box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
				//cursor: pointer;
				//margin-bottom: 22px;
				margin: 10px;
				//text-align: center;

				#map-box-legend-inner-container {
					color: rgb(25, 25, 25);
					font-family: Roboto, Arial, sans-serif;
					//font-size: 16px;
					font-size: 11px;
					//line-height: 38px;
					font-weight: 500;
					line-height: 27px;
					padding-left: 5px;
					padding-right: 5px;

					div.map-box-legend-content {
						//direction: rtl;

						div.map-box-legend-line {
							display: block;
							//text-align: right;
							line-height: 27px;
							vertical-align: middle;

							div {
								display: inline-block;
							}

							div.legend-icon {
								width: 16px;
								height: 16px;

								background-position: center;
								background-repeat: no-repeat;
								background-size: auto 16px;
							}

							div.legend-icon.legend-destination {
								background-image: url('../img/results_page/map_location.png');
							}

							div.legend-icon.legend-hotel {
								background-image: url('../img/results_page/pin_map.png');
							}

							div.legend-delimiter {
								padding-left: 4px;
								padding-right: 4px;
							}

							div.legend-text {
								height: 16px;
							}
						}
					}
				}
			}
		}
	}
}

.filters-container {
	.filters {
		.title {
			display: inline-block;
			font-family: 'Raleway' !important;
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			color: #0e4b94;
			margin-top: 4px;
			margin-bottom: 16px;
		}

		.filter-container {
			.filter {
				position: relative;

				.input-container {
					display: inline-block;
					width: 175px;

					.reset-all-filters {
						padding: 0 30px;
						color: #337ab7;

						&:hover {
							text-decoration: underline;
							color: #23527c;
						}
					}

					.slider {
						width: 168px !important;
						vertical-align: middle;
						margin-top: -2px;

						// &.MuiSlider-root {
						//   color: $blue-bright;
						// }

						.MuiSlider-track,
						.MuiSlider-rail {
							color: var(--general-colors-gray-light);
							height: 8px;
							opacity: 1;
						}

						.MuiSlider-thumbColorPrimary {
							background: var(--desktop-slider-thumb);
							width: 20px;
							height: 20px;
						}
					}

					.input-group {
						span#sizing-addon2 {
							width: 34px;
							padding: 0;
						}

						input.empty {
							font-size: 12px;
						}
					}
				}

				.distance-description {
					position: absolute;
					display: inline-block;
					height: 24px;
					width: 40px;
					line-height: 22px;
					padding-left: 12px;

					@include Font-Regular($secondary-font, $base-font-size-13, $gray-2);
				}

				.clear-button {
					display: none;
					position: absolute;
					top: 15px;
					right: 15px;
					width: 12px;
					height: 12px;
					background-color: $gray-secondary;
					mask-image: url('../img/reservation_page/x_close.svg');
					-webkit-mask-image: url('../img/reservation_page/x_close.svg');
					cursor: pointer;
				}

				&:hover .clear-button {
					display: block;
				}

				.clear-button-wrap {
					display: none;
					position: absolute;
					top: 13px;
					right: 13px;
					width: 16px;
					height: 16px;
					padding: 2px;
					cursor: pointer;

					.clear-button-img {
						width: 12px;
						height: 12px;
						background-image: url('../img/reservation_page/x_close.svg');
					}
				}

				&:hover .clear-button-wrap,
				&:focus-within .clear-button-wrap {
					display: block;
				}

				button.clear-link {
					position: absolute;
					margin-right: 13px;
					right: 0;
					top: 55px;
					display: inline-block;
					height: 20px;
					line-height: 20px;
					text-decoration: underline;

					background: none !important;
					border: none;
					padding: 0 !important;

					color: #069;
					cursor: pointer;
				}

				@import 'starsComponent';

				.check-list {
					.checkbox-container {
						position: relative;

						button {
							display: none;
							position: absolute;
							font-size: $base-font-size-13;
							right: 0;
							top: 0;

							&:focus {
								outline: none;
							}
						}

						&:hover {
							button {
								display: block;
							}
						}
					}
				}

				// &:focus-within {
				//   .check-list .checkbox-container:focus-within button{
				//     display: block;
				//   }
				// }
			}
		}
	}
}
