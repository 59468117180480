@import 'colors';

.empty-state-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 35px;
	box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
	border-radius: 16px;
	height: 420px;

	.empty-state-image {
		margin-top: 59px;
	}

	.empty-state-text {
		color: $gray-1;
		font-size: 15px;
		text-align: center;

		.empty-state-title {
			padding-top: 2px;
			color: $black-text;
			font-size: 18px;
			font-weight: 800;
		}
	}
}
