.installments-banner {
	display: flex;
	align-items: center;
	background-color: #eafff5;
	border-radius: 14px;

	&__text {
		font-size: 15px;
		font-weight: 400;
		line-height: 21px;
		color: #008f4b;
	}
}
