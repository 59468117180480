@import 'variables';
@import 'basketModal';
@import 'agentSettingsModal';
@import 'displayCurrencyComponent';

.top-bar {
	//font-family: 'Raleway', sans-serif;
	display: block;
	width: 100%;
	background: #ffffff;
	height: $top-bar-height;
	//box-shadow: 0 1px 7px rgb(222, 222, 222);
	//border-bottom: 1px solid $gray;

	// make nav-bar fixed
	position: fixed;
	z-index: 99;

	.personal-bar {
		display: block;
		//background-color: $personal-bar-background-color;
		//color: $personal-bar-font-color;//$personal-bar-font-color;
		//@extend .Raleway-Regular;
		//font-size: 16px;
		@include Font-Regular($personal-bar-font-family, $personal-bar-font-size, $personal-bar-font-color);
		width: 100%;
		height: 42px;
		line-height: 42px;

		.inner-personal-bar {
			width: $width-main;
			margin: 0 auto;
			height: 100%;
			text-align: right;
			vertical-align: middle;

			.popover-content {
				.notification {
					color: black;

					&:hover {
						//background: #FBFBFB;
						cursor: pointer;
					}
				}
			}

			div.personal-bar-div:last-child {
				//border-right: 1px solid $personal-bar-border-color;
			}

			div.personal-bar-div {
				.service-telephone-number {
					margin-right: 8px;
				}

				&.circle {
					i {
						border: solid 1px;
						padding: 5px;
						border-radius: 16px;
					}

					&#notifications {
						&:focus {
							i {
								background-color: #2098d1;
								color: #fff;
							}
						}
					}
				}

				&.currency {
					i {
						padding-right: 3px;
					}
				}

				padding-left: 15px;
				padding-right: 15px;
				display: inline-block;
				height: 100%;
				cursor: pointer;
				//margin-left: 36px;
				//padding-left: 18px;
				//padding-right: 18px;
				//border-left: 1px solid $personal-bar-border-color;
				//border-right: 1px solid $personal-bar-font-color;

				//span {
				//  display: inline-flex;
				//  vertical-align: middle;
				//}

				//i.fa {
				//  font-size: 10px;
				//  padding-right: 6px;
				//}
				//
				//i.material-icons {
				//  display: inline-flex;
				//  vertical-align: middle;
				//}
			}
		}
	}

	.company-bar {
		display: block;
		@include Font-Regular($company-bar-font-family, $company-bar-font-size, $company-bar-font-color);
		width: 100%;
		height: 100%;

		.inner-company-bar {
			min-width: 1092px;
			margin: 0 auto;
			height: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.logo-div .link .logo {
				margin-left: 20px;

				.image {
					max-height: 53px;
				}

				.dead-logo {
					-webkit-filter: saturate(0%);
				}
			}

			.navigation-container {
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 25px;

				.company-bar-div {
					height: 100%;

					.dropdown-menu {
						right: 0;
						left: auto;
					}

					img.companyLogo {
						margin-right: 8px;
						margin-left: 8px;
					}
				}

				.navigation-div {
					margin-right: 20px;
					margin-left: 20px;
					font-size: 16px;
					height: 100%;
					min-width: 80px;
					font-weight: 500;

					a {
						text-decoration: none;
						color: $gray-1;
						display: block;
						height: 100%;
						text-align: center;
						font-size: 18px;
						padding-top: 28px;

						&:before {
							background: var(--desktop-navigation-menu-item);
						}

						&.active {
							overflow: visible;
							color: var(--desktop-navigation-menu-item);

							&:before {
								background: var(--desktop-navigation-menu-item);
								left: 0;
								right: 0;
							}
						}
					}

					i.fa {
						display: none;
						color: #a4d5e9;
						padding-right: 12px;
					}
				}

				.basket-div {
					border-right: 1px solid #d8d8d8;
					padding-inline-end: 22px;
					padding-inline-start: 10px;
					display: flex;
					height: 100%;
					align-items: center;

					.share-icon {
						padding: 0 !important;

						box-shadow:
							rgba(0, 0, 0, 0.12) 0 1px 6px,
							rgba(0, 0, 0, 0.12) 0 1px 4px;
						border-radius: 50px;
						width: 36px;
						height: 36px;

						div > svg {
							position: absolute;
							right: 6px;
							top: 6px;
						}

						// TODO: adjust to icon
						span.fa {
							box-shadow:
								rgba(0, 0, 0, 0.12) 0 1px 6px,
								rgba(0, 0, 0, 0.12) 0 1px 4px;
							border-radius: 50px;
							width: 36px;
							height: 36px;

							position: absolute !important;
							top: 19px;
							left: 0;

							padding-top: 6px !important;
						}
					}
				}

				.profile-div {
					margin-left: 32px !important;
				}

				.user-name-div,
				.company-name-div {
					margin-left: 40px;
					color: $gray-1;
					height: 52px;
					line-height: 52px;
					vertical-align: middle;
					font-size: 18px;
				}

				.company-name-div {
					max-width: 180px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-top: 4px;
				}

				.display-currency-div {
					margin-left: 40px;
					color: $gray-1;
					height: 52px;
					line-height: 52px;
					vertical-align: middle;
					font-size: 18px;

					div.display-currency {
						display: flex;
						align-items: center;
						padding-top: 4px;
					}
				}

				.user-name-div {
					div {
						cursor: pointer;

						a {
							color: inherit;
						}

						.avatar {
							background: var(--desktop-avatar-background);
							color: var(--desktop-avatar-color);

							img {
								vertical-align: top;
							}
						}
					}
				}
			}
		}
	}

	&.dark {
		background: #101f31;
		box-shadow: 0 1px 7px rgb(16, 31, 49);

		.navigation-div {
			a {
				color: #fff !important;
			}
		}

		.company-name-div {
			color: #fff !important;
		}
	}

	.shadower {
		position: absolute;
		box-shadow: 0 1px 7px rgb(222, 222, 222);
		//top: 62px;
		//top: 68px;
		// top: 74px;
		top: 54px; // FIX
		width: 100%;
		z-index: 9;
	}
}

.leisure-experience-rewards {
	text-align: center;
	padding: 8px 12px 16px 12px;

	div {
		display: block;

		&.name {
			font-size: 20px;
			font-weight: bold;
		}

		&.balance {
			font-size: 20px;
			font-weight: bold;

			margin-top: 20px;
		}
	}
}
