.trip-request-wizard-page {
	font-family: $secondary-font;
	max-width: 1080px;
	min-width: 760px;
	margin-left: auto;

	.title {
		padding: 24px 0;
		color: $gray-1;
		font-size: 24px;
		font-weight: 600;
	}

	.wizard {
		.destinations {
			&.busy {
				opacity: 0.5;
				cursor: progress;
				.add-destination {
					pointer-events: none;
				}
			}

			.add-destination {
				position: relative;
				margin-top: 24px;
				height: 65px;

				&:last-child {
					margin-bottom: 65px;
				}

				.destination-container {
					padding-right: 16px;

					.field {
						padding-right: 24px;

						&.destination {
							width: 48% !important;

							label {
								font-size: 14px;
								font-weight: 400;
								color: $gray-2;
								margin-left: 4px;
								margin-bottom: 2px;
							}

							// LocationAutocompleteInputField overrides

							.MuiInputBase-root {
								legend {
									max-width: 0.01px; // don't show legend
								}

								.MuiAutocomplete-input {
									padding-left: 0;
								}

								.MuiOutlinedInput-input {
									&::placeholder {
										color: $black-text;
										opacity: 0.5;
										font-size: 15px;
									}
								}

								&.Mui-focused {
									.MuiOutlinedInput-notchedOutline {
										border-color: $main-blue;
									}
								}

								&:focus-within {
									background-color: transparent;
								}
							}
						}

						&.date {
							// width: 20%;

							// Calendar overrides
							.MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd {
								display: none;
							}

							.MuiInputAdornment-root {
								position: absolute;
								right: 8px;
							}

							.MuiInputBase-root {
								height: 42px;
								min-width: 200px;

								legend {
									max-width: 0.01px; // don't show legend
								}
							}

							.MuiInputLabel-root {
								transform: translate(4px, -24px);
								font-size: 14px;
								color: $gray-2;
							}

							.MuiInputBase-inputAdornedEnd {
								color: $black-text;
								font-size: 15px;
							}
						}
					}

					.remove-button {
						position: absolute;
						top: 30px;
						right: 0;
						border: 0;
						background-size: 23px;
						width: 23px;
						height: 23px;
						background-color: $gray-1;
						mask-repeat: no-repeat;
						mask-size: 23px;
						mask-image: url('../img/icons/trash_bin.svg');
						cursor: pointer;

						&:hover {
							background-color: $secondary-blue;
						}
					}
				}
			}
		}

		.footer {
			.add-another-destination {
				color: $blue-2;
				line-height: 17px;
				gap: 10px;
				font-weight: 600;
				cursor: pointer;

				&.busy {
					opacity: 0.5;
					cursor: progress;
				}

				&:hover:not(.busy) {
					text-decoration: underline;

					a {
						background-color: $light-blue;
					}
				}
			}

			.error {
				color: $danger;
			}
		}
	}

	.actions-bar {
		margin-top: 24px;
		gap: 12px;
	}
}
