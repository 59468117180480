.amenities {
	.column {
		display: inline-block;
		width: 33%;
		vertical-align: top;

		.amenity {
			gap: 6px;
			text-transform: capitalize;
			@include Font-Regular(
				$hotel-page-amenity-font-family,
				$hotel-page-amenity-font-size,
				$hotel-page-amenity-font-color
			);
			line-height: $hotel-page-amenity-line-height;

			&::before {
				content: '';
				display: inline-block;
				width: 24px;
				height: 24px;
				background-color: $secondary-blue;
				-webkit-mask-size: contain;
				mask-size: contain;
				-webkit-mask-repeat: no-repeat;
				mask-repeat: no-repeat;
				-webkit-mask-image: url('../img/check-circle.svg');
				mask-image: url('../img/check-circle.svg');
			}
		}

		.amenity:not(:last-child) {
			margin-bottom: 24px;
		}
	}
}
