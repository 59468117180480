@import 'variables';

.preview-pane {
	.title {
	}

	.sub-title {
	}
}

.airfare-page {
	font-family: $secondary-font;
	font-weight: normal;
	max-width: 1080px;
	min-width: 760px;
	margin-left: auto;

	.name-bar {
		margin-top: 24px;
		padding-left: 48px;
		position: relative;
		height: 48px;

		.name {
			font-size: 20px;
			color: #0b0b0b;
		}

		.action {
			//color: #999;
			transition: color 0.25s;

			&.cancel {
				position: absolute;
				right: 16px;
				bottom: 10px;
			}

			&:hover {
				color: #333;
			}

			.evil-icon {
				width: 32px;
				height: 32px;
			}
		}
	}

	.airfare {
		// NOTE: .wizard (root) styles moved to .paper in managementPage.scss...
		//border: 1px solid #ddd;
		//border-radius: 4px;
		//padding: 24px;
		//margin: 24px;
		//font-family: $secondary-font !important;
		//font-weight: normal !important;
		//margin-top: 0;

		.airfare-cost-pnr {
			.field {
				display: inline-block;
				width: 140px;
				margin-right: 28px;
			}
		}

		.airfare-flights {
			//min-height: 195px;

			.airfare-flight {
				display: block;
				position: relative;
				//height: 65px;

				&.hovering {
					background: #f8fbff;
					border-radius: 2px;
				}

				.icon-container {
					display: inline-block;

					i.fa {
					}
				}

				.fields {
					display: inline-block;
					//padding-left: 6px;

					.line {
						display: block;

						.field {
							display: inline-block;
							padding-right: 28px;
							vertical-align: bottom;

							&.first {
								width: 45%;

								.field-input {
									&.flight-number {
										width: 33%;
										padding-right: 28px;
									}

									&.class {
										width: 67%;
									}
								}
							}

							&.airport {
								width: 25%;
							}

							&.date-time {
								width: 12%;

								.field-input {
									&.date {
										width: 33%;
									}

									&.time {
										width: 67%;
									}
								}
							}

							&.last {
								width: 6%;
							}

							.field-input {
								display: inline-block;
								vertical-align: bottom;

								div,
								label,
								input {
									// ?
									font-family: $secondary-font !important;
									font-weight: normal !important;
								}
							}
						}
					}

					.action.remove {
						position: absolute;
						right: 10px;
						bottom: 16px;
						color: #999;

						&:hover {
							color: #666;
						}

						.evil-icon {
							width: 22px;
							height: 22px;
						}
					}
				}
			}
		}

		.actions-bar {
			position: relative;
			border-top: 1px solid #e5e5e5;
			padding-top: 20px;

			.action {
				font-size: 12px;
				color: #999;
				margin-right: 30px;
				transition: color 0.25s;

				&:hover {
					color: #666;
				}

				&.next {
					color: #0b0b0b;
				}

				&.cancel {
					position: absolute;
					right: 0;
					margin-right: 0;
					top: 14px;

					.evil-icon {
						width: 25px;
						height: 25px;
					}
				}
			}

			.error {
				color: red;
			}
		}
	}
}
