@mixin reasons($row-view: false) {
	ul {
		padding-left: 16px;
		margin-bottom: 5px;
		list-style: none;

		li {
			display: block;
			color: $gray-2;
			line-height: 19px;
			font-size: 13px;
		}

		li.negotiated_rate {
			color: green;
		}

		li.flag {
			&::before {
				content: '';
				width: 16px;
				height: 16px;
				margin: 3px 5px -3px -16px;
				background-image: url('../../img/results_page/check-circle.svg');
				background-repeat: no-repeat;
				background-size: contain;
				display: inline-block;
			}

			span {
				font-family: $secondary-font;
				margin-left: -16px;
				margin-right: 40px;
			}
		}

		li div.check-icon {
			content: '';
			width: 16px;
			height: 16px;
			margin: 2px 5px 4px 0;
			background-image: url('../../img/results_page/check-circle.svg');
			background-repeat: no-repeat;
			background-size: contain;
			display: inline-block;
		}

		@if $row-view {
			li,
			li.flag {
				display: block;
			}

			li.inline {
				display: inline-block;
				margin-left: 0;
			}

			li.inline ~ li.inline {
				margin-left: 34px;
			}
		}
	}
}
