.faq-container {
	width: 700px;
	margin: auto;
	padding: 80px 0;

	.faq-title {
		font-size: 38px;
		font-weight: 700;
		color: $gray-1;
		margin-bottom: 20px;
	}

	.faq-question {
		font-size: 15px;
		font-weight: 700;
		color: $black-text;
		margin-right: 20px;
	}

	.faq-sub-question {
		font-size: 15px;
		font-weight: 600;
		color: $black-text;
	}

	.faq-answer {
		font-size: 14px;
		color: #555;
		margin-bottom: 20px;

		:last-child {
			margin-bottom: 0;
		}
	}

	.faq-list {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.MuiAccordionSummary-expandIconWrapper > svg > path {
		stroke: $secondary-blue;
	}

	.faq-item {
		border-radius: 8px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		overflow: hidden;

		&.Mui-expanded {
			margin: 0 0 16px 0;
		}
	}
}
