@import 'variables';

.cheapopo-input {
	margin-left: 10px;
	margin-bottom: 10px;
	display: block;
	background: transparent;
	border: 0;
	height: 20px;
	width: 100%;
	@include Font-Regular($search-bar-input-font-family, $search-bar-input-font-size, $search-bar-input-font-color);
}

.cheapopo-label {
	text-transform: uppercase;
	@include Font-Regular($search-bar-label-font-family, $search-bar-label-font-size, $search-bar-label-font-color);
	display: block;
	height: 40%;
}

.cheapopo-button {
	background-image: linear-gradient(to bottom, #5cb85c 0, #419641 100%);
	border-radius: 4px 0 0 4px;
	/* background-color: #00D994; */
	text-transform: uppercase;
	color: #ffffff;
	text-align: center;

	&:hover {
		background-color: #419641;
		background-position: 0 -15px;
	}
}
