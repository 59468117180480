///
/// Used for both RecentSearchesBox & RecentSearchComponent
///
@import 'variables';

.recent-searches-box {
	min-height: 227px;

	.title {
		@include Font-Regular($secondary-font, $base-font-size-21, $black-text);

		font-weight: bold;
		margin-bottom: 28px;
	}

	.recent-search-component {
		position: relative;
		display: flex;
		max-width: 555px;
		height: 110px;
		margin-bottom: 10px;
		box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
		border-radius: 16px;
		transition: box-shadow 0.5s ease-out;
		cursor: pointer;
		width: 100%;
		text-align: left;

		&:hover {
			box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.18);
		}

		.recent-search-terms {
			padding: 14px 22px;
			width: 100%;

			.recent-search-destination {
				@include Font-Regular($secondary-font, $base-font-size-18, $black-text);
				margin-bottom: 6px;
				font-weight: bold;
				line-height: 24px;
			}

			.recent-search-rest,
			.recent-search-rooms-and-travelers {
				display: flex;
				align-items: center;
				line-height: 16px;
				@include Font-Regular($secondary-font, $base-font-size-13, $gray-border-dark);

				.icon {
					margin-right: 6px;
				}
			}
		}

		.recent-search-removal-container {
			position: absolute;
			right: 8px;
			top: 9px;

			button.remove-button:focus,
			button.remove-button:active {
				outline: none;
			}

			button.remove-button.hovering {
				background-color: #555555 !important;
				cursor: pointer;
			}

			button.remove-button {
				border: 0;

				background-size: 24px 24px;
				width: 24px;
				height: 24px;
				display: block;
				background-color: #dcd9d9;

				mask-image: url('../img/recent_searches/x-close.svg');
				mask-repeat: no-repeat;
				mask-size: 24px 24px;
			}
		}
	}
}
