.flex {
	display: flex;
}

.flex-column {
	flex-direction: column;
}

.flex-end {
	justify-content: flex-end;
}

.align-flex-end {
	align-items: flex-end;
}

.align-flex-start {
	align-items: flex-start;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.space-between {
	justify-content: space-between;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.align-items-baseline {
	align-items: baseline;
}

.flex-strech {
	flex: 1;
}

.flex-spacer {
	flex: 1 1 100%;
}

.flex-wrap {
	flex-wrap: wrap;
}

.pointer {
	cursor: pointer;
}

.display-inline-block {
	display: inline-block;
}

.bold {
	font-weight: bold;
}

.align-right {
	text-align: right;
}

.blur {
	filter: blur(2px) grayscale(40%);
}

.cursor-progress {
	cursor: progress;
}
