.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__input {
	width: 240px;
	height: 30px;
	padding: 10px 20px;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border: 1px solid #aaa;
	border-radius: 4px;
}

.react-autosuggest__input--focused {
	outline: none;
}

.react-autosuggest__input--open {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
	display: none;
}

//.react-autosuggest__suggestions-container--open {
//  display: block;
//  position: absolute;
//  top: 51px;
//  width: 280px;
//  border: 1px solid #aaa;
//  background-color: #fff;
//  font-family: Helvetica, sans-serif;
//  font-weight: 300;
//  font-size: 16px;
//  border-bottom-left-radius: 4px;
//  border-bottom-right-radius: 4px;
//  z-index: 2;
//}

.react-autosuggest__suggestions-container--open {
	display: block;
	position: absolute;
	top: 32px;
	width: 240px;
	border: 1px solid #d9d9d9;
	border-top: none;
	background-color: #fff;
	font-family: Helvetica, sans-serif;
	font-weight: 100;
	font-size: 14px;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	z-index: 2;
}

.react-autosuggest__suggestions-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.react-autosuggest__suggestion {
	cursor: pointer;
	padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
	background-color: #ddd;
}

.react-autosuggest__section-container {
	border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
	border-top: 0;
}

.react-autosuggest__section-title {
	padding: 10px 0 0 10px;
	font-size: 12px;
	color: #777;
}

////////////////////////////////////////////////////////////////////////////////

.certain-category-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
	right: 12px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
	color: #666;
	font-weight: bold;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
	border-bottom: 1px solid var(--general-colors-gray-light);
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item {
	padding-left: 16px;
}

.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
	text-align: center;
	cursor: default;
}

.certain-category-search-dropdown .ant-select-dropdown-menu {
	max-height: 300px;
}

.certain-search-item-count {
	position: absolute;
	color: #999;
	right: 16px;
}

.certain-category-search.ant-select-focused .certain-category-icon {
	color: #108ee9;
}

.certain-category-icon {
	color: #6e6e6e;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	font-size: 16px;
}
