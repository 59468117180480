@import 'variables';

.search-bar-container {
	position: relative;
	width: 1124px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.search-bar {
	width: 100%;
	max-width: 1124px;
	z-index: 1;

	.search-user-name {
		display: none;
		color: $black-text;
		font-size: $base-font-size-15;
		margin-bottom: 6px;
	}

	.search-title {
		color: $white;
		font-size: 2.75rem;
		line-height: 58px;
		font-weight: 700;
		text-shadow: 0 0 13px rgba(0, 0, 0, 0.4);
	}

	.search-sub-title {
		color: $white;
		font-size: 1.375rem;
		line-height: 23px;
		margin-top: 5px;
		text-shadow: 0 0 13px rgba(0, 0, 0, 0.4);

		&.private-travel {
			margin-bottom: 35px;
		}
	}

	.exclamation.icon {
		width: 14px;
		height: 14px;
		background: url('../img/results_page/check_brown.svg') center center no-repeat;
	}

	// .special-search-options{
	//   display: inline-flex;
	//   // align-items: center;
	// }
}
