@import './mixins/sharedMixin';
@import './mixins/inPolicyMixin';

.representative-deal {
	width: 120px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;

	.price-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		&.bottom {
			margin-top: auto;
			margin-bottom: 8px;
		}

		.deal-price-container {
			@include Font-Regular($secondary-font, $base-font-size-24, var(--general-deal-price), 700);
			line-height: 29px;

			.price-per-night {
				text-align: end;
			}
		}

		.deal-price-per-night {
			text-align: end;
			font-size: 13px;
			white-space: nowrap;

			.price {
				font-weight: 600;
			}
		}

		.tooltip-icon {
			background: url('../img/icons/tooltip.svg') right center no-repeat;
			background-size: 13px 13px;
			padding-right: 18px;
		}

		.tooltip-icon:hover {
			color: $black-text;
			cursor: pointer;
		}

		.per-night-text {
			@include Font-Regular($secondary-font, $base-font-size-13, $gray-2);
			line-height: 19px;
			margin-top: 2px;
		}
	}

	@include is-in-policy;

	.is-in-policy {
		margin-top: 2px;
	}

	@include select-button;
}
