@import 'colors';
@import 'hotelAddressComponent';

.deal-details-break-price {
	@include side-info-block(24px);
	@include Font-Regular($secondary-font, $base-font-size-15, #1e1e1e);

	position: sticky;
	top: 130px;

	.number {
		font-size: 16px;
		font-weight: 500;
	}

	.pay {
		color: #0bb2b1;
	}

	.total {
		font-size: 16px;
		font-weight: 800;
	}

	.line {
		margin-top: 3px;
		margin-bottom: 3px;
		border: 1px solid #eeeeee;
		height: 1px;
	}

	.space-container {
		margin-top: 6px;
		margin-bottom: 6px;
	}

	.space-bottom {
		margin-bottom: 6px;
	}

	.space-top {
		margin-top: 6px;
	}

	.tooltip-icon {
		background: url('../img/icons/tooltip.svg') right center no-repeat;
		background-size: 13px 13px;
		padding-right: 18px;
	}

	.tooltip-icon:hover {
		color: $black-text;
		cursor: pointer;
	}

	.price-break-disclaimer {
		font-size: 13px;
		margin-top: 8px;
	}
}
