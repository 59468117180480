@import 'variables';
@import 'travelersCompleteComponent';
@import 'airportsCompleteComponent';

$purposes-width: 49.8%;
$cost-center-width: 25%;
$staying-with-width: $purposes-width + $cost-center-width;

.trip-request-page {
	//max-width: 1092px;
	//min-width: 1092px;
	//height: 100%;
	//margin: 0 auto;
	//padding-top: 36px;
	//position: relative;
	//color: #4A4A4A;
	font-family: $secondary-font;
	font-weight: normal;
	margin-left: auto;
	max-width: 1080px;
	min-width: 760px;

	.left-inputs {
		display: flex;
		width: 42%;
		margin-right: 24px;

		.input {
			width: 48%;

			&:first-child {
				margin-right: 4%;
			}
		}
	}

	.right-inputs {
		display: flex;
		flex: 1;
		margin-right: 160px;

		.input {
			width: 46%;

			&:first-child {
				margin-right: 8%;
			}
		}
	}

	hr.separator {
		margin: 10px 25px 10px 25px;
		&.header {
			margin: 10px 25px 0px 25px;
		}
		&.thin {
			margin: 0px 25px 0px 25px;
		}
	}

	.name-bar {
		.action {
		}

		.name {
			margin-top: 2px;
			max-width: 90%;

			.view-name,
			.edit-name {
				min-width: 210px;
				max-width: 100%;
				min-height: 31px;
				display: inline-block;
				font-size: 20px !important;
			}

			.view-name {
				cursor: text;
				white-space: nowrap;
			}

			&:hover {
				.view-name {
					border-bottom: 1px solid #e5e5e5;
				}
			}

			.edit-name {
				margin-top: -36px;
				padding-bottom: 4px;
			}

			.action.edit {
			}

			.action.lock {
				span.fa.fa-lock {
					color: inherit;
				}
			}
		}

		.action.cancel {
		}
	}

	.trip-request.paper {
		padding: 0 !important;

		.section {
			min-height: 180px; //temp
			margin: 12px;
			padding: 12px;

			// patch keeping disabled text black
			textarea,
			.field-input.date input {
				color: rgba(0, 0, 0, 0.87) !important;
			}

			&.additional-info {
				min-height: 230px !important;
			}

			&.components {
				margin: 0;
				padding: 0;

				.title-line {
					position: relative;
					height: 39px;
					width: 200px;
					background: #f3f3f3;
					border: 1px solid #eee;
					border-top: none;
					border-radius: 0px 0px 4px 4px;
				}

				&.bookings {
					.booked-item {
						padding: 5px;
						padding-left: 24px;

						.column {
							display: inline-block;
							vertical-align: top;

							&.actions {
								&.busy {
									.remove-airfare {
										cursor: progress;
									}
								}

								.remove-airfare {
									position: absolute;
									right: 16px;
									top: 15px;

									.evil-icon {
										width: 18px;
										height: 18px;
										color: #999;

										&:hover {
											color: #666;
										}
									}
								}
							}

							&.icon-column {
								margin-right: 12px;

								.icon-container {
									i.fa {
										color: #cccccc;
										font-size: 18px;
									}
								}
							}

							.line {
								display: block;

								&.title {
									margin-bottom: 6px;

									.header {
										color: rgba(0, 0, 0, 0.3);
										font-size: 10.5px;
									}
								}
							}
						}

						&.airfare {
							.column {
								&.flight-number {
									width: 20%;
								}

								&.route {
									width: 20%;
								}

								&.departure {
									width: 30%;

									.date {
										margin-right: 12px;
									}
								}

								&.cost {
								}
							}
						}

						&.accommodation {
							.column {
								&.actions {
									//margin-left: 10%;
									.booking-menu {
										color: #223143;
									}
								}

								&.hotel-name {
									width: 40%;
									text-transform: capitalize;
								}

								&.check-in,
								&.check-out {
									width: 15%;
								}

								.travel-policy {
									.icon {
										width: 13px;
										height: 13px;
										margin-right: 4px;
										background-size: 13px 13px !important;
										top: 2px;
									}
									&.in-policy {
										.icon {
											background: url('../img/results_page/In_Policy.svg') center center no-repeat;
										}
										.text {
											color: #417505; // green
										}
									}

									&.out-of-policy {
										.icon {
											background: url('../img/results_page/Out_of_Policy_Ex.svg') center center
												no-repeat;
										}
										.text {
											color: #d18a14; // orange
										}
									}
								}

								.breakfast {
									margin-left: 4px;

									// ...
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									width: 67%;
									display: inline-block;
									vertical-align: top;
									min-width: 50px;
								}

								&.status-cost {
									min-width: 150px;
									.line.title {
										.status {
											display: block;
											width: 100%;
											i.fa.fa-spin.fa-spinner {
												margin-right: 4px;
											}

											span {
												@include Font-Regular($secondary-font, 10.5px, #4a4a4a);
												//font-weight: 300 !important;
											}

											&.quote {
												span {
													color: #0b76cc !important;
												}
											}
											&.pending {
												span {
													color: #d18a14 !important; //#FFAA00;
												}
											}
											&.canceled {
												span {
													color: #d0021b !important; //red;
												}
											}
											&.confirmed {
												span {
													color: #417505 !important; //green;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}

			.field {
				.field-input {
					&.select.disabling {
						& > div > div {
							cursor: text !important;

							button {
								display: none !important;
							}
						}
					}
				}
			}

			&.hovering,
			&.editing {
				border: 1px solid #e5e5e5;
				border-radius: 4px;
				border-left: 4px solid #99ddff;
				position: relative;
				padding: 11px;
				padding-left: 8px;

				&.requests.components {
					margin: 12px;

					.title-line {
						margin: 8px;
						margin-top: 24px;
					}
				}
			}

			&.travel-request {
				.line {
					display: block;
					position: relative;
					margin-top: 5px;
					min-height: 48px;

					&:first-of-type {
						margin: 0;
					}
					.field {
						display: inline-block;
						vertical-align: top;
						width: $cost-center-width;
						position: relative;
						&:not(:last-child) {
							padding-right: 24px;
						}

						&.trip-purposes {
							width: 100%;

							// also PATCH
							.field-input.select {
								div {
									div {
										div:nth-child(2) {
											padding-right: 0 !important;

											& + button {
												right: -24px !important;
											}
										}
									}
								}
							}
						}

						// PATCH- should be careful in case material ui changes shit
						&.cost-center {
							width: 100%;

							.field-input.select {
								div {
									div {
										div:nth-child(2) {
											padding-right: 0 !important;

											& + button {
												right: -24px !important;
											}
										}
									}
								}
							}
						}

						&.vacation-days {
							width: 100%;
							margin-top: 12px;
						}

						& .date-picker {
							width: 48%;

							&.first {
								margin-right: 4%;
							}
						}

						& .disabled-margin {
							margin-bottom: 12px;
						}

						& .traveler {
							margin-top: 22px;
						}

						&.staying-with-text {
							width: 100%;
							margin-top: 22px;

							// also PATCH
							.field-input.select {
								div {
									div {
										div:nth-child(2) {
											padding-right: 0 !important;

											& + button {
												right: -24px !important;
											}
										}
									}
								}
							}
						}

						&.trips-schedule-and-agenda-text {
							width: 100%;
							margint-top: 12px;
						}

						.field-input {
							vertical-align: top;

							label {
								white-space: nowrap;
							}

							&.date {
								display: inline-block;
								width: 50%;

								&.from {
									padding-right: 12px;
								}

								&.to {
									padding-left: 12px;
								}
							}

							&.currency {
								width: 20% !important;
							}
							&.amount {
								width: 80% !important;
							}
						}

						.dates-summary {
							display: flex;
							justify-content: space-between;
						}

						div.vacation-days-total,
						a.clear-vacation-days {
							font-size: 12px;
							display: block;
							margin-top: 8px;
						}

						div.vacation-days-total {
							color: #999;
							font-size: 11px;
						}

						a.clear-vacation-days {
							z-index: 1;
						}
					}
				}
			}

			&.components {
				min-height: 240px;
				margin-bottom: 20px;

				.title-line {
					position: relative;
					height: 36px;
					margin-left: 25px;
					margin-bottom: 20px;

					.title {
						color: #333;
						position: absolute;
						top: 6px;
						left: 10px;
						font-size: 14px;
					}

					.add-travel-component {
						width: 30px;
						height: 30px;
						color: #333;
						transition: all 0.2s ease-out;

						&.travel-manager-mode {
							left: 48px;
						}

						&.open {
							transform: rotate(45deg);
							color: #0288d1;
						}

						&:hover {
							color: #0288d1;
						}
					}

					.forward-button {
					}

					.add-flight-button {
					}

					.add-acc-button {
					}

					.buttons {
						transition: all 0.2s ease-out;
						width: 0px;
						position: absolute;
						top: 0px;
						display: inline-flex;
						overflow: hidden;
						text-overflow: clip;
						height: 70px;
						&.more-left {
							button {
								left: 84px !important;
							}
						}
						button {
							left: 45px;
							margin-left: -10px !important;
							top: -16px;
							span.fa {
								color: #888 !important;
								font-size: 18px !important;
							}
						}
						//&.travel-manager-mode {
						margin-left: 40px;
						//}
						&.open {
							width: 370px;
							//border: 1px solid #999;
							//border-radius: 48px;
							//margin: 0 auto;
							//margin-bottom: 15px;
						}
						.icon-button {
							width: 50%;
							display: inline-block;
							padding: 8px;

							&:not(:last-child) {
								border-right: 1px solid #999 !important;
							}

							i.fa {
								font-size: 18px;
								color: #999;
								transition: color 0.3s;

								&:hover {
									color: #666;
								}
							}
						}
					}

					.action {
						position: absolute;
						top: 0;

						.evil-icon {
							width: 36px;
							height: 36px;
							color: #999;

							&:hover {
								color: #666;
							}
						}

						&.forward {
							//right: 36px;
							right: 0;
						}

						&.edit {
							right: 0;
						}
					}
				}
				.trip-components {
					min-height: 192px;
				}
				&.bookings {
					.no-bookings {
						text-align: center;
						margin-top: 48px;

						h3 {
							color: #e5e5e5;
						}
					}

					.booked-components {
						font-size: 12px;

						.booked-item {
							display: block;
							min-height: 70px;
							position: relative;

							//&:hover {
							&.hovered {
								background: $arbi-selection-background-color;
							}

							&:not(:last-of-type) {
								border-bottom: 1px solid #e5e5e5;
							}

							.line {
								height: 18px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
							}

							.column {
								&.actions {
									position: absolute;
									right: 2px;
									top: 11px;
								}
							}

							//.column {
							//  display: inline-block;
							//  vertical-align: top;
							//
							//  .line {
							//    display: block;
							//  }
							//}
						}
					}
				}

				&.requests {
					.trip-components {
						.add-component {
							display: flex;
							justify-content: flex-start;
							align-items: flex-end;
							position: relative;
							padding-left: 24px;
							padding-bottom: 5px;
							max-height: 64px;
							&:last-of-type {
								margin-bottom: 20px;
							}
							&.hovering {
								//background: #f8f8ff;
								background: $arbi-selection-background-color;
							}

							.component-icon,
							.field {
								display: inline-block;
							}

							.component-icon {
								margin-right: 12px;
								padding-bottom: 4px;

								i.fa {
									font-size: 18px;
									color: #ccc;
								}
							}

							.field {
								vertical-align: top;
								margin-right: 30px;

								.field-input {
									display: inline-block;
									vertical-align: top;

									label {
										white-space: nowrap;
									}

									&.date {
										width: 48% !important;

										&.check-out {
											margin-left: 15px;
										}
									}

									&.select {
										//margin-left: 13px;
										width: 50% !important;
										margin-left: 15px;
									}
								}

								&.find-link {
									font-size: 12px;
								}
							}

							.cancel-icon {
								position: absolute;
								right: 16px;
								top: 17px;

								.evil-icon {
									width: 18px;
									height: 18px;
									color: #999;

									&:hover {
										color: #666;
									}
								}
							}

							&.add-flight {
								.field {
									&.find-link {
										max-width: 100px;
										margin-bottom: 10px;
										position: absolute;
										right: 16px;
										top: 22px;
										a:hover {
											text-decoration: underline;
										}
										.icon {
											margin-bottom: -7px;
										}
									}
								}
							}

							&.add-accommodation {
								.field {
									&.find-link {
										max-width: 100px;
										margin-bottom: 10px;
										margin-left: 61px;
										position: absolute;
										right: 16px;
										top: 22px;
										a:hover {
											text-decoration: underline;
										}
										.icon {
											margin-bottom: -7px;
										}
									}
								}
							}

							&.add-car {
							}
						}
					}
				}
			}

			&.additional-info {
				.field {
					width: 90%;

					.field-input {
					}
				}
			}
		}

		.actions-container {
			position: relative;
			padding: 15px 24px;
			//border-top: 1px solid #E5E5E5;
			//padding-top: 15px;
			font-size: 12px;

			&.busy {
				cursor: progress;
				a.action {
					opacity: 0.5;
					pointer-events: none;
				}
			}

			.bottom-right-corner {
				position: absolute;
				right: 24px;
				margin-right: 0 !important;
				top: 14px;

				&.busy-indicator {
					&.fa-spinner {
						font-size: 20px;
					}
				}
			}

			a.action {
				display: inline-block;
				margin-right: 24px;
				color: #999;

				&.disabled {
					cursor: not-allowed;
				}

				&:not(.disabled):hover {
					color: #333;
				}

				&.trash {
					position: absolute;
					top: 11px;
					right: 0;
				}
			}

			span.error {
				color: #ff001d;
			}

			span.saving {
				position: absolute;
				right: 56px;
				color: #999;
			}
		}
	}
}
