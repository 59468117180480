@mixin is-in-policy {
	.is-in-policy {
		display: flex;
		align-items: center;
		gap: 4px;

		.icon {
			width: 14px;
			height: 14px;

			&.in-policy {
				background: url('../../img/results_page/check_green.svg') center center no-repeat;
			}

			&.out-of-policy {
				background: url('../../img/results_page/check_brown.svg') center center no-repeat;
			}
		}

		.text {
			font-family: 'Lato';
			font-size: 13px;
			font-weight: 500;
			line-height: 19px;

			&.in-policy {
				color: $new-green-text;
			}

			&.out-of-policy {
				color: $new-brown-text;
			}
		}
	}
}
