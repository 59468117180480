.help {
	.title {
	}

	.description {
		color: $lighter-gray;
	}

	.contact {
		color: $lighter-gray;

		.phone {
			text-transform: uppercase;
		}

		.email {
		}
	}

	.book-now-container {
		input {
			text-transform: uppercase;
		}
	}
}
