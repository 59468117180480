@import 'loginPage';
@import 'appGrid';
@import 'arbithing';
@import 'style';

html,
body {
	width: 100%;
	min-height: 100%;
	padding: 0;

	height: 100%;
	//margin: 0 auto;
	font-family: 'Lato', sans-serif;
	background: white !important;
}

///
/// pre-loading css images
///
body::after {
	position: absolute;
	width: 0;
	height: 0;
	overflow: hidden;
	z-index: -1; // hide images
	content: url(https://arbitrip.s3.amazonaws.com/logos/arbitrip_favicon%402x-min.svg) url(/img/search_page/pic_bg.jpg);
	// can use for multiple urls, just add here e.g. url(x) url(y)
}

//#app-grid-container {
//  width: 100%;
//  height: 100%;
//}

#app-container {
	width: 100%;
	height: 100%;
}

#not-supported {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
	top: 42%;
	transform: translateY(-50%);
	img.logo {
		height: 66px;
	}
	div.messages {
		padding-top: 28px;
		height: 100px;
		font-size: 21px;
		color: #444;
		.message {
			width: 50%;
			margin: auto;
		}
		i.fa {
			font-size: 22px;
			margin-right: 4px;
		}
	}
}
