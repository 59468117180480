@import 'variables';
@import 'classes';

.login-page {
	background: url('/img/login_page/login_bg.png') no-repeat center center fixed;
	-webkit-background-size: cover; /* For WebKit*/
	-moz-background-size: cover; /* Mozilla*/
	-o-background-size: cover; /* Opera*/
	background-size: cover; /* Generic*/

	////background-color: #2B607C;
	//background-image: linear-gradient(to top right,#fc7279 10%, #ff7b9c 65%, #ffc019 125%);

	//background-color: #1F4B6C;
	//background-image: linear-gradient(to top right,#1F4B6C 10%, #208878 65%, #20C897 125%);

	//background-color: #144D92;
	//background-image: linear-gradient(to top right,#144D92 10%, #2677BA 65%, #3DA9DD 125%);

	width: 100%;
	height: 100%;
	position: relative;

	.login-box {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -247px; // TODO: Sass...
		margin-left: -164px;

		-webkit-box-shadow: 0px 0px 58px 0px rgba(0, 0, 0, 0.75);
		-moz-box-shadow: 0px 0px 58px 0px rgba(0, 0, 0, 0.75);
		box-shadow: 0px 0px 58px 0px rgba(0, 0, 0, 0.75);
		//margin: 0 auto;
		width: 329px;
		display: block;

		.logo-bar {
			width: 100%;
			height: 81px;
			background: #f6fafb;
			border-bottom: 5px solid #59b7e3;
			border-radius: 4px 4px 0 0;

			img {
				position: relative;
				top: 25%;

				display: block;
				margin: 0 auto;
				height: 50%;
			}
		}

		.login-form-container {
			width: 100%;
			height: 413px;
			background: rgba(255, 255, 255, 0.8);
			padding-top: 40px;
			border-radius: 0 0 4px 4px;

			.input-container {
				width: 90%;
				display: block;
				margin: 0 auto;
				margin-bottom: 40px;

				input,
				label {
					width: 300px;
					display: block;
					margin: 0 auto;
				}

				label {
					@extend .cheapopo-label;
					font-size: 12px;
					margin-bottom: 4px;
				}

				input {
					//@extend .cheapopo-input;
					//background: transparent;
					//height: 20px;
					//border: 0;
					//border-bottom: 1px solid navy;
				}
			}

			.email-container {
			}

			.password-container {
			}

			.keep-me-logged-in-container {
				width: 300px;
				display: block;
				margin: 0 auto;
				margin-bottom: 20px;

				//input {
				//  margin-right: 8px;
				//}
				//
				//label {
				//
				//}

				label {
					@extend .cheapopo-label;
					text-transform: none;
					@include Font-Regular(
						$check-list-filter-item-font-family,
						$check-list-filter-item-font-size,
						//$check-list-filter-item-font-color
						#666666
					);
					height: 20px;
					width: 100%;
					display: inline-block;
					line-height: 20px; //$check-list-filter-item-line-height;
					vertical-align: middle;
					padding-left: 24px;
				}

				input[type='checkbox'] {
					display: none;
				}

				input[type='checkbox'] + label {
					background: url('../img/results_page/check_box.png') no-repeat; // left center no-repeat;
					background-size: 20px 20px;
				}
				input[type='checkbox']:checked + label {
					background: url('../img/results_page/check_box_active.png') no-repeat; // left center no-repeat;
					background-size: 20px 20px;
				}
			}

			.log-in-button-container {
				margin-bottom: 48px;

				button {
					width: 300px;
					height: 44px;
					//background: #20C897;
					//color: white;
					//border: 0;
					margin: 0 auto;
					display: block;
					//text-transform: uppercase;
					//@extend .cheapopo-button;
				}
			}

			.forgot-password-container {
				width: 100%;
				text-align: center;

				.forgot-password-link {
					@include Font-Regular(
						$check-list-filter-item-font-family,
						14px,
						//$check-list-filter-item-font-size,
						//$check-list-filter-item-font-color
						#666666
					);
				}
			}
		}
	}
}
