@mixin outlined-input-styles($border-color) {
	.MuiOutlinedInput-root {
		&:focus fieldset {
			border-color: $border-color;
		}

		&.Mui-focused fieldset {
			border-color: $border-color;
		}

		&:hover .MuiOutlinedInput-notchedOutline,
		.Mui-focused .MuiOutlinedInput-notchedOutline {
			border: 1px solid $border-color;
		}
	}
}
