#init_loader.loading {
	cursor: progress;
	text-align: center;
	height: 100%;
	#loading-message {
		font-size: 34px;
		position: relative;
		top: 40%;
		transform: translateY(-50%);
	}
	.banner {
		position: fixed;
		bottom: 0;
		width: 100%;
		text-align: center;
		padding: 24px;
		img.logo {
			height: 42px;
			-webkit-filter: saturate(0%);
		}
	}
}

#app_loader.loading {
	cursor: progress;
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	position: relative;
	top: 50%;
	transform: translateY(-50%);

	.logo-container {
		position: relative;
		width: 124px;
		margin: 0 auto;

		span.logo {
			height: 120px;
			width: 120px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			background-image: url(https://arbitrip.s3.amazonaws.com/logos/arbitrip_favicon%402x-min.svg);
			/* bob animation */
			-webkit-animation-name: hvr-bob-float, hvr-bob;
			animation-name: hvr-bob-float, hvr-bob;
			-webkit-animation-duration: 0.3s, 1.5s;
			animation-duration: 0.3s, 1.5s;
			-webkit-animation-delay: 0s, 0.3s;
			animation-delay: 0s, 0.3s;
			-webkit-animation-timing-function: ease-out, ease-in-out;
			animation-timing-function: ease-out, ease-in-out;
			-webkit-animation-iteration-count: 1, infinite;
			animation-iteration-count: 1, infinite;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-direction: normal, alternate;
			animation-direction: normal, alternate;

			&.rosh {
				filter: hue-rotate(150deg);
			}
		}

		img.apple-leaf {
			position: absolute;
			left: 20px;
			top: -64px;
			width: 128px;

			/* bob animation */
			-webkit-animation-name: hvr-bob-float, hvr-bob;
			animation-name: hvr-bob-float, hvr-bob;
			-webkit-animation-duration: 0.3s, 1.5s;
			animation-duration: 0.3s, 1.5s;
			-webkit-animation-delay: 0s, 0.15s; //3s;
			animation-delay: 0s, 0.15s; //3s;
			-webkit-animation-timing-function: ease-out, ease-in-out;
			animation-timing-function: ease-out, ease-in-out;
			-webkit-animation-iteration-count: 1, infinite;
			animation-iteration-count: 1, infinite;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-direction: normal, alternate;
			animation-direction: normal, alternate;
		}

		// img.honey {
		//   position: absolute;
		//   left: 128px;
		//   top: -196px;
		//   width: 256px;
		// }

		img.santa-hat {
			position: absolute;
			left: -36px;
			top: 0px;
			width: 109px;

			/* bob animation */
			-webkit-animation-name: hvr-bob-float, hvr-bob;
			animation-name: hvr-bob-float, hvr-bob;
			-webkit-animation-duration: 0.3s, 1.5s;
			animation-duration: 0.3s, 1.5s;
			-webkit-animation-delay: 0s, 0.15s; //3s;
			animation-delay: 0s, 0.15s; //3s;
			-webkit-animation-timing-function: ease-out, ease-in-out;
			animation-timing-function: ease-out, ease-in-out;
			-webkit-animation-iteration-count: 1, infinite;
			animation-iteration-count: 1, infinite;
			-webkit-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
			-webkit-animation-direction: normal, alternate;
			animation-direction: normal, alternate;
		}
	}
	div.messages {
		padding-top: 28px;
		height: 100px;
		font-size: 21px;
		color: #444;
		.message {
			width: 50%;
			margin: auto;
		}
		i.fa {
			font-size: 22px;
			margin-right: 4px;
		}
	}
}
