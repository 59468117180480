@import 'colors';
@import 'hotelAddressComponent';

.hotel-terms {
	@include light-gray-bg-rounded;
	padding: 32px;
	margin-bottom: 32px;

	.content-container {
		flex: 2;
		margin-left: 24px;
		@include Font-Regular($secondary-font, $base-font-size-15, $gray-1, 400);
		line-height: 21px;

		.name {
			@include Font-Regular($secondary-font, $base-font-size-24, $black-text, 700);
			line-height: 24px;
		}

		@import 'starsComponent';

		.review-container {
			margin-top: 14px;

			.stars {
				margin-top: 2px;
			}

			.review-content {
				margin-left: 4px;
			}
		}

		.info-container {
			gap: 4px;
			margin-top: 16px;
			text-transform: capitalize;

			@include hotel-address;

			.hotel-address {
				gap: 8px;

				.icon-container {
					background-color: $gray-1;
				}
			}

			.rooms {
				display: flex;
				align-items: center;
				gap: 10px;

				&::before {
					content: '';
					display: inline-block;
					background-size: 14px 14px;
					min-width: 14px;
					min-height: 14px;
					background-color: $gray-1;
					mask-image: url('../img/search_page/search_bar/user.svg');
					mask-repeat: no-repeat;
					mask-size: 14px 14px;
				}
			}

			.breakfast {
				position: relative;
				padding-left: 24px;

				.icon {
					position: absolute;
					top: 2px;
					left: -1px;
					width: 16px;
					height: 16px;
				}
			}
		}
	}

	.image-container {
		height: 150px;
		border-radius: 8px;
		background-repeat: no-repeat;
		background-position: center;
		flex: 1;

		.hotel-image {
			position: relative;
			max-width: 100%;
			height: 100%;
			background-image: url('../img/hotel_page/hotel_place_h@2x.png');
			background-color: #333;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 8px;
		}
	}
}
