@mixin light-gray-bg-rounded {
	background-color: rgba(246, 246, 246, 0.5);
	border-radius: 8px;
}

@mixin container-title-typography {
	font-family: 'Lato';
	font-size: 19px;
	font-style: normal;
	font-weight: 700;
	line-height: 25px;
	color: $black-text;
}

@mixin input-label {
	label {
		margin-left: 3px;
		font-size: 15px;
		font-weight: 400;
		line-height: 21px;
		color: $black-text;
	}
}

@mixin select {
	.MuiInputBase-root {
		height: 42px;
		width: 370px;
		font-size: 15px;
		font-weight: 400;
		line-height: 18px;
	}

	.MuiOutlinedInput-input {
		padding-left: 20px;
		font-size: 15px;
		color: $gray-2;
	}

	.MuiOutlinedInput-notchedOutline {
		border: 1px solid $gray-3;
	}
}
