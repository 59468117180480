@charset "UTF-8";

@import 'variables';
@import 'topBar';
@import 'main';
@import 'footerBar';
@import 'loading';
@import 'combtasBar';

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.fade-in {
	opacity: 0; /* make things invisible upon start */
	-webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
	-moz-animation: fadeIn ease-in 1;
	animation: fadeIn ease-in 1;

	-webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
	-moz-animation-fill-mode: forwards;
	animation-fill-mode: forwards;

	-webkit-animation-delay: 0.4s;
	-moz-animation-delay: 0.4s;
	animation-delay: 0.4s;

	-webkit-animation-duration: 0.2s;
	-moz-animation-duration: 0.2s;
	animation-duration: 0.2s;

	&.slow-animation {
		-webkit-animation-duration: 0.5s;
		-moz-animation-duration: 0.5s;
		animation-duration: 0.5s;
	}
}

.appGrid {
	background-color: $main-background-color;
	color: #333333;

	min-width: 1092px;
	min-height: 90.8vh;
	position: relative;

	.orange {
		background-color: rgb(255, 188, 0);
	}

	.gridRow {
		width: 100%;
		display: block;
	}

	#page-container {
		min-height: $inner-page-height;
		min-width: 1092px;

		// make nav-bar fixed
		padding-top: 75px; //63px;
	}
}
