@mixin hotel-address {
	.hotel-address {
		display: flex;
		gap: 5px;
		margin-top: 4px;
		font-size: 15px;
		line-height: 21px;
		color: $gray-1;

		.icon-container {
			margin-top: 3px;
			display: inline-block;
			vertical-align: middle;
			min-width: 16px;
			min-height: 16px;
			max-width: 16px;
			max-height: 16px;
			mask-size: 16px 16px;
			mask-image: url('../img/results_page/s_location.svg');
			mask-repeat: no-repeat;
			background-color: $black-text;
		}

		.full-address {
			text-transform: capitalize;
		}

		span.distance-description {
			margin-left: 4px;
			text-transform: lowercase;
		}
	}
}
