@import 'agentPricing';

$modal-paper-height: 640px;
$modal-height: calc(#{$modal-paper-height} - 2px);
$deals-height: 280px;

.basket-modal-paper {
	max-height: $modal-paper-height;
	margin-top: -72px;

	& > div {
		padding: 0 !important;
		max-height: calc(100% - 42px) !important;
		border: 0 !important;
	}
}

.dialog-paper {
	width: 50%;
	margin-left: 25%;
}

.basket-modal {
	//min-height: 480px;
	height: $modal-height;
	position: relative;
	overflow: hidden;

	.modal-title {
		position: relative;
		padding: 24px;
		padding-bottom: 0;

		span {
		}

		button.action.close-modal {
			position: absolute;
			top: 19px;
			right: 19px;
			color: #ccc;

			&:hover {
				color: #666666;
			}

			.evil-icon {
				width: 32px;
				height: 32px;
			}
		}
	}

	.modal-fields {
		margin-top: 10px;
		padding: 24px;
		padding-top: 0;
		padding-bottom: 0;

		.field {
			display: block;

			label {
				transform: scale(1) translate(0px, -24px) !important;
			}

			&.to {
				.field-input {
					height: 68px;
					overflow: auto;
				}

				input[type='text'] {
					font-size: 12px !important;
				}
			}

			&.remarks {
				//width: 600px;
			}

			&.send-to-combtas {
				display: inline-block;
				width: 140px;

				label {
					transform: none !important;
				}
			}

			&.combtas-id {
				display: inline-block;
			}
		}
	}

	.modal-deals {
		height: 100%;

		.empty-list {
			text-align: center;
			padding-top: 96px;

			span {
				display: block;
			}
		}

		.title {
			font-size: 14px;
			color: #999999;
			padding: 24px;
			padding-bottom: 0;
		}

		.deals {
			margin-top: 4px; //16px;
			height: 300px;
			overflow: auto;
			border-top: 1px solid #ccc;
			overflow-x: hidden;

			&.multiple {
				border-bottom: 1px solid #ccc;
			}

			&.no-integration {
				height: 349px;
			}

			.basket-deal {
				position: relative;
				background: #f8fbff;
				border-bottom: 1px solid #ccc;
				font-size: 14px;
				//height: 120px; //128px;
				//padding: 4px 8px 4px 24px;
				padding: 8px 24px 8px 24px;

				&.hovered {
					background: #f0f6ff;
				}

				&:last-of-type:not(:nth-of-type(-n + 2)) {
					border-bottom: 0; //1px solid #ccc;
				}

				.line {
					display: block;
					&:first-of-type {
						margin-bottom: 12px;
					}

					.field {
						display: inline-block;
						.field-name {
							display: block;
							font-size: 12px;
							color: #999999;
							height: 17px;
						}
						.field-value {
							display: block;
							height: 20px;

							@import 'travelPolicyIcon';

							.text-field {
								width: 100px !important;
							}

							.travel-policy-icon-button {
								margin: auto !important;
							}

							.arbi-icon {
								font-size: 13px !important;
							}
						}

						&.room-type {
							.field-value {
								& > span {
									text-transform: capitalize;

									display: inline-block;
									text-overflow: ellipsis;
									white-space: nowrap;
									overflow: hidden;
									max-width: 230px;
									max-height: 16px;
								}
							}
						}
						&.date {
							width: 96px;
						}
						&.score {
							width: 128px;
						}
					}
				}
				.hotel-name {
					text-transform: capitalize;
					font-size: 18px;
					display: block;
				}

				.hotel-name-stars-address-dates-room {
					display: inline-block;
					width: 67%;

					.hotel-name-stars {
						dispaly: block;

						// .hotel-name {
						//   text-transform: capitalize;
						//   font-size: 18px;
						//   display: inline-block;
						// }

						@import 'starsComponent';
						.stars {
							display: inline-block;
							margin-left: 4px;
						}
					}
					.hotel-address {
						text-transform: capitalize;

						span {
							display: block;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
							max-width: 420px;
						}
					}
				}

				.review-score-price-cancellation {
					display: inline-block;
					width: 33%;
					//margin-top: 16px;
					position: relative;

					.price {
						.field-value {
							display: flex;
							align-items: center;
						}
					}
				}

				a.action.remove-deal {
					position: absolute;
					right: 12px; //0;
					// top: 52px;
					top: 8px;
					color: #999;

					.evil-icon {
						width: 24px;
						height: 24px;
					}

					&:hover {
						color: #666;
					}
				}
			}
		}
	}

	.modal-actions {
		position: absolute;
		width: 100%;
		bottom: 0;
		padding: 0 24px 12px;
		background-color: #fff;

		&.busy {
			cursor: progress;
		}

		.busy {
			pointer-events: none;
			//cursor: not-allowed !important;
			opacity: 0.5;

			&:hover {
				color: inherit;
			}
		}

		.actions {
			padding-top: 6px;

			.action {
				margin-right: 24px;
				font-size: 12px;
				color: #999;

				&:hover {
					color: #666;
				}

				&.send {
					color: #666;

					&:hover {
						color: #333;
					}
				}

				&.close {
				}

				&.clear {
					padding-top: 2px;
				}
			}

			.bottom-right-corner {
				position: absolute;
				right: 0;

				&.busy-indicator {
					right: 24px;
					top: 24px;
				}
			}
		}

		.invalid-basket {
			font-size: 12px;
			color: red;
		}
	}
}
