.add-airfare-wizard-page {
	font-family: $secondary-font;
	font-weight: normal;
	max-width: 1080px;
	min-width: 760px;
	margin-left: auto;

	.title {
		padding: 24px 0;
		color: $gray-1;
		font-size: 24px;
		font-weight: 600;
	}

	.wizard {
		.airfare-flights {
			min-height: 195px;
			padding-top: 16px;

			&.busy {
				opacity: 0.5;
				cursor: progress;
				.add-airfare-flight {
					pointer-events: none;
				}
			}

			.add-airfare-flight {
				position: relative;
				margin-top: 24px;
				height: 65px;

				&:last-child {
					margin-bottom: 65px;
				}
				.flight-container {
					gap: 24px;

					.field {
						display: inline-block;
						vertical-align: bottom;

						&.flight-container {
							width: 48% !important;
						}

						&.departure-date {
							width: 24%;

							.MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd {
								display: none;
							}

							.MuiInputAdornment-root {
								position: absolute;
								right: 8px;
							}

							.MuiInputBase-root {
								height: 42px;
								min-width: 200px;

								legend {
									max-width: 0.01px; // don't show legend
								}
							}

							.MuiInputLabel-root {
								transform: translate(4px, -24px);
								font-size: 14px;
								color: $gray-2;
							}

							.MuiInputBase-inputAdornedEnd {
								color: $black-text;
								font-size: 15px;
							}
						}

						.MuiInputBase-root {
							height: 42px;
							min-width: 200px;

							legend {
								max-width: 0.01px; // don't show legend
							}
						}

						.MuiInputLabel-root {
							transform: translate(4px, -24px);
							font-size: 14px;
							color: $gray-2;
						}

						.MuiInputBase-inputAdornedEnd {
							color: $black-text;
							font-size: 15px;
						}
					}

					.remove-button {
						position: absolute;
						top: 10px;
						right: 0;
						border: 0;
						background-size: 23px;
						width: 23px;
						height: 23px;
						background-color: $gray-1;
						mask-repeat: no-repeat;
						mask-size: 23px;
						mask-image: url('../img/icons/trash_bin.svg');
						cursor: pointer;

						&:hover {
							background-color: $secondary-blue;
						}
					}
				}
			}
		}

		.footer {
			&.busy {
				opacity: 0.5;
				cursor: progress;
			}

			.add-another-airfare {
				color: $blue-2;
				line-height: 17px;
				gap: 10px;
				font-weight: 600;
				cursor: pointer;

				&:hover:not(.busy) {
					text-decoration: underline;

					a {
						background-color: $light-blue;
					}
				}

				&.busy {
					opacity: 0.5;
					cursor: progress;
				}
			}

			.airfare-cost {
				.MuiInputBase-root {
					height: 42px;

					legend {
						max-width: 0.01px; // don't show legend
					}
				}

				.MuiInputLabel-root {
					transform: translate(4px, -24px);
					font-size: 14px;
					color: $gray-2;
				}

				.MuiInputBase-inputAdornedEnd {
					color: $black-text;
					font-size: 15px;
				}

				.currency-symbol {
					.MuiTypography-root {
						margin-right: -4;
						font-size: 15px;
						color: $black-text;
					}
				}

				&.busy {
					opacity: 0.5;
					cursor: progress;
					.field-input {
						pointer-events: none;
					}
				}
			}
		}
	}

	.actions-bar {
		margin-top: 24px;

		.buttons {
			margin-left: auto;
			gap: 12px;
		}

		.error {
			color: $danger;
		}
	}
}
