// colors palette

$black-text: var(--general-colors-black);
$blue-text: #248bd0;
$green-text: #42731d;
$brown-text: #875610;

$gray-secondary: rgba(32, 36, 43, 0.7);
$gray-border: rgba(32, 36, 43, 0.24);
$gray-border-dark: #949494;
$gray-border-light: #e1e1e1;

$green-background: rgba(66, 115, 29, 0.12);
$blue-background: rgba(0, 32, 128, 0.12);

$blue-link: #63b2d8;
$blue-bright: #42a8e5;
$blue-mid: #357fcb;
$blue-dark: #2b66a2;
$green-bright: #33a931;
$green-dark: #279226;

$gray-color-light: #a9a9a9;
$gray-background-light: #f8f8f8;
$blue-bright-message: #f1fbff;

// redesign new colors
$new-green-text: #008f4b;
$new-brown-text: #dd8605;

$main-blue: #275180;
$secondary-blue: #356e82;
$light-blue: #d1f0ff;
$blue-2: #2d65a1;

$gray-1: var(--general-colors-gray-dark);
$gray-2: #919595;
$gray-3: #bcc0c0;
$gray-4: #d7dada;
$gray-5: var(--general-colors-gray-light);
$gray-6: #8d8f93;
$gray-7: #fafafa;

$light-gray: rgba(216, 216, 216, 0.102797);
$white: #ffffff;

$danger: #ff5353;
