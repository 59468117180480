@use 'sass:math';

@import 'colors';

$top-bar-height: 74px;
$footer-bar-height: 64px;
$bars-height-sum: calc(#{$top-bar-height} + #{$footer-bar-height});

// app height
// $inner-page-height: calc(100vh - 112px); // 112px is the sum of top and footer bars
$inner-page-height: calc(100vh - #{$bars-height-sum});

// fonts
$main-font: 'Raleway';
$secondary-font: 'Lato';

// general widths
$column-width: 24.255319149px;
$search-bar-columns: 45;

// colors
$arbi-dark-gray: #626262;
$arbi-dark-blue: #223344;

$button-blue: #285280;
$button-blue-hover: #3f5c80;

$green: rgba(42, 196, 91, 1);
$orange: rgba(243, 136, 74, 1);
$red: rgba(200, 50, 50, 1);

$arbi-selection-background-color: #fafcff;

//.Raleway-Regular {
//  font-family: 'Raleway' !important;
//  font-style: normal;
//  font-weight: 400;
//}

//@mixin Raleway-Regular-size($size) {
//  @extend .Raleway-Regular;
//  font-size: $size;//($size)px;
//}

@mixin Font-Regular($font, $size, $color, $weight: 400) {
	font-family: $font !important;
	font-style: normal;
	font-weight: $weight;
	font-size: $size;
	color: $color;
}

/**
* Fonts sizes
*/
$base-font-size-8: 8px;
$base-font-size-10: 10px;
$base-font-size-11: 11px;
$base-font-size-12: 12px;
$base-font-size-13: 13px;
$base-font-size-14: 14px;
$base-font-size-15: 15px;
$base-font-size-16: 16px;
$base-font-size-18: 18px;
$base-font-size-20: 20px;
$base-font-size-21: 21px;
$base-font-size-22: 22px;
$base-font-size-24: 24px;
$base-font-size-26: 26px;
$base-font-size-28: 28px;
$base-font-size-48: 48px;

////////////////////////////
// Search page 26.1 notes //
////////////////////////////

$personal-bar-background-color: #233143;
$personal-bar-font-family: $main-font;
$personal-bar-font-size: $base-font-size-14;
$personal-bar-font-color: #5b869b;
$personal-bar-border-color: rgba(91, 134, 155, 0.5);

$company-bar-background-color: #ffffff;
$company-bar-font-family: $main-font;
$company-bar-font-size: $base-font-size-16;
$company-bar-font-color: #000000;

$company-name-font-family: $main-font;
$company-name-font-size: $base-font-size-14;
$company-name-font-color: #000000;

$search-bar-top-margin: 39px;
$search-bar-background-height: 366px;
$search-bar-bottom-margin: 101px;

$search-bar-label-font-family: $main-font;
$search-bar-label-font-size: $base-font-size-14;
$search-bar-label-font-color: #35a8df;

$search-bar-input-font-family: $secondary-font;
$search-bar-input-font-size: $base-font-size-14;
$search-bar-input-font-color: white; //#898989;

$search-bar-button-main-color: #00d994; //#00C996
$search-bar-button-secondary-color: #00ae80;
$search-bar-button-font-color: #90ffe2;

$search-page-small-box-title-font-family: $main-font;
$search-page-small-box-title-font-size: $base-font-size-20;
$search-page-small-box-title-font-color: #0e4b94;

$search-page-small-box-description-font-family: $secondary-font;
$search-page-small-box-description-font-size: $base-font-size-14;
$search-page-small-box-description-font-color: #a6a8ab;
$search-page-small-box-description-line-height: $base-font-size-18;

$recent-search-component-title-font-family: $secondary-font;
$recent-search-component-title-font-size: $base-font-size-18;
$recent-search-component-title-font-color: #555555;

$recent-search-component-secondary-font-family: $secondary-font;
$recent-search-component-secondary-font-size: $base-font-size-14;
$recent-search-component-secondary-font-color: #a6a8ab;

$destination-component-title-font-family: $secondary-font;
$destination-component-title-font-size: $base-font-size-21;
$destination-component-title-font-color: $gray-5;

$destination-component-secondary-font-family: $secondary-font;
$destination-component-secondary-font-size: $base-font-size-15;
$destination-component-secondary-font-color: $gray-5;

$tips-box-title-font-family: $main-font;
$tips-box-title-font-size: 24px;
$tips-box-title-font-color: #0e4b94;

//$tips-box-subtitle-font: ?
//$tips-box-subtitle-font-color: ?

$tip-component-title-font-family: $main-font;
$tip-component-title-font-size: $base-font-size-20;
$tip-component-title-font-color: #0e4b94;

$tip-component-description-font-family: $secondary-font;
$tip-component-description-font-size: $base-font-size-14;
$tip-component-description-font-color: #a6a8ab;
$tip-component-description-line-height: $base-font-size-18;

//$font: Lato Regular;
//$font-size-titles: $base-font-size-16;
//$font-size-masses: $base-font-size-14;
//$font-color-search: #6D6F70;
//$font-color-hotel: #000000;
//$font-color-masses: #A5A7AA;

$border-gray: #ebebeb;
$lighter-gray: #a5a7aa;
$gray: #6d6f70;
$darker-gray: #59595c;
$black: #000000;
$white: #ffffff;

$top-bar-background-color: #6d6e70;
$top-bar-color: #bbbdbf;
$search-box-container-background-color: #e6e7e8;
$search-box-background-color: #f1f1f2;
$search-box-color: #58595b;
$search-box-border-color: #6f7071;
$main-background-color: $white;

//////////////////
// Results Page //
//////////////////

// Filters Component //
$filter-title-font-family: $secondary-font;
$filter-title-font-size: $base-font-size-15;
$filter-title-font-color: $black-text;
$filter-title-font-weight: 400;

$hotel-name-filter-description-font-family: $secondary-font;
$hotel-name-filter-description-font-size: $base-font-size-14;
$hotel-name-filter-description-font-color: #939393;
$hotel-name-filter-description-line-height: $base-font-size-18;

$check-list-filter-item-font-family: $secondary-font;
$check-list-filter-item-font-size: $base-font-size-14;
$check-list-filter-item-font-color: #223143;
$check-list-filter-item-line-height: 34px;

$filters-component-vertical-margin-between-filters: 22px;

// Search Component //
$search-component-input-font-family: $secondary-font;
$search-component-input-font-size: $base-font-size-16;
$search-component-input-font-color: #939393;

$search-component-width: 848px;
$search-component-height: 180px;
$search-component-top-padding: 52px;
$search-component-bottom-padding: 28px;
$search-component-horizontal-margin-between-inputs: 30px;
$search-component-vertical-margin-between-rows: 54px;

// Results Component //
$show-more-results-font-family: $secondary-font;
$show-more-results-font-size: $base-font-size-18;
$show-more-results-font-color: white; //#3F9DE9;

$results-component-sort-by-height: 52px;
$results-component-sort-by-bottom-margin: 14px;
$results-component-margin-between-results: 22px;

// Hotel Component //
$hotel-title-font-family: $secondary-font;
$hotel-title-font-size: $base-font-size-18;
$hotel-title-font-color: $black-text;

// TODO: update family+size+color
$hotel-address-font-family: $secondary-font;
$hotel-address-font-size: $base-font-size-15;
$hotel-address-font-color: $gray-2;

$hotel-reasons-font-family: $secondary-font;
$hotel-reasons-font-size: $base-font-size-14;
$hotel-reasons-font-color: #939393;
$hotel-reasons-line-height: 21px;

$hotel-review-score-family: $secondary-font;
$hotel-review-score-size: 28px;
$hotel-review-score-color: #223143;

$hotel-info-height: 74px;
$hotel-reasons-height: 106px;

// Deal Component //
$deal-nights-font-family: $secondary-font;
$deal-nights-font-size: $base-font-size-12; // TODO: verify size
$deal-nights-font-color: #223143;

$deal-room-font-family: $secondary-font;
$deal-room-font-size: $base-font-size-16;
$deal-room-font-color: #223143;

$deal-total-price-family: $secondary-font;
$deal-total-price-size: 32px;
$deal-total-price-color: #223143;

$deal-height: 114px;

////////////////
// HOTEL PAGE //
////////////////

$hotel-page-hotel-name-font-family: $secondary-font;
$hotel-page-hotel-name-font-size: 24px;
$hotel-page-hotel-name-font-color: $black-text;
$hotel-page-hotel-name-font-weight: 700;

$hotel-page-hotel-address-font-family: $secondary-font;
$hotel-page-hotel-address-font-size: $base-font-size-16;
$hotel-page-hotel-address-font-color: #35a8df;

$hotel-page-about-title-font-family: $secondary-font;
$hotel-page-about-title-font-size: $base-font-size-16;
$hotel-page-about-title-font-color: $gray-1;

$hotel-page-about-text-font-family: $secondary-font;
$hotel-page-about-text-font-size: $base-font-size-15;
$hotel-page-about-text-font-color: $gray-1;
$hotel-page-about-text-line-height: $base-font-size-21;

$hotel-page-title-bottom-margin: 26px;

$hotel-page-about-top-padding: 30px;
$hotel-page-about-bottom-margin: 20px;

// Carousel //
$large-carousel-zoomed-image-width: 848px;
$large-carousel-zoomed-image-height: 556px;

$medium-carousel-zoomed-image-width: 500px;
$medium-carousel-zoomed-image-height: 327px;

$carousel-zoomed-image-bottom-margin: 14px;
$carousel-bottom-margin: 20px;

// Search Strip //
$search-strip-back-to-results-font-family: $secondary-font;
$search-strip-back-to-results-font-size: 14.5px;
$search-strip-back-to-results-font-color: #223143;

$search-strip-search-terms-font-family: $secondary-font;
$search-strip-search-terms-font-size: 14.5px;
$search-strip-search-terms-font-color: #939393;

// Deal Component //
$hotel-page-deal-component-room-font-family: $secondary-font;
$hotel-page-deal-component-room-font-size: $base-font-size-16;
$hotel-page-deal-component-room-font-color: #223143;

$hotel-page-deal-component-height: 114px;
$hotel-page-deal-component-top-padding: 22px;
$hotel-page-deals-bottom-margin: 20px;

// Amenities Component //
$hotel-page-amenity-font-family: $secondary-font;
$hotel-page-amenity-font-size: $base-font-size-14;
$hotel-page-amenity-font-color: $gray-1;
$hotel-page-amenity-line-height: $base-font-size-18;

// Reviews Component //
$hotel-page-reviewer-name-font-family: $secondary-font;
$hotel-page-reviewer-name-font-size: $base-font-size-14;
$hotel-page-reviewer-name-font-color: #223143;
$hotel-page-reviewer-name-line-height: 16.8px;

$hotel-page-review-date-font-family: $secondary-font;
$hotel-page-review-date-font-size: $base-font-size-14;
$hotel-page-review-date-font-color: #a6a8ab;

$hotel-page-review-text-font-family: $secondary-font;
$hotel-page-review-text-font-size: $base-font-size-14;
$hotel-page-review-text-font-color: #a6a8ab;

////////////
// FOOTER //
////////////

$footer-bar-background-color: $personal-bar-background-color; // #DEDEDE;//808184;
$footer-bar-color: $gray-1;
$footer-bar-line-color: $white;

$footer-bar-navigation-font-family: $main-font;
$footer-bar-navigation-font-size: $base-font-size-13;

$footer-bar-disclaimer-font-family: $secondary-font; //$main-font;
$footer-bar-disclaimer-font-size: $base-font-size-13;
$footer-bar-disclaimer-font-color: #666667; //#707070;

$tag-color: #275180;
$tag-background: #d1f0ff;
//$e

//$search-font: $secondary-font 16px;
//$search-color: #6D6F70;
//
//$result-title-font: $secondary-font 16px;
//$result-title-color: #000000;
//
//$result-reasons-font: $secondary-font 14px;
//$result-reasons-color: #A5A7AA;
//
//$filter-item-font: $secondary-font 14px;
//$filter-item-color: #A5A7AA;
//
//$filter-title-font: $secondary-font 14px;
//$filter-title-color: #59595C;

// mixin + map-get

$width-main: 1140px; //1400px;
$width-result: 850px;
$width-result-img: 268px;
$width-column: math.div(24, 24.345);
$height-result-img: math.div(174, 175);

$text-green: #008f4b;
$text-red: #ff5353;
$text-orange: #dd8605;
$text-blue: #356e82;
