@mixin filters {
	.filters-container {
		width: 249px;

		.scroll-to-top {
			display: block;
			width: 100%;
			margin-bottom: 20px;
			text-align: center;
			transition: all 0.2s ease;
			color: #777;
			font-size: 32px;
		}
	}
}
