@charset "UTF-8";

@import 'accessibility';
@import 'searchPage';
@import 'resultsPage';
@import 'hotelPage';
@import 'reservationPage';
@import 'paymentPage';
@import 'bookingsPage';
@import 'requestsPage';
@import 'usersManagementPage';
@import 'travelPolicyPage';
@import 'reportsPage';
@import 'referralPage';
@import 'confirmationPage.scss';
//@import "settingsPage";
@import 'managementPage';
@import 'tripRequestPage.scss';
@import 'tripTablePage';
@import 'googleRelated';
@import 'imageCarousel';
@import 'reactDatesOverrides';
@import 'faqPage';

#page-container {
	//margin: 0 auto;
	//width: 100%;
	//height: 100%;
	//background-color: $white;
}

html,
body,
div#app-container {
	font-family: Lato;
}

.MuiDialog-container {
	min-width: 500px;
}

////////////////////////////////////////////////////////////////////////
////////////////////////// management patches //////////////////////////
////////////////////////////////////////////////////////////////////////
html,
body,
div#app-container {
	font-family: Lato !important;
	font-size: 14px !important;
}

div.hotel-reasons ul {
	-webkit-margin-before: -4px;
}
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
