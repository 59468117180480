@import 'variables';

.upgrade-plan-box {
	width: 100%;
	height: 100%;
	background: $white;
	border: 1px solid $border-gray;
	border-radius: 4px;
	padding-bottom: 16px;

	div.main {
		//float: left;
		display: block;
		width: 100%;
	}

	.title {
		//color: #0F54A4;
		@include Font-Regular(
			$search-page-small-box-title-font-family,
			$search-page-small-box-title-font-size,
			$search-page-small-box-title-font-color
		);
		margin: 16px 16px 0 16px;
	}

	hr {
		//margin: 0 5% 0 5%;
		margin: 8px 16px 8px 16px;
	}

	.description {
		//color: $border-gray;
		@include Font-Regular(
			$search-page-small-box-description-font-family,
			$search-page-small-box-description-font-size,
			$search-page-small-box-description-font-color
		);
		//margin: 0 24px 24px 24px;
		//padding: 0 24px 24px 24px;
		padding: 0 24px 10px 24px;
	}

	.upgrade-plan-content-container {
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
		position: relative;

		.features {
			width: 100%;
			margin-top: 19px; //39px;//16px; // TODO: base on description?
			margin-bottom: 24px;

			span {
				display: block;
				font-size: 16px;
				border: 1px solid #dcd9d9;
				border-bottom: none;
				//border-radius: 4px;
				//margin-bottom: 12px;
				padding: 8px;
				&:hover {
					color: #3ea8dc;
					transition: color 0.2s ease;
				}
				i.fa {
					color: #00abdb;
					margin-right: 8px;
				}
			}
			span:first-of-type {
				border-radius: 4px 4px 0 0;
			}
			span:last-of-type {
				border-radius: 0 0 4px 4px;
				border-bottom: 1px solid #dcd9d9 !important;
			}
		}

		.upgrade-action {
			width: 100%;
			text-align: right;

			button {
				width: 214px;

				i.fa {
					color: #ffffff;
				}
			}

			span.success {
				font-size: 15px;
				.small {
					font-size: 13px;
				}
				i.fa {
					color: #00ae80;
					margin: 3px;
				}
			}

			span.oops {
				font-size: 14px;

				i.fa {
				}
			}
		}
	}
}
