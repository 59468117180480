.requests-page {
	&.new-bookings-page
		.bookings-table-component
		.bookings-table-container
		table.bookings-table
		tbody
		tr.approval-actions {
		border-radius: 0 0 4px 4px;
		box-shadow: none;

		td {
			padding: 0;

			.approval-actions__content {
				font-size: 12px;
				padding: 4px 14px 6px;
				border-radius: 4px;
				display: flex;
				justify-content: space-between;
				background: rgba(53, 168, 223, 0.1); // #35A8DF

				&.expired,
				&.approved,
				&.rejected,
				&.declined {
					background: #f4f4f4;
				}
			}
		}
	}

	&.new-bookings-page .bookings-table-component .bookings-table-container table.bookings-table tbody tr.empty-row {
		height: 12px;
		box-shadow: none;
	}

	.approve-or-reject {
		.action.approve {
			color: $text-green;
		}
	}
}
