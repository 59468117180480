@import 'variables';

$actionMinWidth: 120px;
$actionsMinHeight: 60px;

.deals-wrapper {
	height: auto;
	position: relative;
	margin-top: 12px;
	background-color: $white;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid var(--desktop-deal-hover-border);
	}

	.deal {
		padding: 24px;

		&.loading {
			-webkit-filter: blur(2px) grayscale(40%); /* Chrome, Safari, Opera */
			filter: blur(2px) grayscale(40%);
			opacity: 0.5;
		}

		.deal-info {
			padding: 2px;
			min-height: 68.95px; // FIX

			&.expanded {
				min-height: 105px; // expanded for expedia
			}

			.deal-policy-features {
				flex: 1;
				padding-right: 30px;
				gap: 12px;

				.room-name {
					@include Font-Regular($secondary-font, $base-font-size-18, $black-text, 600);
					line-height: 25px;
					padding-right: 16px;
					text-transform: capitalize;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;

					&.superseded {
						font-style: italic;
						font-weight: 300;
						text-decoration: line-through;
						color: #888;
					}

					.clickable-room {
						color: $black-text;
						&:hover {
							cursor: pointer;
							text-decoration: underline;
						}
					}
				}

				.amenity {
					display: flex;
					gap: 8px;
					margin-top: auto;

					.icon {
						width: 20px;
						height: 20px;
						background-color: $gray-1;
						mask-repeat: no-repeat;
						-webkit-mask-repeat: no-repeat;
						mask-size: contain;
						mask-image: url('../img/check-circle.svg');
						-webkit-mask-image: url('../img/check-circle.svg');

						&.breakfast {
							mask-image: url('../img/amenities/breakfast.svg');
							-webkit-mask-image: url('../img/amenities/breakfast.svg');
						}
					}

					.arbi-icon.fa-ban {
						color: $gray-1;
					}

					.description {
						@include Font-Regular($secondary-font, $base-font-size-15, $gray-1);
						line-height: 21px;

						.highlighted {
							color: #008f4b;
							font-weight: 700;
							line-height: 18px;
						}
					}
				}

				.room-details {
					@include Font-Regular($secondary-font, $base-font-size-15, $secondary-blue);
					line-height: 21px;
					white-space: nowrap;
					cursor: pointer;

					&:hover {
						text-decoration: underline;
					}
				}
			}

			.deal-price-wrap {
				img.booking-logo {
					position: absolute;
					bottom: 2px;
					right: 64px;
					height: 12px;
				}
			}

			.deal-price-container {
				gap: 12px;

				.fixed-height {
					height: 35px;
				}

				.policy-average-wrapper {
					@include is-in-policy;

					.avg-room-night {
						@include Font-Regular($secondary-font, $base-font-size-13, $gray-1);
						line-height: 19px;
					}
				}

				div.old-price {
					font-size: 17px;
					text-decoration: line-through;
					color: #666666;
					margin-right: 2px;
					padding-top: 13px;
				}

				.price-per-night {
					position: relative;
					@include Font-Regular($secondary-font, $base-font-size-24, $black-text, 700);
					line-height: 29px;
					text-align: end;

					span.optimized {
						color: $green-text;
					}

					span.old-price {
						color: $black-text;
						font-size: 12px;
						text-decoration: line-through;
					}

					span.fault-deal {
						color: #888;
						// text-decoration: line-through;
					}

					// patch
					& > div > span {
						display: block;
						width: 100%;
					}
				}

				.deal-price {
					position: relative;

					.badge-position {
						position: absolute;
						top: -18px;
						right: 0;
						min-width: 70px;
						text-wrap: nowrap;

						&.points-applied {
							width: 74px;
						}
					}

					.price {
						font-weight: 600;
					}
				}

				.tax-not-included {
					padding-left: 8px;
				}

				.tax-included {
					cursor: default;
					padding-left: 8px;

					&:hover > span {
						text-decoration: underline;
					}

					.tax-included-info {
						display: inline-block;
						font-size: 3px;
						vertical-align: top;
					}
				}

				.tax-included {
					@include Font-Regular($secondary-font, $base-font-size-13, $gray-1);
					line-height: 19px;

					.tooltip-icon {
						background: url('../img/icons/tooltip.svg') right center no-repeat;
						background-size: 13px 13px;
						padding-right: 18px;
					}

					.tooltip-icon:hover {
						color: $black-text;
						cursor: pointer;
					}
				}
			}
		}
	}

	.deal.single {
		width: 100%;
		align-items: flex-start;
	}

	.deal-actions {
		margin-top: 12px;
		position: relative;

		width: fit-content;
		margin-left: auto;

		.deal-action {
			width: auto;
			margin-left: auto;

			&.share-action {
				position: absolute;
				left: -130px;
				top: 12px;
				font-weight: 600;
				color: var(--desktop-book-action-share-button);
			}
		}
	}

	.deal.multi {
		width: 41%;
	}

	.deal.multi:first-of-type {
		border-right: 1px solid lightgrey;
	}

	.checking-availability {
		min-width: $actionMinWidth;
		min-height: $actionsMinHeight;
		padding-top: 18px;
		text-align: center;
		align-self: flex-end;

		span {
			display: block;
		}
	}

	.deal-action {
		min-width: $actionMinWidth;
		text-align: center;

		&.select-action {
			margin-left: 10px;
		}

		////////////////////////////////////////////////////////////////////////////////
		// TRAVEL POLICY (RESULTS PAGE)
		////////////////////////////////////////////////////////////////////////////////

		.book-btn {
			width: 143px;
			height: 41px;
			font-size: 14px;
			box-shadow: none;
			background: var(--desktop-book-action-background);
			color: var(--desktop-book-action-color);
		}

		input.btn,
		button,
		a.btn,
		span.btn {
			width: 80%;
			text-transform: capitalize;
			height: 32px;
			//margin-bottom: 72px;

			&.block-reserving {
				width: 85%;
				padding: 6px;
			}

			&.disabled {
				color: #fff !important;

				&:hover {
					background: linear-gradient(to bottom, #3da9dd 0, #144d92 100%) !important;
				}
			}
		}
	}
}

.link {
	cursor: pointer;
}

.shallow-wrapper {
	padding: 10px;
	height: 108px;

	.shallow {
		display: inline-block;
		width: 82%;
		text-align: center;
		height: 100%;

		span {
			display: inline-block;
			//padding-left: 10px;
			padding-top: 35px;

			font-size: 16px;
			font-weight: 500;
		}

		span.message {
			padding-top: 16px;
		}
	}
	.shallow-action {
		//margin-top: 5px;

		display: inline-block;
		width: 18%;
		text-align: center;
		vertical-align: top;

		height: 100%;

		padding-top: 30px;

		input.btn,
		button,
		a.btn,
		span.btn {
			//right: 10px;
			//position: absolute;
			//margin-top: 42px;
			width: 80%;
			text-transform: capitalize;
			height: 32px;
		}
	}
}

.tooltip {
	&.result-deal-policy-tooltip {
	}

	&.hotel-deal-policy-tooltip {
	}

	.tooltip-inner {
		.policy-tooltip-container {
			text-align: left;

			padding: 5px;

			span {
				display: block;
				color: #000;
			}
		}
	}
}

.tax-included-tooltip-container,
.book-on-booking-tooltip-container,
.estimated-price-tooltip-container {
	color: #4a4a4a;
}

.agent-tooltip {
	.agent-tooltip-item {
		display: flex;
		justify-content: space-between;
		height: 24px;

		> div {
			align-self: center;
			font-size: 12px !important;
		}
	}
	.agent-tooltip-title {
		width: 72px;
		margin-right: 5px;

		&:after {
			content: ':';
		}
	}

	.agent-tooltip-value {
		width: 72px;
		text-align: right;

		&.multiple-currencies {
			width: 144px;
		}
	}

	&.dynamic {
		.agent-tooltip-title {
			width: 190px;
		}
		.agent-tooltip-value {
			width: 60px;
		}
	}
}
