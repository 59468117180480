.icon {
	display: inline-block;
	width: 13px;
	height: 13px;
	//margin-left: 4px;

	&.in-policy {
		background: url('../img/results_page/In_Policy.svg') left center no-repeat;
		background-size: 13px 13px;
	}

	&.out-of-policy {
		background: url('../img/results_page/Out_of_Policy_Ex.svg') left center no-repeat;
		background-size: 13px 13px;
	}
}
