@import 'variables';
@import '../../node_modules/react-image-gallery/styles/scss/image-gallery';

.carousel {
	display: block;
	width: $large-carousel-zoomed-image-width;
	// margin-bottom: $carousel-bottom-margin;
	margin-bottom: 0;

	////////////////////////////////
	// IMAGE GALLERY REPLACEMENTS //
	////////////////////////////////

	.image-gallery-image {
		//background: black;
		// border-radius: 4px 4px 0 0; // including thumbnails
		border-radius: 4px; // without thumbnails
		width: $large-carousel-zoomed-image-width;
		height: $large-carousel-zoomed-image-height;

		// border: 1px solid #ccc;
		// border-bottom: 0;
		// border-top: 0;

		background-color: black;
		background-size: auto 556px !important;
		background-repeat: no-repeat;
		background-position: center;

		background-size: cover !important;

		img {
			height: $large-carousel-zoomed-image-height;

			display: block;
			width: auto !important;
			margin: 0 auto;
		}
	}

	.image-gallery-slides {
		// border: 1px solid $border-gray;
		// border-radius: 4px 4px 0 0; // including thumbnails
		border-radius: 8px; // without thumbnails

		.image-gallery-slide {
			.image-gallery-description {
				left: 1px !important;
			}
		}
	}

	// uncomment if thumbnails are enabled
	.image-gallery-thumbnails {
		// border: 1px solid #EBEBEB;
		// border-top: 0;
		// border-radius: 0 0 4px 4px;

		// padding: 8px;

		.image-gallery-thumbnail.gallery-thumbnail {
			width: 122px;
			margin-left: 0;

			.image-gallery-thumbnail-label {
				z-index: -1;
				width: 122px;
			}

			& > div > div {
				background-color: black;
				// background-size: auto 72px !important;
				background-repeat: no-repeat;
				background-position: center;

				background-size: cover;
				border: 1px solid $border-gray;
				border-radius: 4px;
			}

			&.active {
				border-radius: 4px;

				& > div > div {
					width: 114px !important;
					margin: 0 !important;
					padding: 0 !important;
					border: 0 !important;
					border-radius: 0 !important;
				}
			}
		}
	}

	&.medium {
		// width: $medium-carousel-zoomed-image-width;
		width: 100%;

		.image-gallery-image {
			// width: $medium-carousel-zoomed-image-width;
			width: 100%;
			// height: $medium-carousel-zoomed-image-height;
			height: 400px;

			img {
				height: $medium-carousel-zoomed-image-height;
			}
		}
	}
}
