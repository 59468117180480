@import 'colors';

.cancellation-terms-container {
	@include light-gray-bg-rounded;
	margin-bottom: 32px;
	padding: 16px 32px;

	.title {
		font-size: 15px;
		line-height: 18px;
		font-weight: 500;
		color: $black-text;
		margin-bottom: 8px;
	}

	.cancellation-term {
		font-size: 13px;
		line-height: 20px;
		color: $gray-1;
	}
}
