@use 'sass:math';

@import 'variables';
@import './mixins/reservationMixins';
@import './mixins/sharedMixin';
@import 'colors';
@import 'paymentComponent';
@import 'redeemPoints';
@import 'hotelDetailsContainer';
@import 'dealDetailsContainer';
@import 'dealDetailsContainerBreakTaxes';
@import 'cancellationTermsContainer';
@import 'travelers';
@import 'agentPricing';
@import 'reservationDialog';

.MuiDialog-root.special-request-dialog {
	.special-request-dialog-description {
		color: $black-text;
		padding-bottom: 4px;
	}

	.dialog-option .MuiInputBase-root {
		width: 100%;

		&.selected .MuiSelect-select {
			color: $black-text;
		}
	}

	.MuiDialogActions-spacing {
		padding: 32px 24px 24px 0;

		.action-button {
			width: 128px;
			margin-left: 16px;
			border: none;
			background-image: none;
			outline: none;
			box-shadow: none;
			padding: 12px 18px;
			font-weight: 700;
			color: #fff;

			&.grey {
				background: $gray-5;
				color: $main-blue;

				&:hover {
					background: $gray-4 !important;
					color: $main-blue;
				}
			}

			&:hover {
				background: #0f2742 !important;
				color: #fff;
			}
		}
	}
}

.reservation-page {
	width: 1092px;
	height: 100%;
	margin: 0 auto;
	padding: 10px 0 64px;

	.back-to-hotel {
		@include back-to-button;
	}

	.reservation-container {
		display: flex;
		gap: 23px;
		margin: 0 auto;

		&.top-container {
			margin-top: 10px;
		}

		.reservation-main {
			width: 825px;
			display: inline-block;
			vertical-align: top;

			.MuiInputBase-root.selected .MuiSelect-select {
				color: $black-text;
			}
		}
	}

	.reservation-side {
		width: 266px;
	}

	.loading {
		text-align: center;
		margin-top: 100px;
	}

	.quote-button {
		margin-right: 10px;
	}

	.management-trip-container {
		margin: 0;

		label {
			font-size: $base-font-size-16;
			margin-left: 5px;
		}
	}

	.inputs-container {
		gap: 32px;
		margin-bottom: 32px;

		@include input-label;

		input {
			height: 42px;

			&:focus {
				border-color: $main-blue;
				box-shadow: none;
			}
		}

		@include select;

		&.trip-id {
			padding: 32px;
			@include light-gray-bg-rounded;
		}
	}

	.maximum-points-earned {
		width: 100%;
		height: 112px;
		position: relative;
		margin-bottom: 32px;

		.maximum-points-earned-title {
			position: absolute;
			// width: 54px;
			// height: 24px;
			left: 13px;
			top: 0;
			@include container-title-typography;
		}

		.maximum-points-earned-inner {
			position: absolute;
			width: 100%; //855px;
			height: 80px;
			left: 0px;
			top: 32px;

			background: linear-gradient(99.53deg, #f2f9fd 4.71%, #cde3f1 96.18%);
			// opacity: 0.6;
			border-radius: 8px;

			display: flex;
			align-items: center;

			// padding: 24px;

			.maximum-points-earned-icon {
				display: flex;
				align-items: center;
				width: 40px;
				height: 40px;
				margin-left: 25px;

				.maximum-points-earned-icon-inner {
					width: 36px;
					height: 36px;
					background-color: #d1f0ff; // Light Blue
					border-radius: 48px;
					// position: absolute;
					// right: 0;
					// bottom: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.maximum-points-earned-text {
				display: flex;
				align-items: center;

				// width: 170px;
				height: 42px;

				margin-left: 13px;

				font-family: 'Lato';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 21px;
				/* or 140% */

				/* Black */
				color: $black-text;
			}

			.maximum-points-earned-content-loader {
				margin-left: 13px;
			}

			.maximum-points-earned-points {
				margin-left: 13px;
				display: flex;
				align-items: center;

				box-sizing: border-box;

				// width: 120px;
				height: 44px;

				/* White */
				background: #ffffff;
				// opacity: 0.6;
				/* Light Blue */
				border: 1px solid #d1f0ff;
				border-radius: 8px;

				padding: 9px;

				.maximum-points-earned-points-value {
					font-family: 'Lato';
					font-style: normal;
					font-weight: 600;
					font-size: 28px;
					line-height: 34px;
					/* identical to box height */

					/* Black */
					color: $black-text;
				}

				.maximum-points-earned-points-suffix {
					// display: flex;
					align-self: flex-end;
					margin-left: 9px;

					font-family: 'Lato';
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					// line-height: 21px;
					line-height: 15px;

					/* Black */
					color: $black-text;
				}

				.maximum-points-earned-disclaimer {
					margin-top: 10px;
					margin-left: 8px;
				}
			}
		}
	}

	#client_trip_id,
	#combtas_id,
	#travel_booster_docket_number,
	#price_comparable {
		width: 240px;
	}

	.travel-booster-docket-number-container {
		display: block;

		#travel-booster-docket-number.error {
			border: 1px solid red;
		}

		& > div {
			display: block;

			.tb-label {
				font-size: 14px;
				margin-left: 0;

				.tb-checkbox {
					padding-left: 0;
				}
			}
		}
	}

	.custom-fields-container {
		@include light-gray-bg-rounded;
		@include input-label;

		.custom-fields-block {
			padding: 16px 32px;
			width: 100%;

			&.no-top-padding {
				padding-top: 0;
			}
		}
	}

	.special-requests {
		@include light-gray-bg-rounded;
		@include Font-Regular($secondary-font, 13px, $black-text);
		padding: 16px 32px;
		margin-bottom: 32px;

		.title {
			font-size: 16px;
			font-weight: 800;
			line-height: 25px;
		}

		.checkbox-container {
			.MuiFormControlLabel-label {
				@include Font-Regular($secondary-font, 15px, $gray-1);
				font-weight: 700;
				line-height: 18px;
			}

			.MuiCheckbox-root {
				padding: 0;
				margin: 9px;
			}
		}

		.disclaimer {
			font-weight: 400;
			line-height: 19px;
			margin-bottom: 16px;
		}

		.subtitle {
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			margin: 0px 0px 4px 4px;

			&.disabled {
				color: $gray-2;
			}
		}

		.textarea-container {
			margin-bottom: 44px;

			.textarea-wrap {
				position: relative;

				textarea {
					overflow: auto;
					border: 1px solid #ccc;
					resize: none;
					height: 94px;
					width: 100%;
					border-radius: 4px;
					padding: 10px;
					@include Font-Regular($secondary-font, 13px, $gray-1);

					&:focus {
						outline: none;
						border: 1px solid var(--general-input);
					}

					&.expanded {
						height: 151px;
						scroll-padding-bottom: 57px;
						padding: 10px 10px 57px;
					}
				}

				.tags-wrap {
					position: absolute;
					bottom: 5.5px;
					right: 1px;
					width: calc(100% - 2px);
					padding: 10px;
					border-radius: 4px;
					background-color: white;
					display: flex;
					gap: 10px;

					.tag {
						color: $gray-1;
						padding: 6px 8px;
						background-color: $light-blue;
						width: fit-content;
						border-radius: 5px;
						display: flex;
						align-items: center;

						.clear-selection-button {
							margin-left: 10px;
							padding: 2px;

							&:hover {
								cursor: pointer;
							}
						}
					}
				}
			}
		}

		.select-container {
			margin-bottom: 28px;

			.MuiInputBase-root {
				&:hover {
					border-color: var(--general-input);
				}

				&.Mui-focused {
					border-color: var(--general-input);
				}

				&.Mui-disabled {
					pointer-events: none;
				}
			}
		}
	}

	.terms-of-supplier {
		@include light-gray-bg-rounded;
		padding: 16px 32px;
		margin-bottom: 32px;

		.title {
			@include Font-Regular($secondary-font, 16px, $black-text);
			font-weight: 800;
			line-height: 25px;
			margin-bottom: 10px;
		}

		.terms-wrap {
			@include Font-Regular($secondary-font, 13px, $gray-1);
			font-weight: 400;
			line-height: 19px;
			white-space: pre-line;

			.terms {
				margin-right: 2px;
			}
		}

		.text-button {
			@include Font-Regular($secondary-font, 13px, var(--general-link));
			font-weight: 400;
			line-height: 19px;
			background: none;
			border: none;
			padding: 0;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}

		::-webkit-scrollbar {
			height: 8px;
			width: 8px;
		}

		::-webkit-scrollbar-track {
			background-color: #f5f5f5;
			border-radius: 4px;
		}

		::-webkit-scrollbar-thumb {
			background-color: #888;
			border-radius: 4px;
		}

		::-webkit-scrollbar-thumb:hover {
			background-color: #555;
		}

		/* For Firefox */
		scrollbar-width: thin;
		scrollbar-color: #888 #f5f5f5;
		/* thumb and track color */
	}

	.redeem-arbibank-balance-container {
		.title {
			@include container-title-typography;
			margin-left: 12px;
		}

		.redeem-arbibank-balance-content {
			gap: 36px;
			height: 210px;
			padding: 32px;
			margin-top: 12px;
			@include light-gray-bg-rounded;
			color: $black-text;

			.summary {
				gap: 16px;
				width: 50%;
				height: 100%;

				.redeem-line {
					height: 18px;
					font-size: $base-font-size-14;
					font-weight: normal;

					&.total-for-payment {
						font-size: 15px;
						font-weight: bold;
					}

					&.reservation-points {
						margin-top: auto;
					}

					.price-title {
						display: inline-block;
						width: 200px;
					}

					.price-value {
						display: inline-block;
					}

					.checkbox-container {
						display: inline-block;
						margin-left: 16px;
						height: 23px;

						label {
							@include Font-Regular(
								$check-list-filter-item-font-family,
								$check-list-filter-item-font-size,
								$check-list-filter-item-font-color
							);
						}
					}
				}
			}

			.redeem-balance {
				height: 100%;
				width: 50%;
				position: relative;

				@import '_bankBalanceComponent';

				& > button {
					width: 183px;
					height: 42px;
					margin-top: 23px;
					@include Font-Regular($secondary-font, 15px, #fff);
					background-color: $main-blue;
					line-height: 18px;
					outline: none;

					&:hover {
						box-shadow: none;
						background-color: #3f5c80;
					}
				}

				.redeemed {
					margin-top: 5px;

					.summary {
						justify-content: center;
						width: initial;
						height: initial;
					}

					button {
						outline: none;
					}
				}
			}
		}
	}

	.disclaimer-container {
		margin-bottom: 32px;

		.title {
			@include container-title-typography;
			margin-left: 12px;
		}

		.disclaimer-content {
			padding: 24px 42px;
			margin-top: 12px;
			@include light-gray-bg-rounded;
			@include Font-Regular($secondary-font, 13px, $gray-2);
			line-height: 19px;
		}
	}

	.cost-center-container {
		margin-right: 15px;
		width: 320px;
		display: inline-block;

		label {
			margin-right: 12px;
			font-size: $base-font-size-14;
			font-weight: normal;
		}

		& > div {
			width: 220px !important;

			div,
			input {
				font-size: $base-font-size-14 !important;
			}
		}
	}

	.charge-currency-container {
		margin-bottom: 16px;
		margin-top: 16px;

		label {
			display: inline-block;
			margin-right: 16px;
		}

		.select-container {
			background: white;
			border-radius: 4px;
			width: 145px;
			display: inline-block;

			select,
			ul {
				appearance: none;
				-moz-appearance: none;
				-webkit-appearance: none;
				background: url('../img/search_page/search_bar/arrow_drop.png') no-repeat 115px;
			}

			select:focus,
			ul:focus,
			input:focus {
				outline: none;
			}
		}
	}

	.invoice-options-container {
		margin-bottom: 32px;

		.title {
			@include container-title-typography;
			margin-left: 12px;
		}

		.invoice-options-content {
			margin-top: 12px;
			padding: 32px;
			@include light-gray-bg-rounded;

			@include select;

			@include input-label;
		}
	}

	.go-to-payment-container {
		margin-bottom: 32px;
	}

	.booking-error-message {
		padding: 12px;
		background-color: #ffebeb;
		color: $danger;
		font-size: 15px;
		margin-bottom: 32px;
		border-radius: 8px;

		.icon {
			margin-right: 8px;
			width: 16px;
			height: 16px;
			top: 3px;

			path {
				fill: $danger;
				stroke-width: 10px;
			}
		}

		.error-action {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.final-approval-make-reservation {
		.make-reservation {
			.quoting-button {
				margin-right: 15px;
			}

			.request-this-room {
				border-radius: 4px;
				background-color: $main-blue;
				color: #fff;
				outline: none;

				&:hover {
					box-shadow: none;
					background-color: #3f5c80;
					color: #fff;
				}
			}
		}

		.out-of-policy-request-container {
			.title {
				@include container-title-typography;
				margin-left: 12px;
			}

			.out-of-policy-request-content {
				@include light-gray-bg-rounded;
				padding: 16px 32px 32px;
				margin-bottom: 32px;

				.out-of-policy-request {
					color: $gray-2;

					.out-of-policy-request-explanation {
						margin-top: 8px;
						font-size: 13px;
						line-height: 19px;

						.out-of-policy-message {
							font-style: italic;
							font-weight: 600;
						}
					}

					.out-of-policy-request-reason {
						height: 83px;
						margin-top: 16px;
						border: 1px solid $gray-4;
						border-radius: 4px;

						.text-box {
							width: 100%;
							resize: none;
							overflow: auto;
							padding: 10px;
							height: 100%;
							border: none;
							border-radius: 4px;
							font-size: 15px;

							&.error {
								border: 2px solid red !important;
							}
						}
					}
				}
			}
		}
	}
}

.reservation-modal {
	margin-bottom: 20px;
	text-align: center;

	h2 {
		margin-bottom: 20px;
	}

	.reservation-modal-content {
		margin-bottom: 20px;
		text-align: center;

		span {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	.non-refundable-modal-actions {
		button {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	button {
		margin-bottom: 20px;
	}
}

.dialog-option {
	@include light-gray-bg-rounded;

	@include select;

	@include input-label;
}

i.fa.fa-spin.fa-spinner {
	margin-left: 10px;
	vertical-align: middle;
	color: #555;
}
