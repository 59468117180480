///
/// Used for both TipsBox & TipComponent
///

@import 'variables';

.tips-box {
	display: block;
	width: 100%;
	height: 100%;
	background-color: $white;
	border: 1px solid $border-gray;
	border-radius: 3px;
	clear: both;

	.tips-header {
		display: block;
		margin: 0 auto;
		margin-top: 36px;
		width: 100%;
		text-align: center;

		.tips-title {
			display: block;
			width: 100%;
			margin: 0 auto;

			hr {
				margin-left: 24px; //auto;
				margin-right: 24px; //auto;
				color: $border-gray;
				width: 30%;
				display: inline-block;
			}

			hr.left {
				float: left;
			}

			hr.right {
				float: right;
			}

			.title-text {
				display: inline-block;

				@include Font-Regular(
					$tips-box-title-font-family,
					$tips-box-title-font-size,
					$tips-box-title-font-color
				);
			}
		}

		.tips-description {
			width: 100%;
			margin: 0 auto;
			display: block;
			@include Font-Regular(
				// TODO: ASK NOAM!
				'Raleway',
				18px,
				#898989
			);
		}
	}

	.tips-components-container {
		width: 100%;
		height: 100%;
		display: block;
		color: $lighter-gray;
		text-align: center;
		//display:         flex;
		//-webkit-align-items: center;
		//align-items: center;
		//-webkit-justify-content: center;
		//justify-content: center;

		//.tips-row {
		//  display: block;
		//  position: relative;

		///
		/// TipComponenet
		///
		.tipComponent {
			width: 34%; //27%;
			height: 226px;
			display: inline-block;
			vertical-align: top;
			margin: 2%;

			.tip-div {
				width: 100%;
				display: block;
			}

			.tip-icon {
			}

			.tip-title {
				@include Font-Regular(
					$tip-component-title-font-family,
					$tip-component-title-font-size,
					$tip-component-title-font-color
				);
			}

			.tip-tip {
				@include Font-Regular(
					$tip-component-description-font-family,
					$tip-component-description-font-size,
					$tip-component-description-font-color
				);
				line-height: $tip-component-description-line-height;
			}
		}
		//}
	}
}
