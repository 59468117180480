@import 'variables';
@import './mixins/reservationMixins';

.payment-component {
	min-height: 227px;

	h4.title {
		@include container-title-typography;
		margin-left: 12px;
	}

	.payment-details-container {
		h2 {
			margin: 0;
			padding: 0;
			margin-left: -2px;
		}

		.payment-method-container {
			background-color: white;

			.payment-method-container-inner {
				padding: 32px;
				@include light-gray-bg-rounded;

				&.payment-inputs {
					margin-bottom: 32px;
				}

				.title {
					display: inline-block;
					margin-right: 14px;
				}

				.methods {
					display: inline-block;

					.method {
						display: block;
						margin-right: 10px;

						input {
							display: inline-block;
							margin-right: 4px;
						}

						label.payment-method-disabled {
							cursor: not-allowed;
							color: #ababab;
							font-weight: 400;
						}

						label {
							display: inline-block;
						}
					}
				}

				.book-now-pay-later-disclaimer {
					font-family: 'Lato';
					font-size: 15px;
					line-height: 18px;
					color: #ff5353;

					span.payment-deadline-date {
						font-weight: 700;
					}
				}

				.payment-method {
					height: auto;

					.no-payment-message {
						font-size: 13px;
						color: $gray-2;

						a {
							color: $main-blue;
						}
					}
				}

				.charge-currency-container {
					label {
						display: block;
						font-weight: 400;
						margin-left: 4px;
					}

					div.select-container {
						.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
							padding: 12px;
						}

						.MuiSelect-icon {
							color: #000;
							margin-top: 2px;
							margin-right: 7px;
						}

						.MuiInput-underline::before,
						.MuiInput-underline::after {
							display: none;
						}
					}
				}

				.company-policy-component,
				.disclaimer {
					font-size: 13px;
					color: $gray-2;

					p {
						margin: 0;
					}
				}

				.disclaimer {
					a {
						color: var(--general-link);
					}
				}

				.pay-at-hotel-component {
					color: $gray-2;
				}

				.company-policy-component,
				.pay-at-hotel-component {
					.content {
						padding-top: 5px;
					}

					.button-container {
						margin-top: 10px;
					}
				}

				.credit-card-component {
					&.pci {
						position: relative;

						.processing-payment {
							font-size: 15px;
							font-weight: 700;
							color: $gray-1;
							height: 60px;
							padding: 20px;
							text-align: center;
						}
					}

					.credit-card-charge-date {
						padding-top: 5px;
						color: #3ea8dc;
						font-size: 18px;
						font-weight: bold;
						text-align: center;
					}

					.iframe-container {
						display: inline-block;
						width: 100%;
						height: 658px;
						text-align: center;

						&.htz {
							height: 900px;
						}

						&.cg {
							height: 345px;
							text-align: center;
						}

						&.ci {
							height: 430px;
							text-align: center;
						}

						&.pci {
							height: 356px;
							text-align: center;
						}

						&.threeDS {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 0;
							right: 0;
							height: 328px;
						}

						&.threeDS-hidden {
							height: 0;
							width: 0;
						}

						iframe {
							margin-top: 15px;
						}
					}

					.info-container {
						display: inline-block;
						width: 100%;
					}
				}

				.payment-action {
					margin-top: 15px;

					.btn.flat {
						border: none;
						background-image: none;
						background: var(--general-primary-button-background);
						color: var(--general-primary-button-color);
						height: 37px;
						width: 148px;
						outline: none;

						&:not(.fault-deal):hover {
							background: var(--general-primary-button-hover);
						}

						&:focus-visible {
							outline: $color-outline-tab 2px auto;
						}
					}
				}
			}
		}
	}
}
