@import './mixins/_bookingPageMixins';

.bookings-advanced-filters-popover {
	margin-top: 8px;

	.bookings-advanced-filters {
		width: 710px;
		padding: 24px;

		&__filter-group {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 32px;
		}

		&__title {
			font-weight: 700;
			font-size: 21px;
		}

		&__filter {
			display: flex;
			flex-direction: column;
			margin-top: 16px;

			label {
				font-size: 15px;
				font-weight: 400;
				display: flex;
				align-items: center;

				svg {
					height: 18px;
					margin-right: 6px;
				}
			}

			& .MuiInputBase-root {
				padding-right: 0;

				.MuiInputBase-input {
					padding-top: 11px;
					padding-bottom: 11px;
				}

				legend {
					max-width: 0.01px; // don't show legend
				}
			}

			&__row {
				display: flex;
				gap: 16px;

				& .MuiInputLabel-root {
					transform: translate(12px, 10px);

					&.MuiInputLabel-shrink {
						display: none;
					}
				}
			}

			&__info {
				display: flex;
			}
		}

		&__actions {
			display: flex;
			justify-content: flex-end;
			gap: 16px;
			margin-top: 24px;
		}

		&__action-button {
			background: var(--general-primary-button-background);
			color: var(--general-primary-button-color);
			border-radius: 4px;
			padding: 12px 18px;
			width: 120px;
			height: 41px;
			font-size: 14px;

			&:hover {
				background: var(--general-primary-button-hover);
			}
		}

		&__action-button_clear {
			@extend .bookings-advanced-filters__action-button;
			background: $gray-5;
			color: $gray-1;

			&:hover {
				background: $gray-5;
			}
		}

		@include outlined-input-styles($main-blue);
	}
}
