@import 'colors';

.retroactive-points-usage-title {
	display: flex;
	border-bottom: 1px solid $gray-2;
	padding-bottom: 16px;

	.title-text {
		font-size: 24px;
		font-weight: 700;
	}

	.title-logo {
		margin-top: 8.5px;
		height: 28px;

		img {
			// width: 180px;
			width: 120px;
		}
	}

	.close-button {
		// height: 28px;
		margin-right: -16px;
		margin-left: auto;
	}
}

.retroactive-points-usage {
	width: 761px;
	// position: relative;

	hr {
		border-color: $gray-2;
		margin-top: 16px;
		margin-bottom: 16px;
	}

	.x-button {
		position: absolute;
		top: 13.5px;
		right: 0;

		color: #888;
	}

	.retroactive-actions {
		display: flex;
		margin-right: -12px;

		.status-container {
			align-self: center;

			span.success {
				color: $green-bright;
			}

			span.failed {
				color: $danger;
			}
		}

		.busy-container {
			align-self: center;
			margin-right: 16px;
		}

		// .cancel-container {
		//     margin-left: auto;
		// }

		.update-container {
			margin-left: auto;

			button {
				text-transform: none;
			}
		}
	}

	// .reservation-summary {
	//     // .reservation-summary-title {
	//     //     background-color: white;
	//     // }

	//     .column-item {
	//         width: 50%;
	//         display: inline-block;

	//         &.full-width {
	//             height: 80px;
	//             width: 100%;

	//             span.reservation-summary-item-value {
	//                 position: relative;
	//             }

	//             .edit-docket-number {
	//                 background-color: $light-gray;
	//             }

	//             .edit-button {
	//                 width: 50px;
	//                 position: absolute;
	//                 top: -5px;
	//                 right: 34px;
	//             }
	//         }

	//         &.docket-number {
	//             margin-top: 36px;
	//             margin-bottom: 36px;
	//         }

	//         .reservation-summary-item {
	//             flex-direction: column;

	//             &:not(:first-child) {
	//                 margin-top: 20px;
	//             }

	//             span {
	//                 &.reservation-summary-item-title {
	//                     font-weight: 700;
	//                     width: 100%;
	//                 }

	//                 &.reservation-summary-item-value {
	//                     width: 100%;
	//                 }
	//             }
	//         }
	//     }

	//     .reservation-summary-item {
	//         display: flex;
	//         font-size: 14px;
	//         color: $black-text;

	//         span {
	//             display: inline-block;

	//             &.reservation-summary-item-title {
	//                 width: 33%;

	//                 &.main-title {
	//                     width: 100%;
	//                     font-weight: 700;
	//                 }
	//             }

	//             &.reservation-summary-item-value {
	//                 width: 67%;
	//             }
	//         }

	//         // &.vertical-item {
	//         //     flex-direction: column;
	//         // }
	//     }
	// }

	// .travel-booster-id {
	//     position: relative;

	//     .travel-booster-id-input {
	//         label {
	//             width: 200px;
	//         }

	//         input {
	//             &::placeholder {
	//                 opacity: .5;
	//             }
	//         }
	//     }

	//     .edit-button {
	//         position: absolute;
	//         bottom: 0;
	//     }
	// }

	// .footer {
	//     display: flex;

	//     .status {
	//         display: flex;
	//         // padding-top: 10px;
	//         position: relative;
	//         width: 35%;

	//         // .busy {
	//         //     height: 41px;
	//         //     justify-items: center;
	//         // }

	//         .status-text,
	//         .busy {
	//             position: absolute;
	//             bottom: 0;
	//         }
	//     }

	//     .action-buttons {
	//         margin-left: auto;
	//         display: flex;
	//         justify-content: flex-end;
	//         // width: 33%;
	//         height: 41px;

	//         .busy {
	//             padding-left: 4px;
	//         }

	//         .action-button {
	//             width: 153px;
	//             height: 41px;
	//             font-size: 14px;
	//             text-transform: none;
	//         }

	//         .sync-button {
	//             background-color: $main-blue;
	//             color: $white;

	//             margin-left: 16px;
	//         }

	//         .close-button {
	//             color: $gray-2;
	//         }
	//     }

	//     .status {
	//         // display: inline-block;
	//         // width: 67%;
	//         // text-align: right;
	//         // justify-items: center;
	//         height: 41px;

	//         // .busy {

	//         // }

	//         .status-text {
	//             &.succeeded {
	//                 color: $green
	//             }

	//             &.failed {
	//                 color: $red
	//             }
	//         }
	//     }
	// }
}
