@import '../../node_modules/react-image-gallery/styles/scss/image-gallery';

$collage-gap-value: 8px;

.collage-wrapper {
	width: 816px;

	.collage-container {
		display: flex;
		gap: $collage-gap-value;

		.collage-item {
			background: center / cover no-repeat;
			cursor: pointer;
		}

		&.collage-1-items {
			.collage-item {
				border-radius: 8px;
				width: 100%;
				height: calc((136px * 3) + (2 * $collage-gap-value));
			}
		}

		&.collage-2-items {
			.collage-item {
				flex: 1;
				height: calc((136px * 3) + (2 * $collage-gap-value));
			}
			.collage-item:first-child {
				border-radius: 8px 0 0 8px;
			}
			.collage-item:last-child {
				border-radius: 0 8px 8px 0;
			}
		}

		&.collage-3-items {
			.collage-item:first-child {
				flex: none;
				width: calc(816px - 249px - $collage-gap-value);
				height: calc((136px * 3) + (2 * $collage-gap-value));
				border-radius: 8px 0 0 8px;
			}

			.side-images {
				display: flex;
				flex-direction: column;
				gap: $collage-gap-value;

				.collage-item {
					width: 249px;
					height: calc((calc((136px * 3) + (2 * $collage-gap-value)) - $collage-gap-value) / 2);
				}

				.collage-item:first-child {
					border-radius: 0 8px 0 0;
				}

				.collage-item:last-child {
					border-radius: 0 0 8px 0;
				}
			}
		}

		&.collage-4-items {
			.collage-item:first-child {
				flex: none;
				width: calc(816px - 249px - $collage-gap-value);
				height: calc((136px * 3) + (2 * $collage-gap-value));
				border-radius: 8px 0 0 8px;
			}

			.side-images {
				display: flex;
				flex-direction: column;
				gap: $collage-gap-value;

				.collage-item {
					width: 249px;
					height: 136px;
				}

				.collage-item:first-child {
					border-radius: 0 8px 0 0;
				}

				.collage-item:last-child {
					border-radius: 0 0 8px 0;
				}
			}
		}
	}
}

.hotel-page-main-gallery {
	.image-gallery {
		display: flex;
		align-items: center;
		justify-content: center;

		.image-gallery-content {
			height: 100%;
			width: 100%;

			.image-gallery-slide-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				width: 100%;
			}

			.image-gallery-swipe {
				display: flex;
				align-items: center;
				height: 100%;
				width: 100%;

				.image-gallery-slides {
					height: 100%;
					width: 100%;
					max-width: 75vw;
					max-height: 85vh;

					.image-gallery-description {
						margin-left: 1px !important;
					}

					.image-gallery-slide {
						height: 100%;
						width: 100%;

						.gallery-image {
							border-radius: 0;
							height: 100%;
							min-height: 644px;
							width: 100%;
							min-width: 848px;
						}
					}
				}
			}
		}

		.image-gallery-left-nav,
		.image-gallery-right-nav {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid white;
			border-radius: 50%;
			height: 64px;
			width: 64px;
			padding: 0;

			&:hover {
				background-color: #ddd;
			}

			&::before {
				content: '';
				display: inline-block;
				width: 32px;
				height: 32px;
				background-image: url('../img/hotel_page/arrow.svg');
				background-repeat: no-repeat;
				background-size: cover;
			}

			&.image-gallery-left-nav {
				left: -100px;

				&::before {
					margin-right: 4px;
				}
			}

			&.image-gallery-right-nav {
				right: -100px;

				&::before {
					margin-left: 4px;
					transform: rotate(180deg);
				}
			}
		}
	}
}

.close-button-container {
	position: absolute;
	left: 0;
	top: 1%;
	width: 75px;
	cursor: pointer;

	.icon {
		height: 24px;
		width: 24px;
		background: url('../img/hotel_page/x-close.svg') center center no-repeat;
		background-size: 24px 24px;
	}

	.text {
		@include Font-Regular($secondary-font, $base-font-size-15, #fff, 700);
	}
}

.images-count {
	position: absolute;
	top: 1%;
	width: 122px;
	@include Font-Regular($secondary-font, $base-font-size-15, #fff, 700);
}
