@mixin back-to-button {
	display: flex;
	gap: 8px;

	a {
		@include Font-Regular($secondary-font, $base-font-size-15, $black-text);
		line-height: 18px;
		display: flex;
		gap: 8px;
		padding: 8px 8px 8px 0px;
	}
}

@mixin side-info-block($padding-bottom: 24px) {
	display: flex;
	flex-direction: column;
	width: 266px;
	height: fit-content;
	padding: 16px 16px $padding-bottom;
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

@mixin select-button {
	.select-button {
		width: 115px;
		padding: 12px 18px;
		background: var(--desktop-select-hotel-button-background);
		line-height: 17px;
		@include Font-Regular($secondary-font, $base-font-size-14, var(--desktop-select-hotel-button-color), 700);

		&:hover {
			background: var(--desktop-select-hotel-button-hover);
		}

		&:disabled {
			opacity: 0.65;
		}
	}
}
