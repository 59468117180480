@import '@/sass/variables';

.confirmation-page {
	width: fit-content;
	margin: 0 auto;

	&__content {
		display: flex;
		gap: 21px;
	}

	&__main {
		width: 744px;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&__side {
		width: 355px;

		.deal-details-break-price {
			margin-top: 20px;
			margin-bottom: 64px;
			width: 100%;
			padding: 30px 32px;
		}
	}

	.order-confirmation {
		padding: 16px;

		&__title {
			font-size: 24px;
			font-weight: 700;
			line-height: 28.8px;
			margin-bottom: 4px;
			color: var(--general-colors-black);
		}

		&__content {
			display: flex;
		}

		&__text {
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			color: #008f4b;
			margin-inline-start: 8px;
		}
	}

	.travelers {
		padding: 16px 24px;
		background-color: #fafafa;
		border-radius: 8px;
		font-size: 16px;

		&__title {
			margin-bottom: 16px;
			display: flex;

			&-text {
				font-weight: 800;
				line-height: 25px;
				color: var(--general-colors-gray-dark);
				margin-inline-start: 8px;
			}
		}

		&__names {
			font-weight: 600;
			line-height: 20.32px;
			color: var(--general-colors-black);
		}

		.go-to-booking-page {
			font-size: 13px;
			font-weight: 400;
			line-height: 19px;
			color: var(--general-colors-primary);
			width: fit-content;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	.cancellation-terms,
	.terms-of-supplier,
	.contact-us {
		padding: 16px 32px;
		background-color: #fafafa;
		border-radius: 8px;

		.title {
			font-weight: 800;
			font-size: 18px;
			line-height: 25px;
			margin-bottom: 10px;
			color: var(--general-colors-black);
		}

		&__text {
			font-size: 13px;
			font-weight: 400;
			line-height: 19px;
			color: var(--general-colors-gray-dark);

			&-red {
				color: #d66845;
			}

			&-green {
				color: #008f4b;
			}
		}
	}

	.terms-of-supplier {
		white-space: pre-line;

		.text-button {
			@include Font-Regular($secondary-font, 13px, var(--general-colors-primary));
			font-weight: 400;
			line-height: 19px;
			background: none;
			border: none;
			padding: 0;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}

	.cancellation-terms,
	.contact-us {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 32px;
		padding: 16px 32px 32px;
	}

	&__button {
		width: 180px;
		height: 41px;
		padding: 12px 18px;
		border-radius: 5px;
		border: 1px solid var(--general-colors-primary);
		color: var(--general-colors-primary);
		background-color: transparent;
		font-size: 14px;
		font-weight: 700;
		line-height: 16.8px;
	}

	.reservation-details {
		box-shadow: 0px 0px 12px 0px #0000001a;
		padding: 30px 32px;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		font-size: 15px;
		font-weight: 400;
		line-height: 21px;
		color: var(--general-colors-gray-dark);

		img {
			width: 100%;
			border-radius: 8px;
			height: 180px;
			object-fit: cover;
		}

		&__hotel-name {
			font-size: 18px;
			font-weight: 800;
			line-height: 25px;
			color: var(--general-colors-black);
		}

		&__reviews {
			display: flex;
			gap: 4px;

			@import '@/sass/starsComponent';
		}

		&__address,
		&__rooms-and-guests {
			display: flex;
			align-items: center;
			gap: 8px;
			text-transform: capitalize;
		}

		&__hotel-address {
			margin-bottom: 4px;
		}

		&__dates {
			color: var(--general-colors-black);
		}
	}
}
