@import 'variables';
//@import "../../node_modules/react-datepicker/src/stylesheets/datepicker";
//@import "../../node_modules/react-datepicker/src/stylesheets/datepicker-cssmodules";
@import 'travelersCompleteComponent';
@import 'airportsCompleteComponent';
//@import "../../node_modules/antd/dist/antd.min.css";
@import 'tripRequestWizardPage';
@import 'forwardRequestsPage';
@import 'addAirfareWizardPage';
@import 'airfarePage';

$add-component-height: 80px;

.management-page {
	max-width: 1092px;
	margin: 0 auto;
	position: relative;
	font-family: $secondary-font;

	min-width: 100vw;

	.layout {
		height: 100%;
		display: flex;

		.content {
			width: 80%;
			min-height: calc(100vh - 113px);
			min-width: 870px;
			padding-left: 55px;
			padding-right: 20px;

			.empty-itinerary,
			.no-trips {
				text-align: center;
				padding: 24px;
				margin-top: 50px;
				h3 {
					color: rgba(102, 102, 102, 0.48);

					&.error {
						color: #ff001d;
					}
				}
			}

			.no-ongoing-trips {
				height: 472px;
				background-color: $gray-7;
				border-radius: 4px;
				padding-top: 64px;

				.message {
					margin-top: 16px;
					font-size: 16px;
					font-weight: 500;
					color: $gray-1;
				}

				.back-button {
					margin-top: 32px;
					color: $main-blue;
				}
			}

			.name-bar {
				margin-top: 20px;
				padding-left: 48px;
				position: relative;
				height: 48px;

				.name {
					font-size: 20px;
					color: #0b0b0b;
					display: inline-block;
					height: 32px;
					position: relative;
				}

				.action {
					color: #999;
					transition: color 0.25s;

					&.lock {
						position: absolute;
						margin-left: -37px;
						margin-top: -8px;
					}

					&.edit {
						position: absolute;
						right: -32px;
						top: -1px;
					}

					&.cancel,
					&.close-trip {
						position: absolute;
						right: 16px;
						bottom: 10px;
					}

					&:hover {
						color: #666;
					}

					.evil-icon {
						width: 32px;
						height: 32px;
					}
				}
			}

			.paper {
				border-radius: 8px;
				padding: 32px;
				padding-top: 24px;
				background-color: $gray-7;
				box-shadow: none;
				font-family: $secondary-font !important;
				font-weight: normal !important;

				&.full-height {
					min-height: 77vh; //78.5vh;
				}

				&.busy {
					text-align: center;
					i {
						font-size: 24px;
						margin-top: 100px;
					}
				}
			}
		}
		.sidebar {
			min-width: 400px;
			max-width: 400px;
			background-color: $gray-7;
			font-size: 12px;
			font-weight: normal;
			color: $gray-1;
			padding: 24px;
			border-left: 1px solid $gray-4;

			//Separator Line Color: #E9E9E9
			//Icons color: 		#C5C5C5

			.no-trip {
				.sidebar-header {
					color: $black-text;
					font-size: 18px;
					font-weight: 700;
				}

				.sidebar-content {
					margin-top: 8px;
					color: $gray-1;
					font-size: 15px;
				}

				.space-man-icon {
					margin-top: 96px;
				}
			}

			.trip-preview {
				.name {
					@include Font-Regular($secondary-font, $base-font-size-18, $black-text, 700);
					height: 22px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin-bottom: 8px;
				}

				.trip-status-component {
					@include Font-Regular($secondary-font, $base-font-size-15, $black-text);

					display: inline-block;
				}

				.approval-status {
					display: inline-block;
					margin-left: 5px;
				}

				.path {
					margin-bottom: 8px;
					text-transform: uppercase;
					@include Font-Regular($secondary-font, $base-font-size-15, $gray-2);
				}

				.booked-items {
					.no-booked-items {
						color: $gray-1;
						font-size: 15px;
					}

					& > .title {
						font-weight: bold;
						margin-bottom: 8px;
					}

					.items {
						.booked-item {
							padding-top: 24px;
							padding-bottom: 24px;

							&:not(:last-child) {
								border-bottom: 1px solid #dedede;
							}

							.icon-container {
								display: inline-block;
								vertical-align: top;
								margin-right: 8px;

								i.fa {
									font-size: 16px;
									color: #ccc;
								}
							}

							.item-container {
								display: inline-block;

								span {
									display: inline-block;
								}
							}
						}

						.booked-flight,
						.booked-hotel {
							text-align: center;

							.hotel-name {
								font-weight: 600;
								font-size: 16px;
								text-transform: capitalize;
								margin-bottom: 34px;
							}

							.booked-item-container {
								font-size: 13px;
								line-height: 19px;
								margin-left: 10px;
								margin-right: 10px;
								color: $gray-2;

								.code,
								.check-in-out {
									font-weight: 700;
									color: $blue-2;
									font-size: 24px;
									line-height: 29px;
								}
							}

							.icon-container {
								flex: 1;
								text-align: center;
								position: relative;
								margin-right: 0;

								.icon {
									background: #f8fbff;
									z-index: 1;
								}

								.dash {
									position: absolute;
									top: 3px;
								}
							}

							.flight-icon {
								margin-bottom: 10px;

								.icon {
									width: 29px;
								}

								.dash {
									&.left {
										left: -16px;
									}

									&.right {
										right: -16px;
									}
								}
							}

							.hotel-icon {
								margin-top: 20px;

								.icon {
									width: 34px;
								}

								.dash {
									&.left {
										left: 0;
									}

									&.right {
										right: 0;
									}
								}
							}
						}

						.booked-accommodation {
							.accommodation-container {
								.destination {
									text-transform: capitalize;
								}

								.check-in-container,
								.check-out-container {
									.title {
										width: 63px;
									}

									.check-in,
									.check-out {
										width: 42px;
									}

									.special-request {
									}
								}
							}
						}

						.booked-car {
							// TBD
						}
					}
				}

				.flight-status-component {
					margin-bottom: 8px;
					position: relative;

					.title {
						font-weight: bold;
						margin-bottom: 8px;
					}

					.blocker {
						position: absolute;
						//height: 103px;
						width: 100%;
						background: #f8fbff;
						border-bottom: 1px solid #eee;

						height: 2px;
					}

					.flight-status-container {
						//background-color: #fff;
						//border-radius: 4px;
						overflow: hidden;
						height: 407px;
						pointer-events: none;

						.flight-status {
							border: 1px solid #eee;
						}
					}
				}
			}

			.preview-pane {
				.accommodation {
					.title {
						font-size: 18px;
						text-transform: capitalize;
						color: #333;
					}
					color: #999;

					.status {
						margin-top: -5px;
					}

					.sub-title {
						.status {
							display: inline-block;
							text-transform: capitalize;
						}

						i.fa {
							margin-right: 4px;
						}
						.address::after,
						.city::after {
							content: ', ';
						}

						span {
							text-transform: capitalize;
							&.initials {
								text-transform: uppercase;
							}
						}
					}

					.status-container {
						margin-bottom: 12px;
						.status {
							margin-left: 4px;
							display: inline-block;
							i.fa.fa-spin.fa-spinner {
								margin-right: 4px;
							}

							span {
								@include Font-Regular($secondary-font, 12px, #4a4a4a);
								//font-weight: 300 !important;
							}

							&.quote {
								span {
									color: #0b76cc !important;
								}
							}
							&.pending {
								span {
									color: #d18a14 !important; //#FFAA00;
								}
							}
							&.canceled,
							&.cancelled {
								span {
									color: #d0021b !important; //red;
								}
							}
							&.confirmed {
								span {
									color: #417505 !important; //green;
								}
							}
						}
					}

					.section {
						margin-bottom: 16px;

						.title {
							font-size: 12px;
							color: #666;
							font-weight: bold;
							margin-bottom: 12px;
							text-transform: none;
							margin-top: 16px;
						}

						.section-content {
							padding-left: 24px;

							.deal {
								margin-bottom: 16px;
							}

							.meta {
							}

							.field {
								position: relative;

								&.room-description {
									.field-value {
										text-transform: capitalize;
									}
								}

								.field-name {
									width: 100px;
									display: inline-block;
								}

								.field-icon {
									position: absolute;
									left: -20px;
								}

								.field-value {
									display: inline-block;
									margin-right: 4px;

									& + .field-value::before {
										content: '| ';
									}
								}
							}
						}
					}

					.section + .section {
						margin-top: 16px;

						&::before {
							content: '';
							display: block;
							height: 1px;
							//width: 80%;
							//margin: 10px 10% 10px 10%;
							width: 100%;
							background: #ccc;
						}
					}

					.rooms {
						.quantity::after {
							content: ' | ';
						}

						i.fa::before {
							margin-left: 4px;
						}
					}

					.travelers {
						.prefix::after {
							content: ' | ';
						}
						a.traveler + a.traveler::before {
							content: ', ';
						}
					}
				}
				.airfare {
					.title {
						font-size: 18px;
						color: #333;
					}
					color: #999;

					.flights {
						margin-top: 24px;
						.title {
							font-size: 12px;
							color: #666;
						}

						.section-content {
							//margin-top: 12px;

							.flight {
								padding-left: 24px;
								margin-top: 16px;
								//.line {
								//  display: block;
								//
								//  div {
								//    display: inline-block;
								//  }
								//}
								.field {
									display: block;
									position: relative;

									.field-icon {
										position: absolute;
										left: -20px;
									}

									span {
										display: inline-block;
									}

									&.flight-number,
									&.airline,
									&.duration {
										.field-name {
											margin-right: 4px;
										}
									}

									&.flight-number {
										margin-top: 16px;
									}

									&.depart,
									&.arrival {
										.field-name {
											width: 50px;

											& + .field-value {
												width: 80px !important;
											}
										}

										.field-value {
											width: 50px;

											&:last-of-type {
												width: 80px;
											}
										}
									}
								}
							}

							.flight + .flight::before {
								content: '';
								display: block;
								height: 1px;
								//width: 80%;
								//margin: 10px 10% 10px 10%;
								width: 100%;
								background: #ccc;
							}
						}
					}
				}

				.tips {
					color: $black-text;

					.title {
						font-size: 18px;
						font-weight: 800;
						margin-bottom: 16px;
					}

					.sub-title {
						margin-bottom: 16px;
						font-size: 15px;
					}

					.preview-content {
						margin-bottom: 24px;

						.example {
							color: $gray-1;
							font-size: 15px;
						}
					}

					.notes {
						color: #0b0b0b;
					}
				}
			}
		}
	}

	.trip-status-component {
		margin-top: 4px;
		margin-bottom: 12px;

		&.icon-only {
			display: inline;
		}

		span.status-icon {
			vertical-align: text-bottom;
			margin-right: 4px;

			i.fa {
				font-size: 9px;
				color: #e1e1e1;
				margin-right: 4px;
			}

			&.draft i.fa {
				color: #ccc;
			}
			&.new-request i.fa {
				color: #72c0ea;
			}
			&.pending i.fa {
				color: #ffaa00;
			}
			&.approved i.fa {
				color: #87d068;
			}
			&.canceled,
			&.declined,
			&.rejected {
				i.fa {
					color: #dd5a5a;
				}
			}

			&.ongoing i.fa {
				animation: pulse 2s infinite;
			}

			@keyframes pulse {
				0% {
					color: #87d068;
				}
				50% {
					color: #228844;
				}
				100% {
					color: #87d068;
				}
			}
		}

		span.status-text {
			@include Font-Regular($secondary-font, $base-font-size-15, $black-text);

			text-transform: capitalize;
		}
		.approval-status {
			display: inline-block;
			margin-left: 5px;
		}
	}
}

///////////////////////////////////////
//////// material ui overrides ////////
///////////////////////////////////////

div[role='presentation'] {
	& > div[role='menu'] {
		padding: 0 !important;
		display: block !important;

		& > div {
			span[role='menuitem'] {
				//padding-left: 32px !important;

				& > div {
					& > span {
					}

					& > div {
						//padding-left: 0 !important;
						padding-left: 32px !important;

						& > svg {
							// selected option icon
							left: 0 !important;
						}

						& > div {
							// option text
							font-size: 12px !important;
							font-weight: 400 !important;
						}
					}
				}
			}
		}
	}
}

.cancel-reservation-modal {
	.cancellation-policy {
		padding: 10px 10px 10px 0;
		margin: 5px 5px 5px 0;
		max-width: 95%;
	}
}
