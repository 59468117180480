.agent-settings-modal-paper {
	margin-top: -24px;

	& > div {
		padding: 0 !important;
		border: 0 !important;
	}
}

.dialog-paper {
	width: 50%;
	margin-left: 25%;
}

.agent-settings-modal {
	position: relative;
	overflow: hidden;

	.modal-title {
		position: relative;
		padding: 24px;

		a.action.close-modal {
			position: absolute;
			top: 17px;
			right: 17px;
			color: #ccc;

			&:hover {
				color: #666666;
			}

			.evil-icon {
				width: 32px;
				height: 32px;
			}
		}
	}

	.modal-fields {
		display: flex;
		margin: 24px;

		.field {
			display: inline-block;
			vertical-align: middle;
			//width: 200px;

			label {
				transform: scale(1) translate(0px, -24px) !important;
			}

			&.company {
				width: 300px;

				.field-input.select button {
					margin-right: -36px !important; // patch for material ui select field arrow
				}
			}

			&.margin,
			&.fixed-fee {
				width: 100px;
				margin-left: 36px;
			}
		}
	}

	.disclaimer {
		padding: 24px;
		padding-bottom: 12px;
		font-size: 12px;
		margin-top: 12px;

		span {
			//margin-right: 4px;
		}

		a {
			margin-left: 4px;
		}
	}

	hr {
		margin-left: 24px;
		margin-right: 24px;
	}

	.modal-actions {
		margin: 0 24px 24px;
		position: relative;
		&.busy {
			cursor: progress;
		}

		.busy {
			pointer-events: none;
			//cursor: not-allowed !important;
			opacity: 0.5;

			&:hover {
				color: inherit;
			}
		}

		.actions {
			padding-top: 6px;

			.action {
				margin-right: 24px;
				font-size: 12px;
				color: #999;

				&:hover {
					color: #666;
				}

				&.save-changes {
					color: #666;

					&:hover {
						color: #333;
					}
				}

				&.cancel-changes {
				}
			}

			.bottom-right-corner {
				position: absolute;
				right: 0;

				&.busy-indicator {
					right: 24px;
					top: 12px;
				}

				&.loader {
					font-size: 12px;
					margin-top: 6px;
					margin-right: 24px;
				}
			}
		}
	}

	.discard-changes-dialog {
		width: 100%;
		height: 100%;
		position: absolute;
		background: #fff;
		top: 0;
		text-align: center;
		padding-top: 80px;
		z-index: 1;

		.message {
			max-width: 80%;
			margin: 0 auto;

			div {
				color: $text-red;
				font-size: 16px;
			}
		}

		.actions {
			span {
				font-size: 18px !important;
			}
		}
	}
}
