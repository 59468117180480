@import 'variables';

.footer-bar {
	position: absolute;
	width: 100%;
	height: $footer-bar-height;
	display: flex;
	align-items: center;
	color: $footer-bar-color;
	border-top: 1px solid $gray-4;
	bottom: -9.2vh;

	.inner-container {
		margin: 0 auto;
		padding-right: 68px;

		.navigation {
			width: 100%;
			height: 50%;

			.nav-div {
				height: 100%;
				display: inline-block;
				margin-left: 20px;

				a {
					padding: 20px 10px;
					@include Font-Regular(
						$footer-bar-disclaimer-font-family,
						$footer-bar-disclaimer-font-size,
						$footer-bar-color
					);
				}
			}
		}

		.info {
			width: 100%;
			height: 50%;
			display: inline;

			.info-div {
				height: 100%;
				display: inline-block;
				margin-left: 20px;
				@include Font-Regular(
					$footer-bar-disclaimer-font-family,
					$footer-bar-disclaimer-font-size,
					$footer-bar-color
				);

				&:first-child {
					margin-left: 0;
					margin-right: 10px;
				}

				a {
					padding: 20px 10px;
					color: $footer-bar-color;
				}
			}
		}
	}
}
