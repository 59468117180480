.users-management-page-wrapper {
	background-color: #f7f7f7;
	min-height: 792px;
}

.users-management-page {
	max-width: 1092px;
	min-width: 1092px;
	height: 100%;
	margin: 0 auto;
	padding-top: 36px;
	position: relative;

	.role {
		text-transform: capitalize;
	}

	.action-menu {
		opacity: 0;
		transition: opacity 0.25s;

		.spinner {
			padding-left: 16px;
		}

		&.is-loading {
			opacity: 1;
		}
	}

	.table-row.loading {
		cursor: progress;
	}

	.table-font {
		font-size: 12px !important;
	}

	.table-row:hover {
		.action-menu {
			opacity: 1;
		}
	}

	.menu-item {
		cursor: pointer;
	}

	.sort-icon {
		display: inline-block;
		margin-left: 10px;
		width: 24px;
		height: 18px;
		text-align: center;
		cursor: pointer;

		svg {
			width: 14px !important;
			height: 14px !important;
		}
	}

	.pagination-wrapper {
		ul.pagination {
			float: right;
		}
	}

	.paper {
		margin-bottom: 50px;
	}

	.toolbar {
		padding-top: 20px;
		padding-bottom: 24px;
	}

	.ellipsis {
		max-width: 240px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.avatar {
		margin-right: 10px;
	}

	.avatar-text {
		display: inline-block;
	}

	.title {
		flex: 0 0 auto;
	}

	.add-user {
		position: absolute;
		left: -26px;
		top: 42px;
	}
}
