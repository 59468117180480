@import './mixins/_bookingPageMixins';

.new-bookings-filters-component {
	width: 100%;
	height: 90px;
	margin: 0 auto;
	background: $gray-background-light;
	padding: 24px 66px;

	&.tags {
		height: auto;
	}

	&__filters,
	&__tags {
		max-width: 1600px;
	}

	&__filters {
		display: flex;
		gap: 16px;
		margin: 0 auto;
	}

	&__filter {
		display: flex;
		align-items: center;
		gap: 8px;

		label {
			font-size: 15px;
			font-weight: 400;
			margin-bottom: 0;
		}

		& .MuiTextField-root {
			label {
				font-size: 14px;
				color: $gray-color-light;
			}
		}

		&.when {
			.desktop-date-picker {
				width: 160px;

				label {
					color: rgb(172, 172, 172);
					font-size: 14px;
				}

				input::selection {
					background: transparent;
				}

				.MuiInputBase-root {
					padding-right: 0;
				}
			}
		}

		&.clear,
		&.advanced-search {
			button.MuiButtonBase-root {
				background: transparent;
				border: none;
				color: var(--desktop-bookings-button);
			}

			&:hover {
				text-decoration: underline;
				text-decoration-color: var(--desktop-bookings-button);
			}
		}

		&.export {
			margin-left: auto;

			.busy-indicator {
				width: 24px;
				height: 24px;
				display: flex;
				align-items: center;
				padding-left: 4px;
			}
		}

		&.search {
			.MuiInputBase-root {
				width: 420px;
				padding: 0 6px;
			}
		}

		& .MuiInputBase-root {
			.MuiInputBase-input {
				padding-top: 11px;
				padding-bottom: 11px;
			}

			legend {
				max-width: 0.01px; // don't show legend
			}
		}

		& .MuiInputLabel-root {
			transform: translate(12px, 10px);

			&.MuiInputLabel-shrink {
				display: none;
			}
		}

		& button.MuiButtonBase-root {
			font-size: 14px;
			background-color: white;
			text-transform: none;
		}
	}

	&__search-wrapper {
		.MuiTextField-root {
			width: 100%;
		}
	}

	&__tags {
		margin: 16px auto 0;

		.MuiStack-root {
			display: flex;
			gap: 16px;

			.MuiChip-root {
				height: 35px;
				margin-left: 0;

				color: $gray-1;
				background-color: $gray-4;
				border-radius: 12px;
				font-size: 15px;

				.tag {
					display: flex;

					& div:first-child {
						margin-right: 4px;
					}
				}

				.MuiChip-deleteIcon {
					margin: 0 12px 0 0;
				}
			}
		}
	}

	@include outlined-input-styles($secondary-blue);
}

#csv-dropdown-menu {
	.menu-item-icon {
		margin-right: 8px;
	}

	.MuiPaper-root {
		margin-top: 4px;
	}
}
