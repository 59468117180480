// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
	background: var(--desktop-calendar-range-background);
	color: $black-text;
	border: 1px solid transparent;
}

.CalendarDay__selected_span:hover {
	border: 1px solid transparent;
	background: var(--desktop-calendar-hover);
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
	background: var(--desktop-calendar-selected-background);
	color: var(--desktop-calendar-selected-color);
	border: 1px solid transparent;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
	background: var(--desktop-calendar-hover);
	color: white;
	border: 1px solid transparent;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
	background: #f0f7fb;
	color: #484848;
	border: 1px solid transparent;
}

.CalendarDay__default {
	border-radius: 50%;
	border: 1px solid transparent;
	outline: none;
}

.CalendarDay__default:hover {
	border: 1px solid transparent;
}

.CalendarDay__default:focus-visible {
	outline: $color-outline-tab 2px auto;
}

.DateRangePicker {
	width: 100%;

	> div {
		height: 100%;
	}
}

.DateRangePickerInput {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	background-color: transparent;

	&:hover {
		cursor: pointer;
		.DateInput,
		.DateInput_input {
			cursor: pointer;
		}
	}
}

.DateInput {
	display: flex;
	width: 155px;
	background-color: transparent;
	background-color: $gray-5;
	height: 100%;
	border-radius: 8px;
}

.DateInput_input {
	position: relative;
	color: $gray-1;
	font-size: 15px;
	font-weight: 700;
	line-height: 18px;
	background-color: var(--desktop-search-background);
	border-bottom: 2px solid var(--desktop-search-background);
	text-align: left;
	border-radius: 8px;
	padding-top: 29px;
	padding-left: 41px;
	text-overflow: ellipsis;

	&:hover:not(.DateInput_input__focused) {
		background-color: var(--desktop-search-hover);
		border-bottom: 2px solid var(--desktop-search-hover);
	}

	&::placeholder {
		color: $black-text;
	}
}

.DateInput_input__focused {
	border-bottom: 2px solid var(--desktop-search-border);
}

.DateRangePickerInput_arrow {
	width: 8px;
	visibility: hidden;
}

.DateRangePicker_picker {
	left: -160px !important;
	border-radius: 8px;
	box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.2);
}

.DayPickerNavigation_button {
	border: none;
}

.DayPickerNavigation_svg__horizontal {
	fill: var(--desktop-calendar-color);
}

.DateInput_fang {
	display: none;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
	border-right: 33px solid $main-blue;
}

.CalendarMonth_caption {
	color: var(--desktop-calendar-color);
	font-weight: 800;
}

.DayPicker__withBorder {
	border-radius: 8px;
}
