@import 'colors';

$component-width: 146px;
$component-height: 42px;
$full-width: calc(#{$component-width} * 2);

$freelance-component-width: 184px;
$freelance-component-height: 41.16px;
$freelance-full-width: calc(#{$freelance-component-width} * 2);

.change-client-price {
	label {
		font-size: 15px;
		margin-bottom: 0;
	}

	.MuiDialog-paper {
		padding: 32px;

		.MuiDialogTitle-root {
			padding: 0;
		}

		.MuiDialogContent-root {
			margin-top: 22px;
			padding: 0;
		}
	}

	.basket-agent-pricing {
		z-index: 1500 !important;
	}

	.change-client-price-title {
		font-size: 21px;
		font-weight: 800;
		line-height: 25px;
		color: $black-text;
	}

	.MuiDialogActions-root {
		padding: 0;
	}

	.change-client-price-actions {
		display: flex;
		justify-content: flex-end;
		margin-top: 53px;
		width: 100%;

		button {
			font-size: 15px;
			padding: 8px 32px;
			border-radius: 4px;

			&:first-of-type {
				background-color: $gray-3;
				color: $black-text;
				margin-right: 10px;
			}

			&:last-of-type {
				background-color: $main-blue;
				color: #fff;
			}
		}

		.MuiButton-label {
			font-family: 'Lato';
			letter-spacing: 0;

			color: $main-blue;
		}
	}

	.agent-pricing-container {
		width: $full-width;

		.MuiButton-outlinedPrimary {
			color: $main-blue;
		}

		.MuiButton-containedPrimary,
		.MuiButton-containedPrimary:hover {
			background-color: $main-blue;
			box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
		}

		.MuiTouchRipple-child {
			background-color: $main-blue;
			color: $main-blue;
		}

		.agent-pricing {
			width: 100%;

			.edit-pricing {
				width: 100%;

				.pricing-method {
					margin-top: 15px;

					.MuiButtonGroup-grouped {
						font-size: 14px;
					}

					.MuiButtonGroup-grouped:not(:first-of-type) {
						border-radius: 0px 4px 4px 0px;
					}

					.MuiButtonGroup-grouped:not(:last-of-type) {
						border-radius: 4px 0px 0px 4px;
					}

					button {
						text-transform: capitalize;
						width: $component-width;
						height: $component-height;

						font-family: 'Lato';
						letter-spacing: 0;
					}
				}

				.pricing-value {
					margin-top: 16px;

					.MuiInputBase-formControl,
					.MuiTypography-root {
						font-size: 15px;
						font-weight: 700;
						color: $black-text;
					}

					.content-loader {
						outline: 1px solid #ccc;
						outline-offset: 0;
						border-radius: 4px;
					}

					.pricing-text-field,
					.pricing-text-field > div {
						width: $full-width;
						height: $component-height;
					}
				}
			}
		}
	}

	&.settle-payment-edit-commission {
		.agent-pricing-container {
			width: $freelance-full-width;

			.agent-pricing {
				.edit-pricing {
					.pricing-method {
						button {
							width: $freelance-component-width;
							height: $freelance-component-height;

							font-size: 15px;
							font-weight: 600;
							line-height: 18px;

							&.MuiButton-outlined {
								color: $gray-1;
							}

							&.MuiButton-contained {
								background-color: $gray-1;
							}
						}
					}
					.pricing-value {
						.pricing-text-field,
						.pricing-text-field > div {
							width: $freelance-full-width;
							height: $freelance-component-height;
						}
					}
				}
			}
		}

		.change-client-price-actions {
			button {
				width: 128px;
				height: 41px;

				font-size: 14px;
				line-height: 16.8px;
				font-weight: 700;

				&.change-client-price-cancel {
					margin-right: 20px;
					background-color: $gray-5;
				}
			}
		}

		.pricing-summary {
			border-top: 1px solid $gray-4;
			border-bottom: 1px solid $gray-4;

			padding-top: 16px;
			padding-right: 24px;
			padding-bottom: 16px;
			padding-left: 24px;
		}

		.sell-in-net-price {
			margin-top: 30px;

			width: 169px;
			height: 48px;

			border: 1px solid $gray-3;
			border-radius: 10px;

			padding-top: 12px;
			// padding-right: 16px;
			// padding-bottom: 12px;
			padding-left: 16px;

			.sell-in-net-price-label {
				margin: 0;

				.sell-in-net-price-checkbox {
					width: 24px;
					height: 24px;

					.MuiSvgIcon-root {
						font-size: 28px;
					}
				}

				.MuiFormControlLabel-label {
					margin-left: 8px;
					font-size: 15px;
					line-height: 18px;
					font-weight: 700;
				}
			}
		}
	}

	.pricing-summary {
		margin-top: 16px;

		hr {
			color: $gray-4;
		}

		label:not(:first-of-type) {
			margin-top: 24px;
		}

		.pricing-item {
			display: flex;
			margin-top: 16px;

			&.bold {
				font-weight: 700;
			}

			&.sum {
				color: $main-blue;
			}

			.price-value {
				margin-left: auto;

				span.approx {
					font-weight: 400;
					font-size: 13px;
					margin-right: 2px;
				}

				.edit-commission {
					margin-right: 12px;
					color: $main-blue;
					font-size: 13px;
					text-decoration: underline;

					&:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}
