@import 'variables';
@import 'hotelCardComponent';

.results {
	width: 100%;
	margin-bottom: 110px;

	.results-notes,
	.waiting-for-more {
		display: flex;
		flex-direction: column;
		font-size: 24px;
		margin-top: 18px;
		text-align: center;
		padding: 25px;
		border-radius: 8px;

		i {
			margin-right: 4px;
		}

		a,
		a:visited {
			cursor: pointer;
			text-decoration: underline;
			color: #069;
		}

		a:hover {
			color: #035;
		}

		.no-results-image {
			width: 250px;
			height: 250px;
			margin: 0 auto 20px;
		}
	}

	.result {
		background: $white;
		width: 100%;
		display: block;
		margin-top: 24px;
		font-family: lato;
	}

	.show-more {
		text-align: center;
		vertical-align: middle;
		margin-top: 24px;
		margin-bottom: 24px;
		width: 100%;
		height: 48px;
		line-height: 48px;
		background: #3ea8dc;
		cursor: pointer;
		border-radius: 4px;
		&:hover {
			background: #3098cc;
		}
		@include Font-Regular(
			$show-more-results-font-family,
			$show-more-results-font-size,
			$show-more-results-font-color
		);
	}

	.loading {
		background: $white;
		width: 100%;
		display: block;

		margin-top: 24px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 24px;

		font-size: 36px;
		text-align: center;
		padding: 24px;
	}
}
