@import 'variables';
@import 'fixed-data-table';

$filters-height: 84px;

.reports-page {
	max-width: 1092px;
	min-width: 1000px;
	//height: 1700px;
	height: 100%;
	margin: 0 auto;
	padding-top: 36px;
	position: relative;
	padding-bottom: 64px;

	.reports-loading {
		&.on-top {
			position: absolute;
			top: 121px;

			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;

			//-moz-box-shadow: 13px 13px 0 0 #FFFFFF;
			//-webkit-box-shadow: 13px 13px 0 0 #FFFFFF;
			//box-shadow: 13px 13px 0 0 #FFFFFF;
		}

		display: block;
		width: 100%;
		//border: 1px solid #ccc;
		//border-radius: 4px;
		margin-bottom: 20px;

		font-size: 36px;
		margin-top: 7%;
		text-align: center;
		padding: 25px;

		//// todo: remove after yt
		//span {
		//  margin-bottom: 20px;
		//}
	}

	.reports-notes {
		display: block;
		width: 100%;
		border: 1px solid #ccc;
		border-radius: 4px;
		margin-bottom: 20px;

		font-size: 19px;
		margin-top: 18px;
		text-align: center;
		padding: 25px;

		// todo: remove after yt
		span {
			margin-bottom: 20px;
		}
	}

	.filters-container {
		display: block;
		width: 100%;
		margin-bottom: 20px;
		border: 1px solid #d3d3d3;
		border-radius: 4px;
		//height: $filters-height;
		height: $filters-height - 4px;
		//line-height: $filters-height - (2 * $filters-padding);

		.reports-filter {
			padding: $filters-padding;
			width: 100%;
			height: 100%;
			vertical-align: middle;
			border-top: 4px solid #35a8df;
			border-radius: 4px;

			div.date-filter-container {
				//width: 34%;
				margin-right: 64px;
			}

			div.client-name-filter-container {
				margin-right: 24px;

				.client-name-container {
					width: 200px;

					.select-container {
						width: 200px;

						select {
							width: 200px;
							background-position-x: 170px !important;
						}
					}
				}
			}

			.end-date-containers + .update-filters-container {
				margin-left: 288px;

				.update-filters-error {
					display: block;
					width: 300px;
					left: -216px;
					top: 36px;
				}
			}

			.update-filters-container {
				//width: 32%;
				text-align: right !important;

				.update-filters-button {
					position: absolute;
					right: 24px;
					top: 61px;
				}

				span.update-filters-error {
					font-size: 12px;
					//padding-right: 12px;
					color: red;
					font-weight: bold;
					vertical-align: bottom;

					/* display: block; */ // is it needed?
					padding-right: 2px;
					padding-top: 3px;
				}
			}

			div.filter-container {
				display: inline-block;
				//margin-right: 2%;
				text-align: left;

				label,
				.month-container {
					margin-right: 8px;
					font-weight: 400;
				}

				.date-container,
				.client-name-container {
					display: inline-block;
				}

				.select-container {
					background: white;
					border-radius: 4px;
					width: 120px;

					select,
					ul {
						appearance: none;
						-moz-appearance: none;
						-webkit-appearance: none;
						background: url('../img/search_page/search_bar/arrow_drop.png') no-repeat 90px;
					}
				}

				.dropdown {
					button {
						background: #fff;
					}
				}

				input {
					cursor: not-allowed;
					vertical-align: middle;
					height: 36px;
					border: 1px solid #ccc;
					border-radius: 4px;
					background: none;
					width: 100%;
					padding-left: 10px;
					padding-right: 10px;
					font-family: $secondary-font;
					&:disabled {
						background: rgba(211, 211, 211, 0.35);
					}
				}
			}

			.filter-container.destination-hotel-name-container {
				width: 48%;
				background: url('../img/magnifier-icon.png') no-repeat left center;
				background-size: 16px auto;
				background-position-x: 8px;
				input {
					padding-left: 28px !important;
					font-family: $secondary-font;
				}
			}

			.filter-container.traveler-name-container {
				width: 23%;
			}

			.filter-container.date-container {
				width: 11.5%;
				position: relative;
				padding-right: 30px !important;

				input {
					width: 128px !important;
				}

				i.glyphicon.glyphicon-calendar.fa.fa-calendar {
					position: absolute;
					right: 10px;
					//top: 18px;
					//top: 10px;
					top: 12px;
					color: $gray-color-light;
				}
			}

			.filter-container.end-date-container {
				margin-right: 0;
			}
		}

		.export-to-csv {
			width: 6%;
			position: absolute;
			right: 24px;
			top: 112px;

			button {
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				color: #35a8df;

				&:focus {
					outline: 0;
				}
			}
		}
	}

	.reports-container.loading {
		-webkit-filter: blur(2px) grayscale(40%); /* Chrome, Safari, Opera */
		filter: blur(2px) grayscale(40%);
		opacity: 0.5;
	}

	.report-header {
		margin-top: 12px;
		margin-bottom: 12px;
		text-align: center;

		span {
			font-size: 24px;
		}
	}

	.reports-container {
		display: block;
		width: 100%;
		//border: 1px solid #ccc;
		border-radius: 4px;
		margin-bottom: 20px;

		.table-container {
			font-size: 12px;
		}

		.upper {
			display: block;
			width: 100%;

			.text-reports-container {
				display: block;
				height: $filters-height - 4px;
				text-align: center;
				background: #00abdb;
				font-family: Lato;
				border-radius: 4px;

				.text-report {
					display: inline-block;
					width: 25%;
					height: 100%;
					padding-top: 4px;

					&:not(:last-of-type) {
						border-right: 1px solid #fff;
					}

					.text-report-description {
						display: block;
						margin-left: 4px;

						span {
							//display: table-caption;
							//word-spacing: ;
							font-size: 16px;
							//color: #ccc;
							color: #fff;
							font-weight: 300;
						}
					}

					.text-report-data {
						display: inline-block;
						text-align: center;
						//color: #0E4B94;
						color: #fff;
						margin-top: -4px;

						.text-report-value {
							display: inline-block;

							span {
								font-size: 41px;
								text-align: center;
							}
						}
						.text-report-unit {
							display: inline-block;

							span {
								font-size: 14px;
								text-align: center;
								font-weight: 300;
							}
						}
					}
				}
			}

			.chart-reports-container {
				display: block;
				vertical-align: top;
				text-align: left; //center;
				//margin-top: 50px;
				margin-top: 16px;

				.chart-report {
					display: inline-block;
					width: 50%;
					text-align: left;

					&.total-spent .text-report-value {
						color: rgba(42, 196, 91, 1);
					}

					&.average-price-per-night .text-report-value {
						//color: rgba(243, 102, 74, 1);
						//color: rgba(243, 170, 111, 1);
						color: rgba(243, 136, 74, 1);
					}

					.text-report {
						display: block;
						width: 80%;
						margin: 0 auto;

						.text-report-data {
							display: inline-block;
							text-align: center;

							.text-report-value {
								display: block;

								span {
									font-size: 42px;
									text-align: center;
								}
							}
							.text-report-unit {
								display: block;

								span {
									font-size: 16px;
									text-align: center;
								}
							}
						}

						.text-report-description {
							display: inline-block;
							height: 100%;
							margin-left: 4px;

							span {
								//display: table-caption;
								display: block;
								font-size: 17px;
								color: #737373; //#ccc;
								font-weight: 300;
								line-height: 18px;
							}
						}
					}

					.bar-chart-container {
						//height: 240px;
						//margin-top: 8px;
						width: 80%;
						height: 200px;
						margin: 0 auto;

						canvas {
							margin: 0 auto;
						}
					}
				}

				.text-report {
					.text-report-description {
						span {
							text-transform: capitalize;
						}
					}
				}
			}
		}

		span.text-cell {
			text-transform: capitalize;
		}

		span.cell-span.monetary {
			&.negative {
				color: red;
			}
		}

		span.cell-span.variance {
			margin-left: 4px;

			&:before {
				content: '(';
			}

			//&.positive {
			//  color: green;
			//
			//  &:before {
			//    content: '(+';
			//  }
			//}
			//
			//&.negative {
			//  color: red;
			//}

			&.excess {
				color: red;
			}

			&:after {
				content: '%)';
			}
		}

		.oop {
			//margin-top: 64px;
			margin-top: 120px;
			.left-pie {
				width: 33.5%; //67%; //50%;
				margin-left: 33.5%;

				.oop-gauge-container {
					p.title {
						text-align: center;
						margin: 10px;
						font-size: 19px;
					}
				}
			}
			.oop-table {
				margin-left: 10%;
				margin-top: 20px;
				span.traveler-name {
					text-transform: capitalize;
				}
				button.export {
					margin-right: 70px;
					float: right;
				}
			}
		}

		.bottom {
			//margin-top: 64px;
			margin-top: 120px;

			.table-container {
				vertical-align: top;
				text-align: left;
				display: inline-block;

				.table-header {
					background: #00abdb;
					color: #fff;

					span.table-title {
						margin-right: 6px;
					}

					span.table-sort {
						font-size: 12px;
						//opacity: .85;
						font-weight: 300;
					}
				}

				span.cell-span {
					font-size: 12px;
				}

				//.table-header-cell {
				//  background: #00ABDB;
				//}

				&.top-cities-table-container .fixedDataTableLayout_main.public_fixedDataTable_main {
					margin-left: 0;
				}
				&.top-travelers-table-container .fixedDataTableLayout_main.public_fixedDataTable_main {
					margin-right: 0;
				}

				.fixedDataTableLayout_main,
				.public_fixedDataTable_main {
					//text-align: left;
					//border: none;
					margin: 0 auto;

					.public_fixedDataTable_header,
					.public_fixedDataTable_header .public_fixedDataTableCell_main {
						background: none;
					}

					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////// fixed-data-table-2 additions ////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					.ScrollbarLayout_main.ScrollbarLayout_mainVertical.public_Scrollbar_main,
					.fixedDataTableLayout_horizontalScrollbar.public_fixedDataTable_horizontalScrollbar,
					.fixedDataTableLayout_bottomShadow.public_fixedDataTable_bottomShadow {
						display: none !important;
					}
					.fixedDataTableLayout_rowsContainer {
						overflow: visible !important;
					}
					.fixedDataTableRowLayout_main.public_fixedDataTableRow_main.public_fixedDataTableRow_highlighted.public_fixedDataTableRow_odd.public_fixedDataTable_bodyRow {
						overflow: visible !important;
					}
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
				}
			}

			.top-cities-table-container {
				min-width: 54.5%; //67%; //50%;
				//width: 800px;
				//margin: 0 auto;

				span.city-name {
					text-transform: capitalize;
				}

				span.number-of-trips {
				}

				span.average-price-per-night {
				}

				span.total-spent {
				}
			}

			.top-travelers-table-container {
				width: 35.5%; //50%;
				//text-align: right;
				display: inline-block;
				//width: 800px;
				//margin: 0 auto;
				.fixedDataTableLayout_main.public_fixedDataTable_main {
					//border: none;
					//text-align: left;

					.public_fixedDataTable_header,
					.public_fixedDataTable_header .public_fixedDataTableCell_main {
						background: none;
					}
				}

				span.traveler-name {
					text-transform: capitalize;
				}

				span.number-of-trips {
				}

				span.total-spent {
				}
			}

			.cost-centers-table-container {
				width: 60%;
				//margin-right: 20px;

				& + .table-container {
					width: 40% !important;
				}
			}
		}

		hr.bottom-extra-separator {
			margin-top: 48px;
		}

		.extra {
			margin-top: 48px;

			.table-container {
				vertical-align: top;

				.table-header {
					background: #00abdb;
					color: #fff;

					span.table-title {
						margin-right: 6px;
					}

					span.table-sort {
						font-size: 12px;
						//opacity: .85;
						font-weight: 300;
					}
				}

				span.cell-span {
					font-size: 12px;
				}

				//.table-header-cell {
				//  background: #00ABDB;
				//}

				.fixedDataTableLayout_main,
				.public_fixedDataTable_main {
					//text-align: left;
					//border: none;
					margin: 0 auto;

					.public_fixedDataTable_header,
					.public_fixedDataTable_header .public_fixedDataTableCell_main {
						background: none;
					}

					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////// fixed-data-table-2 additions ////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					.ScrollbarLayout_main.ScrollbarLayout_mainVertical.public_Scrollbar_main,
					.fixedDataTableLayout_horizontalScrollbar.public_fixedDataTable_horizontalScrollbar,
					.fixedDataTableLayout_bottomShadow.public_fixedDataTable_bottomShadow {
						display: none !important;
					}
					.fixedDataTableLayout_rowsContainer {
						overflow: visible !important;
					}
					.fixedDataTableRowLayout_main.public_fixedDataTableRow_main.public_fixedDataTableRow_highlighted.public_fixedDataTableRow_odd.public_fixedDataTable_bodyRow {
						overflow: visible !important;
					}
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
					//////////////////////////////////////////////////////////////////////////////////////////////////////
				}
			}

			.annual-budget-actual-spend {
				width: 100%;
				text-align: left;
				display: inline-block;
				//width: 800px;
				//margin: 0 auto;

				span.city-name {
					text-transform: capitalize;
				}

				span.number-of-trips {
				}

				span.average-price-per-night {
				}

				span.total-spent {
				}
			}
		}
	}
}
