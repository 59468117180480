.stars {
	.star {
		width: 13px;
		height: 13px;
		display: inline-block;
		-webkit-mask-repeat: no-repeat;
		mask-repeat: no-repeat;
		background: var(--general-stars);

		&.full {
			-webkit-mask-image: url('../img/results_page/star.svg');
			mask-image: url('../img/results_page/star.svg');
		}

		&.half {
			-webkit-mask-image: url('../img/results_page/half_star.svg');
			mask-image: url('../img/results_page/half_star.svg');
		}

		&.empty {
			-webkit-mask-image: url('../img/results_page/empty_star.svg');
			mask-image: url('../img/results_page/empty_star.svg');
		}
	}
}
