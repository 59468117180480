@import 'colors';
@import 'hotelAddressComponent';

.deal-details {
	@include side-info-block(32px);

	.nights {
		font-size: 16px;
		font-weight: 500;
	}

	.dates-container {
		.check-in,
		.check-out {
			width: 117px;
			margin-top: 8px;
			padding: 12px;
			border: 1px solid $gray-4;

			span:nth-child(1) {
				font-size: 15px;
				line-height: 21px;
				color: $gray-2;
			}

			span:nth-child(2) {
				font-size: 15px;
				line-height: 23px;
				color: $black-text;
			}
		}

		.check-in {
			border-top-left-radius: 5px;
		}

		.check-out {
			border-left: none;
			border-top-right-radius: 5px;
		}
	}

	.average-price-container {
		padding: 13px;
		border: 1px solid $gray-4;
		border-top: none;
		border-radius: 0 0 5px 5px;
		font-size: 15px;
		color: $black-text;

		span:nth-child(1) {
			line-height: 21px;
		}

		span:nth-child(2) {
			line-height: 18px;
		}
	}

	.total-price-wrap {
		margin-top: 18px;
		font-size: 28px;
		color: $black-text;

		.title {
			font-size: 15px;
			line-height: 21px;
			font-weight: 400;
		}

		&.price-converted-wrap {
			font-size: $base-font-size-18;

			span.approx {
				font-weight: 400;
				font-size: $base-font-size-13;
				margin-right: 2px;
			}
		}
	}

	.tax-info {
		color: $gray-2;
		font-size: 13px;
		line-height: 19px;
	}

	.change-client-price {
		margin-top: 22px;
		padding: 12px;
		border-radius: 4px;
		background-color: $main-blue;
		font-size: 15px;
		line-height: 18px;

		&:hover {
			box-shadow: none;
			background-color: #3f5c80;
		}
	}

	.billing-price-wrap {
		margin-top: 5px;
		font-size: $base-font-size-16;
	}

	.excluded-extra-charges {
		margin-top: 4px;
		font-size: 12px;
		font-weight: bold;
	}

	.extra-charge {
		text-transform: capitalize;
	}

	.pay-container {
		margin-top: 15px;
		gap: 15px;
	}

	.pay-wrap {
		padding: 10px 16px;
		border: 1px solid $gray-4;
		border-radius: 5px;
		color: $black-text;

		.price {
			font-size: 16px;
		}
	}

	.no-payments-excluded,
	.price-converted-disclaimer {
		color: $gray-6;
		font-size: 13px;
	}

	.pay-with-installments {
		display: flex;
		align-items: center;
		height: 64px;
		background-color: #eafff5;
		border-radius: 14px;

		.icon-wrap {
			padding: 12px 12px 20px 16px;
		}

		.text {
			font-size: 15px;
			font-weight: 400;
			line-height: 21px;
			color: #008f4b;
			margin: 13px 8px 9px 0;
		}
	}

	.installments-banner {
		height: 64px;

		&__icon-wrap {
			padding: 12px 12px 20px 16px;
		}
	}
}
