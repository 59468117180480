.pac-container {
	//background-color: #fafafa !important;
	background-color: #fafafa;
	-webkit-box-shadow: 0;
	-moz-box-shadow: 0;
	box-shadow: 0;

	margin-top: -1px;
	border-top: none;
	//padding-right: 1px;
	//border-radius: 4px;
	//margin-left: 1px;

	.pac-item {
		&:hover {
		}

		.pac-icon {
		}
		.pac-matched {
		}
		.pac-item-query {
		}
	}

	.pac-item-selected {
		&:hover {
		}

		.pac-icon {
		}
		.pac-matched {
		}
		.pac-item-query {
		}
	}
}
