@import 'variables';

.payment-page > div {
	margin-top: 24px;
	margin-bottom: 24px;
}

.payment-page {
	//width: $width-main;
	width: 70%;
	/* border-radius: 4px; */
	border-top: 1px solid #d0d1d2;
	padding-left: 5%;
	padding-right: 5%;
	height: 100%;
	margin: 0 auto;
	margin-top: 10px;

	.final-approval-make-reservation {
		padding-top: 20px;
	}

	img {
		width: 24px;
		height: 24px;
	}

	.payment-details-container {
		width: 100%;
		display: block;
		border: 1px solid #cccccc;
		border-radius: 4px;
		padding: 12px;

		// h2 {

		// }

		.payment-method-container {
			.title {
				display: inline-block;
				margin-right: 24px;
			}

			.methods {
				display: inline-block;

				.method {
					display: inline-block;
					margin-right: 16px;

					input {
						display: inline-block;
						margin-right: 4px;
					}

					label {
						display: inline-block;
					}
				}
			}

			.payment-method {
				display: block;
				height: auto;
			}
		}

		// PaymentComponent (component)
		.credit-card-component {
			width: 100%;

			// .credit-card-container {
			//   .info {
			//     .credit-card-number {

			//     }
			//   }
			// }
		}
		.bank-transfer-component {
			width: 100%;
		}
	}

	.final-approval-make-reservation {
		.checkbox-container {
			display: block;
			width: 100%;

			label {
				@include Font-Regular(
					$check-list-filter-item-font-family,
					$check-list-filter-item-font-size,
					$check-list-filter-item-font-color
				);
				height: 20px;
				width: 100%;
				display: inline-block;
				line-height: 20px; //$check-list-filter-item-line-height;
				vertical-align: middle;
				padding-left: 24px;
			}

			input[type='checkbox'] {
				display: none;
			}

			input[type='checkbox'] + label {
				background: url('../img/results_page/check_box.png') no-repeat; // left center no-repeat;
				background-size: 20px 20px;
			}
			input[type='checkbox']:checked + label {
				background: url('../img/results_page/check_box_active.png') no-repeat; // left center no-repeat;
				background-size: 20px 20px;
			}
		}

		.disclaimer {
			margin-bottom: 16px;
		}

		// .make-reservation {
		//   //input[type=button].disabled {
		//   //  color: black !important;
		//   //}
		// }
	}
}

.reservation-modal {
	margin-bottom: 20px;
	text-align: center;

	h2 {
		margin-bottom: 20px;
	}

	// .modal-reservation-booked {

	// }

	// .modal-reservation-problem {

	// }

	.non-refundable-modal-actions {
		button {
			margin-left: 10px;
			margin-right: 10px;
		}
	}

	button {
		margin-bottom: 20px;
	}
}
