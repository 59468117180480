.trip-table-page,
.ongoing-trips-page {
	width: 100%;
	height: 100%;
	margin: 0 auto;
	padding-bottom: 18px;
	position: relative;

	div:nth-of-type(2) {
		max-height: calc(100vh - 137px) !important;
		overflow: auto;
	}

	&.ongoing-trips-page {
		.all-trips {
			position: absolute;
			top: 21px;

			.evil-icon {
				width: 32px;
				height: 32px;
				color: #999;

				&:hover {
					color: #666;
				}
			}
		}

		.loading-trips {
			right: 95px !important;
		}

		.map-container {
			padding: 28px;

			img {
				display: block;
				margin: 0 auto;

				width: 100%;
				height: 100%;
				filter: blur(4px);
			}
		}
	}

	.trips-table-header {
		padding: 24px 0;

		.title {
			@include Font-Regular($secondary-font, $base-font-size-24, $gray-1, 600);
		}

		.actions {
			gap: 12px;
		}
	}

	.trip-table {
		th {
			background-color: $gray-7;
			font-size: 13px;
			line-height: 19px;
			border: 1px solid $gray-5;
			color: $gray-2;
			padding: 8px 16px;
		}

		.trip-table-row {
			&,
			tr,
			th,
			td {
				border-bottom: 1px solid $gray-4;
				height: 44px !important;
			}

			td:first-child {
				border-left: 1px solid $gray-5;
			}

			td:last-child {
				border-right: 1px solid $gray-5;
			}

			&.hovered {
				&,
				tr,
				th,
				td {
					background-color: $gray-5 !important;
				}
			}

			.traveler-column {
				&,
				tr,
				th,
				td {
					max-width: 280px;
				}

				.traveler-column-wrapper {
					gap: 8px;
				}
			}

			.current-location-column {
				&,
				tr,
				th,
				td {
					span.current-location {
						&.flight {
						}

						&.accommodation {
							text-transform: capitalize;
						}
					}
				}
			}

			.date-column {
				&,
				tr,
				th,
				td {
					width: 140px;
				}
			}
		}
		.trip-table-body {
			&,
			tr,
			td {
				color: $black-text !important;

				.MuiTypography-root {
					font-size: 15px;
				}
			}

			.trip-status {
				position: relative;

				i.fa {
					font-size: 9px;
					color: #e1e1e1;
					position: absolute;
					left: -12px;
					top: 3px;
				}

				&.draft i.fa {
					color: #ccc;
				}
				&.new-request i.fa {
					color: #72c0ea;
				}
				&.pending i.fa {
					color: #ffaa00;
				}
				&.approved i.fa {
					color: #87d068;
				}
				&.canceled,
				&.declined,
				&.rejected {
					i.fa {
						color: #dd5a5a;
					}
				}
			}

			.trip-travelers {
				display: inline;
			}
		}
	}
}
